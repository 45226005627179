import { cva } from "class-variance-authority";

export const skeletonTabItemCva = cva("tab-item flex items-center cursor-pointer select-none gap-2 transition-all duration-200 ease-in-out dark:text-gray-200", {
  variants: {
    tile: {
      true: "flex-col items-center justify-center text-center p-1 rounded-lg",
      false: "px-4 py-2.5",
    },
    orientation: {
      horizontal: "rounded-lg",
      vertical: "w-full",
    },
  },
  compoundVariants: [
    {
      tile: true,
      orientation: "horizontal",
      class: "min-w-[88px] px-4",
    },
    {
      tile: true,
      orientation: "vertical",
      class: "min-w-[100px] max-w-[100px]",
    },
    {
      tile: false,
      orientation: "vertical",
      class: "max-w-[200px]",
    },
  ],
});

export const tabItemCva = cva("tab-item flex items-center select-none gap-1 transition-all duration-200 ease-in-out dark:text-gray-200 outline-none", {
  variants: {
    tile: {
      true: "flex-col items-center justify-center text-center p-1 h-16",
      false: "px-4 py-2.5",
    },
    orientation: {
      horizontal: "rounded-lg",
      vertical: "w-full",
    },
    active: {
      true: "text-primary-600 dark:text-primary-400",
      false: null,
    },
    listPosition: {
      left: null,
      right: null,
    },
    error: {
      true: "!text-red-600 !dark:text-red-400 !dark:bg-red-800",
      false: null,
    },
    disabled: {
      true: "cursor-not-allowed text-gray-400 dark:text-gray-600",
      false: "cursor-pointer hover:bg-gray-50 dark:hover:bg-dark-700 focus:bg-gray-50 dark:focus:bg-dark-700",
    },
  },
  compoundVariants: [
    {
      tile: true,
      orientation: "horizontal",
      class: "min-w-[88px] px-4",
    },
    {
      tile: true,
      orientation: "vertical",
      class: "min-w-[100px] max-w-[100px]",
    },
    {
      tile: false,
      orientation: "vertical",
      class: "max-w-[200px]",
    },
    {
      tile: true,
      orientation: "vertical",
      active: true,
      class: "dark:bg-dark-800",
    },
    {
      tile: false,
      orientation: "vertical",
      active: true,
      listPosition: "left",
      class: "border-r border-primary-600 dark:border-primary-400",
    },
    {
      tile: false,
      orientation: "vertical",
      active: true,
      listPosition: "right",
      class: "border-l border-primary-600 dark:border-primary-400",
    },
  ],
});

export const tabIconCva = cva("flex items-center justify-center", {
  variants: {
    tile: {
      true: "w-8 h-8 p-1.5 rounded-lg bg-gray-100 dark:bg-dark-700",
      false: null,
    },
    active: {
      true: null,
      false: null,
    },
    error: {
      true: "!text-red-600 !dark:text-red-400",
      false: null,
    },
    disabled: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      tile: true,
      error: false,
      disabled: false,
      active: true,
      class: "text-primary-600 dark:text-primary-400 bg-primary-100 dark:bg-primary-800/40",
    },
    {
      tile: true,
      error: true,
      disabled: false,
      active: true,
      class: "bg-red-100 dark:bg-red-800/40",
    },
  ],
});

export const tabTitleCva = cva("text-sm leading-3", {
  variants: {
    tile: {
      true: "text-center",
      false: "whitespace-nowrap",
    },
  },
});
