import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export const shortCutTaskPermissionAttributes = [
  PermissionAttributesEnum.ContextTaskshortcutAlltasks,
  PermissionAttributesEnum.ContextTaskshortcutAlltaskstoday,
  PermissionAttributesEnum.ContextTaskshortcutAlltasksuntiltoday,
  PermissionAttributesEnum.ContextTaskshortcutChecked,
  PermissionAttributesEnum.ContextTaskshortcutFinished,
  PermissionAttributesEnum.ContextTaskshortcutMytaskstoday,
  PermissionAttributesEnum.ContextTaskshortcutMytasksuntiltoday,
  PermissionAttributesEnum.ContextTaskshortcutPerproperty,
  PermissionAttributesEnum.ContextTaskshortcutWalkroute,
  PermissionAttributesEnum.ContextTaskshortcutAssignedtome,
  PermissionAttributesEnum.ContextTaskshortcutUnassigned,
] as const;

export type ShortCutTaskPermissionAttributes = typeof shortCutTaskPermissionAttributes[number];
export const shortCutReportPermissionAttributes = [

  PermissionAttributesEnum.ContextReportshortcutAllreports,
  PermissionAttributesEnum.ContextReportshortcutGuestreports,
  PermissionAttributesEnum.ContextReportshortcutOpenreports,
  PermissionAttributesEnum.ContextReportshortcutTopics,
  PermissionAttributesEnum.ContextReportshortcutPerproperty,
  PermissionAttributesEnum.ContextReportshortcutTransferredtotask,
  PermissionAttributesEnum.ContextReportshortcutMyreports,
] as const;

export type ShortCutReportPermissionAttributes = typeof shortCutReportPermissionAttributes[number];
export const shortCutPermissionAttributes = [
  ...shortCutTaskPermissionAttributes,
  ...shortCutReportPermissionAttributes,
] as const;

export type ShortCutPermissionAttributes = typeof shortCutPermissionAttributes[number];

export const noneShortCutPermissionAttributes = [
  PermissionAttributesEnum.ContextTaskshortcutUser,
  PermissionAttributesEnum.ContextTaskshortcutUsergroups,
  PermissionAttributesEnum.ContextTaskshortcutUsergrouplessuser,
] as const;

export type NoneShortCutPermissionAttributes = typeof noneShortCutPermissionAttributes[number];

export const shortCutPermissions = [
  ...shortCutPermissionAttributes,
  ...noneShortCutPermissionAttributes,
] as const;

export type ShortcutPermission = typeof shortCutPermissions[number];

export class ShortcutVoter extends ContextVoter {
  public hasShortcut = ({
    location = this.permissionService.defaultLocation,
    client = this.permissionService.defaultClient,
    attribute,
  }: {
    location?: string | null
    client?: string | null
    attribute: ShortcutPermission
  }) => {
    if (!shortCutPermissions.includes(attribute)) {
      console.warn(`[ShortcutVoter] Shortcut permission ${attribute} is not supported`);
      return false;
    }

    if (this.permissionService.getGlobalPermissionForUser({
      attribute: PermissionAttributesEnum.Global,
    })) {
      return true;
    }

    return this.hasAccess({
      location,
      client,
      attribute,
    });

    // Cache the result
  };

  public isFocusedOnTaskShortcut = ({
    location = this.permissionService.defaultLocation,
    client = this.permissionService.defaultClient,
  }: {
    location?: string | null
    client?: string | null
  } = {}): ShortCutTaskPermissionAttributes | null => {
    let foundPermission: ShortCutTaskPermissionAttributes | null = null;

    for (const attribute of shortCutTaskPermissionAttributes) {
      if (this.hasShortcut({ location, client, attribute })) {
        if (foundPermission) {
          // If we already found one and now found another, return null
          return null;
        }
        foundPermission = attribute;
      }
    }

    // make sure the user has the none shortcut permission
    for (const attribute of noneShortCutPermissionAttributes) {
      if (this.hasShortcut({ location, client, attribute })) {
        return null;
      }
    }

    return foundPermission;
  };

  public isFocusedOnReportShortcut = ({
    location = this.permissionService.defaultLocation,
    client = this.permissionService.defaultClient,
  }: {
    location?: string | null
    client?: string | null
  } = {}): ShortCutReportPermissionAttributes | null => {
    let foundPermission: ShortCutReportPermissionAttributes | null = null;

    for (const attribute of shortCutReportPermissionAttributes) {
      if (this.hasShortcut({ location, client, attribute })) {
        if (foundPermission) {
          // If we already found one and now found another, return null
          return null;
        }
        foundPermission = attribute;
      }
    }

    // make sure the user has the none shortcut permission
    for (const attribute of noneShortCutPermissionAttributes) {
      if (this.hasShortcut({ location, client, attribute })) {
        return null;
      }
    }

    return foundPermission;
  };
}
