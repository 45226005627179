import type { HydraCollection } from "@verbleif/lib";
import { createGlobalState } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { api } from "./Api";
import { useAuthStore } from "./AuthStore/useAuthStore";
import { useServerErrorHandler } from "./Error";

export const useNavbarUnreadCount = createGlobalState(() => {
  const authStore = useAuthStore();
  const unreadCount = ref(0);
  const { onError } = useServerErrorHandler();
  const loading = ref(false);
  const { t } = useI18n();
  async function checkUnreadCount() {
    if (loading.value) {
      return;
    }

    loading.value = true;

    try {
      if (!authStore.user.value) {
        return;
      }

      const response = await api.get<HydraCollection<any>>(`/users/${authStore.user.value?.id}/legacy_notifications`, {
        params: {
          "perPage": 1,
          "page": 1,
          "exists[readAt]": false,
        },
      });
      unreadCount.value = response.data.totalItems;
    } catch (err) {
      onError({ error: err, toastTitle: t("navbar.dropdown.pull_notification_count_title") });
      throw err;
    } finally {
      loading.value = false;
    }
  }

  return {
    unreadCount,
    checkUnreadCount,
  };
});
