import type { ComputedRef, Ref } from "vue";
import type { LoopbackFilterLogicalOperatorEnum, LoopbackFilterOperatorEnum } from "../Api";
import { computed, inject, provide, ref, watch } from "vue";
import { useDebounce } from "../Debounce";

const SearchableKey = Symbol("Searchable");

export interface SearchableField<T> {
  name: T
  logicalOperator: LoopbackFilterLogicalOperatorEnum
  operator: LoopbackFilterOperatorEnum
  index?: number
}

function createSearchable<T>(
  searchableFields: Array<Extract<keyof T, string> | SearchableField<Extract<keyof T, string>>>,
  items?: Ref<T[]> | ComputedRef<T[]> | undefined,
  searchFieldKey: string = "name",
  paramOverride?: (filter: string) => string,
) {
  const searchValue = ref<string>("");
  const searchDisabled = ref(false);
  const { debounce, debouncedValue } = useDebounce();

  watch(searchValue, () => debounce(searchValue.value));

  function clearSearch() {
    searchValue.value = "";
    debouncedValue.value = "";
  }

  function enableSearch() {
    searchDisabled.value = false;
  }

  function disableSearch() {
    searchDisabled.value = true;
  }

  const searchEnabled = computed(() => {
    return (
      searchValue.value.trim() !== ""
      && searchDisabled.value === false
    );
  });

  function setSearchUrlParams(urlParams: URLSearchParams) {
    if (searchEnabled.value) {
      if (searchableFields.length === 1) {
        urlParams.append(convertFilterToUrlParam(searchableFields[0], 0), debouncedValue.value);
      } else {
        searchableFields.forEach((filter, index) => {
          urlParams.append(convertFilterToUrlParam(filter, index), debouncedValue.value);
        });
      }
    }
  }

  function convertFilterToUrlParam(filter: SearchableField<Extract<keyof T, string>> | string, index: number) {
    if (typeof filter === "string") {
      if (paramOverride) {
        return paramOverride(filter);
      }
      return `filter[where][${filter}][ilike]`;
    }
    if (paramOverride) {
      return paramOverride(filter.name);
    }
    return `filter[where][${filter.logicalOperator}][${filter?.index || index}][${filter.name}][${filter.operator}]`;
  }

  const filtered = computed(() => {
    if (!items) {
      return [];
    }
    if (searchDisabled.value) {
      return items.value;
    }

    if (searchValue.value.trim() === "") {
      return items.value;
    }

    return items.value.filter((item: any) => {
      if (!(searchFieldKey in item)) {
        return false;
      }
      if (typeof item[searchFieldKey] !== "string") {
        return false;
      }
      return item[searchFieldKey].match(new RegExp(searchValue.value, "gi"));
    });
  });

  function highlight(word: string | null) {
    if (!word) {
      return word;
    }
    if (searchValue.value.trim() !== "") {
      return word;
    }
    const check = new RegExp(searchValue.value, "gi");
    return word
      .toString()
      .replace(check, matchedText => `<mark>${matchedText}</mark>`);
  }

  return {
    clearSearch,
    setSearchUrlParams,
    searchValue,
    debouncedValue,
    searchableFields,
    searchEnabled,
    searchDisabled,
    highlight,
    filtered,
    enableSearch,
    disableSearch,
  };
}

type CreateSearchableParameters<T> = Parameters<typeof createSearchable<T>>;
export function provideSearchable<T>(...config: CreateSearchableParameters<T>) {
  const instance = createSearchable<T>(...config);
  provide(SearchableKey, instance);
  return instance;
}

type UseSearchable = ReturnType<typeof createSearchable>;
export function useSearchable() {
  const instance = inject<UseSearchable>(SearchableKey);

  if (!instance) {
    throw new Error("Run provideSearchable before useSearchable.");
  }

  return instance;
}
