import type { PropertyHydraItem, PropertyReservationStateHydraItem, PropertyTaskStateHydraItem, UserGroupHydraItem, UserHydraItem } from "@verbleif/lib";
import type { VSidebarItemProps } from "../Sidebar/VSidebarItem.type";
import { OccupationState, PermissionAttributesEnum, TransitionEvent } from "@verbleif/lib";
import { shortcutVoter, useSharedStore, useSystemStore } from "@verbleif/shared";
import { createInjectionState } from "@vueuse/core";
import { TaskListType } from "@web/features/Tasks/TasksRoutes";
import { sort } from "fast-sort";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { usePropertyStates } from "../TaskExtraData/usePropertyStates";

function getPropertyIconColor(item: {
  reservationState: PropertyReservationStateHydraItem | null
  taskState: PropertyTaskStateHydraItem | null
}) {
  const isBlocked = item.reservationState?.blocked;
  const isOccupied = item.reservationState?.datePointState?.occupationState === OccupationState.OCCUPIED;

  if (isBlocked) {
    return "text-red-500 dark:text-red-400";
  }

  if (isOccupied) {
    return "text-blue-500 dark:text-blue-400";
  }

  return "text-gray-900 dark:text-gray-100";
}

const [useProvideTaskList, useTaskListRaw] = createInjectionState(() => {
  const route = useRoute();
  const {
    properties,
    propertyGroupsIndexedByIri,
  } = useSystemStore();
  const { t } = useI18n();
  const {
    usersPerUserGroup,
    usersList,
    userGroupLessUsers,
    userGroupsList,
  } = useSharedStore();

  const { propertyStates } = usePropertyStates();

  const hasTaskListsShortcutUserGroups = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUsergroups,
    });
  });

  const hasTaskListsUsers = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUser,
    });
  });

  const hasTaskListsShortcutUserGroupsLessUser = computed<boolean>(() => {
    return shortcutVoter.hasShortcut({
      attribute: PermissionAttributesEnum.ContextTaskshortcutUsergrouplessuser,
    });
  });

  const showUserGroupsListsTab = computed<boolean>(() => {
    return hasTaskListsShortcutUserGroups.value || hasTaskListsShortcutUserGroupsLessUser.value;
  });

  const lists = computed<VSidebarItemProps[]>(() => {
    const rawLists: VSidebarItemProps[] = [];
    userGroupsList.value.sort((a, b) => a.name.localeCompare(b.name)).forEach((userGroup) => {
      const routeId = `${TaskListType.TYPE_USER_GROUP}_${userGroup.id}`;

      const listItem: VSidebarItemProps = {
        routeId,
        text: userGroup.name,
        icon: ["fa", userGroup.avatarIconIdentifier ?? "users-class"],
        customIconColor: userGroup.avatarColor,
        to: {
          name: "task-list",
          params: { type: TaskListType.TYPE_USER_GROUP, id: userGroup.id },
        },
        children: [],
      };
      const userGroupUsers = usersPerUserGroup.value[userGroup["@id"]];
      if (userGroupUsers?.length && hasTaskListsUsers.value) {
        listItem.children = [];

        userGroupUsers.sort((a, b) => a.name.localeCompare(b.name)).forEach((user) => {
          listItem.children!.push({
            routeId: `${TaskListType.TYPE_USER}_${user.id}`,
            text: user.name,
            icon: ["fad", "user"],
            to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
            children: [],
          });
        });
      }
      rawLists.push(listItem);
    });

    if (hasTaskListsShortcutUserGroupsLessUser.value) {
      userGroupLessUsers.value.sort((a, b) => a.name.localeCompare(b.name)).forEach((user) => {
        rawLists.push({
          routeId: `${TaskListType.TYPE_USER}_${user.id}`,
          text: user.name,
          icon: ["fad", "user"],
          to: { name: "task-list", params: { type: TaskListType.TYPE_USER, id: user.id } },
          children: [],
        });
      });
    }
    return rawLists;
  });

  const currentListType = computed<TaskListType | null>(() => {
    return route.params.type as TaskListType || null;
  });

  const currentListId = computed(() => {
    return route.params.id as string || null;
  });

  const currentListRouteId = computed(() => {
    if (!currentListType.value) {
      return null;
    }
    return `${currentListType.value}_${currentListId.value}`;
  });

  const currentList = computed<UserHydraItem | UserGroupHydraItem | PropertyHydraItem | null>(() => {
    switch (currentListType.value) {
      case TaskListType.TYPE_ALL:
      case TaskListType.TYPE_PER_PROPERTY:
        return properties.value.find((property) => {
          return `${TaskListType.TYPE_PER_PROPERTY}_${property.id}` === currentListRouteId.value;
        }) || null;
      case TaskListType.TYPE_TODAY:
      case TaskListType.TYPE_OPEN_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_UNTIL_TODAY:
      case TaskListType.TYPE_ALL_TODAY:
      case TaskListType.TYPE_ASSIGNED_TO_ME:
      case TaskListType.TYPE_UNASSIGNED:
      case TaskListType.TYPE_READY:
      case TaskListType.TYPE_FINISHED:
      case TaskListType.TYPE_CHANGEOVER_DAY:
      case TaskListType.TYPE_ROUTE:
      case TaskListType.TYPE_TEMPLATE:
        return null;
      case TaskListType.TYPE_USER_GROUP:
        return userGroupsList.value.find((userGroup) => {
          return `${TaskListType.TYPE_USER_GROUP}_${userGroup.id}` === currentListRouteId.value;
        }) || null;
      case TaskListType.TYPE_USER:
        return usersList.value.find((user) => {
          return `${TaskListType.TYPE_USER}_${user.id}` === currentListRouteId.value;
        }) || null;
      default:
        return null;
    }
  });

  const loading = computed(() => {
    return !!currentList.value;
  });

  const currentListName = computed<string>(() => {
    if (currentList.value) {
      return currentList.value?.name || "";
    }

    return "";
  });

  const currentListTranslation = computed(() => {
    if (!currentListType.value) {
      return "unknown";
    }
    const map = {
      [TaskListType.TYPE_ALL]: "tasks.type.all",
      [TaskListType.TYPE_PER_PROPERTY]: "tasks.type.per_property",
      [TaskListType.TYPE_TODAY]: "tasks.type.today",
      [TaskListType.TYPE_OPEN_UNTIL_TODAY]: "tasks.type.open_until_today",
      [TaskListType.TYPE_ALL_UNTIL_TODAY]: "tasks.type.all_until_today",
      [TaskListType.TYPE_ALL_TODAY]: "tasks.type.all_today",
      [TaskListType.TYPE_ASSIGNED_TO_ME]: "tasks.type.assigned_to_me",
      [TaskListType.TYPE_UNASSIGNED]: "tasks.type.unassigned",
      [TaskListType.TYPE_READY]: "tasks.type.ready",
      [TaskListType.TYPE_FINISHED]: "tasks.type.finished",
      [TaskListType.TYPE_USER_GROUP]: "tasks.type.user_group",
      [TaskListType.TYPE_USER]: "tasks.type.user",
      [TaskListType.TYPE_CHANGEOVER_DAY]: "tasks.type.change_over_day",
      [TaskListType.TYPE_ROUTE]: "tasks.type.route",
      [TaskListType.TYPE_TEMPLATE]: "tasks.type.template",
    };
    return map[currentListType.value];
  });

  const propertyTabs = computed<VSidebarItemProps[]>(() => {
    const rawProperties = properties.value.filter(property => property.active);
    const propertiesWithGroup = sort(rawProperties.map((property) => {
      const propertyGroup = propertyGroupsIndexedByIri.value[property.group];
      return {
        ...property,
        groupName: propertyGroup?.name ?? null,
        groupId: propertyGroup?.id ?? null,
      };
    })).asc([
      property => property.groupName ?? "",
      property => property.name,
    ]);

    const items: VSidebarItemProps[] = [];

    // First collect all groups and their properties
    const groupedProperties = propertiesWithGroup.reduce((acc, property) => {
      // Skip properties without a group
      if (!property.groupName) {
        return acc;
      }

      if (!acc[property.groupName]) {
        acc[property.groupName] = {
          properties: [],
          groupId: property.groupId,
        };
      }
      acc[property.groupName].properties.push(property);
      return acc;
    }, {} as Record<string, { properties: typeof propertiesWithGroup, groupId: number | null }>);

    // Add groups and their properties
    Object.entries(groupedProperties).forEach(([groupName, group]) => {
      // Create group item with properties as children
      const groupItem: VSidebarItemProps = {
        routeId: `${TaskListType.TYPE_PER_PROPERTY}_group_${group.groupId}`,
        text: groupName,
        icon: ["fad", "chart-tree-map"],
        iconColor: "text-gray-500",
        // hideSpacer: true,
        children: group.properties.map((property) => {
          const propertyState = propertyStates.value[property.id];
          const isSwitching = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.SWITCH;
          const isBlocked = propertyState?.data.reservationState?.blocked;
          const isDeparting = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.DEPARTURE;
          const isArriving = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.ARRIVAL;
          const rightIcons: Array<{
            icon: [string, string]
            tooltip: string
            color: string
            tooltipColor?: string
            showInTooltip: boolean
          }> = [];

          if (isSwitching) {
            rightIcons.push({
              icon: ["fad", "exchange"],
              tooltip: t("tasks.section.object_status.switching"),
              color: "text-blue-500 dark:text-blue-400",
              showInTooltip: true,
            });
          }

          if (isDeparting) {
            rightIcons.push({
              icon: ["fad", "right-from-bracket"],
              tooltip: t("tasks.section.object_status.departing"),
              color: "text-red-500 dark:text-red-400",
              showInTooltip: true,
            });
          }

          if (isArriving) {
            rightIcons.push({
              icon: ["fad", "left-to-bracket"],
              tooltip: t("tasks.section.object_status.arriving"),
              color: "text-green-500 dark:text-green-400",
              showInTooltip: true,
            });
          }

          if (isBlocked) {
            rightIcons.push({
              icon: ["fad", "lock"],
              tooltip: t("tasks.section.object_status.blocked"),
              color: propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.NONE
                ? "text-neutral-600 dark:text-neutral-400"
                : "text-red-500",
              showInTooltip: true,
            });
          }

          return {
            routeId: `${TaskListType.TYPE_PER_PROPERTY}_${property.id}`,
            text: property.name,
            icon: ["fad", "house"],
            to: {
              name: "task-list",
              params: {
                type: TaskListType.TYPE_PER_PROPERTY,
                id: property.id,
              },
            },
            children: [], // Required by VSidebarItemProps but empty
            iconColor: propertyState ? getPropertyIconColor(propertyState.data) : "text-gray-900 dark:text-gray-100",
            rightIcons: rightIcons.length > 0 ? rightIcons : undefined,
          };
        }),
      };
      items.push(groupItem);
    });

    return items;
  });

  const visiblePropertyTabs = reactive<Record<string, boolean>>(
    propertyTabs.value.reduce((acc, property) => {
      acc[property.routeId] = true;
      return acc;
    }, {} as Record<string, boolean>),
  );

  return {
    lists,
    visiblePropertyTabs,
    currentListName,
    currentList,
    currentListRouteId,
    currentListId,
    propertyTabs,
    currentListType,
    currentListTranslation,
    loading,
    hasTaskListsShortcutUserGroups,
    hasTaskListsUsers,
    hasTaskListsShortcutUserGroupsLessUser,
    showUserGroupsListsTab,
  };
});

export { useProvideTaskList };

export function useTaskList() {
  const taskList = useTaskListRaw();
  if (taskList == null) {
    throw new Error("Please call `useProvideTaskList` on the appropriate parent component");
  }
  return taskList;
}

export const isFocusedOnTaskShortcut = computed(() => {
  return shortcutVoter.isFocusedOnTaskShortcut();
});
