import type { Ref } from "vue";
import type { TabItemRef } from "./types";
import { createInjectionState } from "@vueuse/core";
import { computed, ref } from "vue";

const [useProvideTabsState, useTabsStateRaw] = createInjectionState(({
  modelValue,
  orientation,
  scrollable,
  lazy,
  tile,
  inkbar,
  listPosition,
  centerItemsInList,
  dividerBetweenTabs,
}: {
  modelValue: Ref<number>
  orientation?: Ref<"horizontal" | "vertical">
  scrollable?: Ref<boolean>
  lazy?: Ref<boolean>
  tile?: Ref<boolean>
  inkbar?: Ref<boolean>
  listPosition?: Ref<"left" | "right">
  centerItemsInList?: Ref<boolean>
  dividerBetweenTabs?: Ref<boolean>
}) => {
  const activeIndex = computed({
    get: () => modelValue.value,
    set: (value) => {
      modelValue.value = value;
    },
  });

  const tabItems = ref<TabItemRef[]>([]);

  function registerTab(tab: TabItemRef) {
    tabItems.value.push(tab);
  }

  function unregisterTab(index: number) {
    tabItems.value = tabItems.value.filter(tab => tab.index !== index);
  }

  function onTabClick(index: number) {
    activeIndex.value = index;
  }

  return {
    activeIndex,
    tabItems,
    registerTab,
    unregisterTab,
    onTabClick,
    lazy,
    scrollable,
    orientation,
    tile,
    inkbar,
    listPosition,
    centerItemsInList,
    dividerBetweenTabs,
  };
});

export { useProvideTabsState };

export function useTabsState() {
  const state = useTabsStateRaw();

  if (state == null) {
    throw new Error("Please call `useProvideTabsState` on the appropriate parent component");
  }

  return state;
}
