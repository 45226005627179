import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "../../createApi.ts";
import { localeState } from "@verbleif/lib/src/translations/localeState";
import { InterceptorType } from "../../createApi.ts";

export function AcceptLanguageRequestInterceptor(
  excludedRoutes: string[] = [],
): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url) {
      return config;
    }

    if (excludedRoutes.includes(config.url)) {
      return config;
    }

    // Get current locale directly from localeState
    const locale = localeState.value;

    if (locale) {
      config.headers["Accept-Language"] = locale;
    }
    return config;
  }

  return {
    name: "AcceptLanguageRequestInterceptor",
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  };
}
