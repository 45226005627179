<script setup lang="ts">
import { OccupationState, TransitionEvent } from "@verbleif/lib";
import { useI18n } from "vue-i18n";
import { parseLocale } from "./Occupation/localeParser";
import { useReservationState } from "./useReservationState";

const { reservationState } = useReservationState();
const { t, te } = useI18n();
const JsonEditorVue = defineAsyncComponent(() =>
  import("json-editor-vue"),
);

enum Mode {
  text = "text",
  tree = "tree",
  table = "table",
}

const datePointState = computed(() => reservationState.value?.datePointState);
const currentReservation = computed(() => reservationState.value?.current);
const nextArrival = computed(() => reservationState.value?.nextArrival);
const lastDeparture = computed(() => reservationState.value?.lastDeparture);

const enums = computed(() => ({
  TransitionEvent: Object.entries(TransitionEvent)
    .filter(([key]) => Number.isNaN(Number(key)))
    .map(([key, value]) => ({ key, value })),
  OccupationState: Object.entries(OccupationState)
    .filter(([key]) => Number.isNaN(Number(key)))
    .map(([key, value]) => ({ key, value })),
}));

// Locale parsing debug data
const localeDebugData = computed(() => {
  const reservations = [
    currentReservation.value,
    nextArrival.value,
    lastDeparture.value,
  ].filter(Boolean);

  return reservations.map((reservation) => {
    const rawLocale = reservation?.data?.locale || "UNKNOWN";
    const parsedLocale = parseLocale(rawLocale);

    // Get translations
    const languageKey = parsedLocale.language.toUpperCase();
    const baseTranslation = te(`locales.${languageKey}`)
      ? t(`locales.${languageKey}`)
      : t("locales.UNKNOWN");

    const regionCode = parsedLocale.region.toUpperCase();
    const countryName = t(`countries.${regionCode}`) || parsedLocale.region;

    // Determine if we should show country name
    const parts = rawLocale.split("_");
    const showCountryName = parts.length > 1;

    // Create display text
    const displayText = showCountryName
      ? `${baseTranslation} (${countryName})`
      : baseTranslation;

    return {
      reservationType: reservation === currentReservation.value
        ? "Current"
        : reservation === nextArrival.value ? "Next Arrival" : "Last Departure",
      rawLocale,
      parsedLocale,
      displayText,
      languageKey,
      regionCode,
      countryName,
      showCountryName,
    };
  });
});

// Flag existence check
const flagExistsMap = ref<Record<string, boolean>>({});

async function checkFlagExists(regionCode: string) {
  if (!regionCode || regionCode === "UNKNOWN") {
    return;
  }

  if (flagExistsMap.value[regionCode] !== undefined) {
    return; // Already checked
  }

  try {
    const response = await fetch(`/flags/${regionCode.toUpperCase()}.svg`, { method: "HEAD" });
    const contentType = response.headers.get("content-type");
    flagExistsMap.value[regionCode] = contentType ? !contentType.includes("text/html") : false;
  } catch (error) {
    console.error(error);
    flagExistsMap.value[regionCode] = false;
  }
}

// Check flags for all regions
watch(localeDebugData, (newData) => {
  newData.forEach((item) => {
    if (item.regionCode) {
      checkFlagExists(item.regionCode);
    }
  });
}, { immediate: true });

// Get flag URL
function getFlagUrl(regionCode: string) {
  return flagExistsMap.value[regionCode]
    ? `/flags/${regionCode.toUpperCase()}.svg`
    : "/flags/UNKNOWN.svg";
}

const activeIndex = ref(0);
</script>

<template>
  <div class="px-4 max-h-[55vh]">
    <VTabs
      v-model:model-value="activeIndex"
      lazy
      divider-between-tabs
      orientation="horizontal"
    >
      <VTabList>
        <VTabItem
          title="Date Point State"
          :icon="['fad', 'calendar']"
          :index="0"
        />
        <VTabItem
          title="Current Reservation"
          :icon="['fad', 'house']"
          :index="1"
        />
        <VTabItem
          title="Next Arrival"
          :icon="['fad', 'left-to-bracket']"
          :index="2"
        />
        <VTabItem
          title="Last Departure"
          :icon="['fad', 'right-from-bracket']"
          :index="3"
        />
        <VTabItem
          title="Full State"
          :icon="['fad', 'database']"
          :index="4"
        />
        <VTabItem
          title="Enums"
          :icon="['fad', 'list']"
          :index="5"
        />
        <VTabItem
          title="Locale Parser"
          :icon="['fad', 'language']"
          :index="6"
        />
      </VTabList>
      <VTabPanels>
        <VTabPanel :index="0">
          <div v-if="datePointState" class="py-2">
            <JsonEditorVue
              :model-value="datePointState"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
            <div class="text-gray-500 dark:text-gray-400">
              No date point state data available
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="1">
          <div v-if="currentReservation" class="py-2">
            <JsonEditorVue
              :model-value="currentReservation"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
            <div class="text-gray-500 dark:text-gray-400">
              No current reservation data available
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="2">
          <div v-if="nextArrival" class="py-2">
            <JsonEditorVue
              :model-value="nextArrival"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
            <div class="text-gray-500 dark:text-gray-400">
              No next arrival data available
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="3">
          <div v-if="lastDeparture" class="py-2">
            <JsonEditorVue
              :model-value="lastDeparture"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
            <div class="text-gray-500 dark:text-gray-400">
              No last departure data available
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="4">
          <div v-if="reservationState" class="py-2">
            <JsonEditorVue
              :model-value="reservationState"
              :mode="Mode.text"
              :stringified="false"
              read-only
            />
          </div>
          <div v-else class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
            <div class="text-gray-500 dark:text-gray-400">
              No reservation state data available
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="5">
          <div class="py-2 space-y-6">
            <div>
              <h3 class="text-xl text-gray-800 dark:text-gray-200 font-semibold mb-2">
                Transition Events
              </h3>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="event in enums.TransitionEvent"
                  :key="event.key"
                  class="flex items-center gap-2 p-2 bg-gray-50 dark:bg-dark-800 rounded"
                >
                  <span class="font-mono">{{ event.value }}</span>
                  <span class="text-gray-600 dark:text-gray-400">{{ event.key }}</span>
                </div>
              </div>
            </div>
            <div>
              <h3 class="text-xl text-gray-800 dark:text-gray-200 font-semibold mb-2">
                Occupation States
              </h3>
              <div class="grid grid-cols-2 gap-2">
                <div
                  v-for="state in enums.OccupationState"
                  :key="state.key"
                  class="flex items-center gap-2 p-2 bg-gray-50 dark:bg-dark-800 rounded"
                >
                  <span class="font-mono">{{ state.value }}</span>
                  <span class="text-gray-600 dark:text-gray-400">{{ state.key }}</span>
                </div>
              </div>
            </div>
          </div>
        </VTabPanel>
        <VTabPanel :index="6">
          <div class="py-2">
            <div v-if="localeDebugData.length === 0" class="py-4 my-4 text-center bg-gray-50 dark:bg-dark-800 rounded-lg">
              <div class="text-gray-500 dark:text-gray-400">
                No reservation data available for locale parsing
              </div>
            </div>

            <div v-else class="space-y-6">
              <div v-for="(item, index) in localeDebugData" :key="index" class="bg-gray-50 dark:bg-dark-800 rounded-lg p-4">
                <h4 class="text-lg font-medium mb-4 text-gray-800 dark:text-gray-200">
                  {{ item.reservationType }} Reservation
                </h4>

                <!-- Responsive layout: 3 columns on desktop, 1 column on medium and down -->
                <div class="grid grid-cols-1 xl:grid-cols-3 gap-4">
                  <!-- Raw Locale Section -->
                  <div class="bg-white dark:bg-dark-700 p-3 rounded">
                    <h5 class="font-medium text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Raw Locale
                    </h5>
                    <div class="font-mono text-gray-800 dark:text-gray-200 break-all">
                      {{ item.rawLocale }}
                    </div>
                  </div>

                  <!-- Parsed Locale Section -->
                  <div class="bg-white dark:bg-dark-700 p-3 rounded">
                    <h5 class="font-medium text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Parsed Locale
                    </h5>
                    <div class="space-y-1">
                      <div class="flex justify-between">
                        <span class="text-gray-500 dark:text-gray-400">Language:</span>
                        <span class="font-medium text-gray-800 dark:text-gray-200">{{ item.parsedLocale.language }}</span>
                      </div>
                      <div class="flex justify-between">
                        <span class="text-gray-500 dark:text-gray-400">Region:</span>
                        <span class="font-medium text-gray-800 dark:text-gray-200">{{ item.parsedLocale.region }}</span>
                      </div>
                      <div v-if="item.parsedLocale.script" class="flex justify-between">
                        <span class="text-gray-500 dark:text-gray-400">Script:</span>
                        <span class="font-medium text-gray-800 dark:text-gray-200">{{ item.parsedLocale.script }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Translation & Flag Section -->
                  <div class="bg-white dark:bg-dark-700 p-3 rounded">
                    <h5 class="font-medium text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Translation & Flag
                    </h5>
                    <div class="flex items-center mb-2">
                      <img
                        :src="getFlagUrl(item.regionCode)"
                        :alt="item.countryName"
                        class="w-5 h-5 mr-2"
                      >
                      <span class="font-medium text-gray-800 dark:text-gray-200">{{ item.displayText }}</span>
                    </div>

                    <div class="text-sm text-gray-500 dark:text-gray-400 space-y-1">
                      <div class="flex justify-between">
                        <span>Language:</span>
                        <span class="font-medium text-gray-800 dark:text-gray-200">{{ t(`locales.${item.languageKey}`) || 'Not found' }}</span>
                      </div>
                      <div v-if="item.showCountryName" class="flex justify-between">
                        <span>Country:</span>
                        <span class="font-medium text-gray-800 dark:text-gray-200">{{ t(`countries.${item.regionCode}`) || 'Not found' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VTabPanel>
      </VTabPanels>
    </VTabs>
  </div>
</template>
