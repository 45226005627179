<script setup lang="ts">
import type { Placement } from "floating-vue";
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";
import { useTabsState } from "../useTabsState";
import { useProvideTabItemState } from "./useTabItemState";
import { skeletonTabItemCva, tabIconCva, tabItemCva, tabTitleCva } from "./VTabItem.cva";

interface Props {
  title?: string
  icon?: string | string[]
  disabled?: boolean
  tooltip?: string
  tooltipPosition?: Placement
  index: number
  error?: boolean
  skeleton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  icon: undefined,
  tooltip: undefined,
  tooltipPosition: "right",
  title: "",
  error: false,
  skeleton: false,
});

const { disabled, error, index } = toRefs(props);

const {
  activeIndex,
  tabItems,
  registerTab,
  unregisterTab,
  onTabClick,
  orientation,
  tile,
  listPosition,
} = useTabsState();

onMounted(() => {
  registerTab({
    index: index.value,
    error: error.value,
    disabled: disabled.value,
  });
});

onBeforeUnmount(() => {
  unregisterTab(index.value);
});

watch([error, disabled], ([newError, newDisabled]) => {
  const existingTab = tabItems.value.find(tab => tab.index === index.value);
  if (existingTab) {
    existingTab.error = newError;
    existingTab.disabled = newDisabled;
  }
});

useProvideTabItemState({
  error,
  disabled,
  index,
});

const titleRef = ref<HTMLSpanElement | null>(null);

function handleClick() {
  if (!disabled.value) {
    onTabClick(index.value);
  }
}

function handleKeyDown(event: KeyboardEvent) {
  if (!disabled.value && (event.key === "Enter" || event.key === " ")) {
    event.preventDefault();
    handleClick();
  }
}

const skeletonClasses = computed(() => {
  return skeletonTabItemCva({
    tile: tile?.value,
    orientation: orientation?.value,
  });
});

const itemClasses = computed(() => {
  return tabItemCva({
    tile: tile?.value,
    orientation: orientation?.value,
    active: activeIndex.value === index.value,
    error: error.value,
    disabled: disabled.value,
    listPosition: listPosition?.value,
  });
});

const iconClasses = computed(() => {
  return tabIconCva({
    tile: tile?.value,
    active: activeIndex.value === index.value,
    error: error.value,
    disabled: disabled.value, // TODO: figure out why attrs.disabled is not working
  });
});

const titleClasses = computed(() => {
  return tabTitleCva({
    tile: tile?.value,
  });
});
</script>

<template>
  <template v-if="props.skeleton">
    <div
      :class="[skeletonClasses]"
    >
      <template v-if="tile">
        <VSkeleton
          :width="32"
          width-unit="px"
          :dynamic-width="false"
          :height="32"
          :pill="false"
          class="w-8 h-8 p-1.5 rounded-lg bg-gray-100 dark:bg-dark-700"
        />
        <VSkeleton
          :width="80"
          width-unit="px"
          :dynamic-width="false"
          :height="20"
          :pill="true"
        />
      </template>
      <template v-else>
        <VSkeleton
          :width="24"
          width-unit="px"
          :dynamic-width="false"
          :height="24"
          :pill="false"
          class="rounded-lg bg-gray-100 dark:bg-dark-700"
        />
        <VSkeleton
          :width="orientation === 'vertical' ? 160 : 80"
          width-unit="px"
          :dynamic-width="false"
          :height="20"
          :pill="true"
        />
      </template>
    </div>
  </template>
  <VTooltip v-else :content="props.tooltip" :placement="props.tooltipPosition" :disabled="!props.tooltip">
    <template #reference>
      <div
        :class="[itemClasses]"
        role="tab"
        :tabindex="disabled ? -1 : 0"
        :aria-selected="activeIndex === index"
        :aria-disabled="disabled"
        @click="handleClick"
        @keydown="handleKeyDown"
      >
        <div
          v-if="props.icon"
          :class="[iconClasses]"
        >
          <FontAwesomeIcon :icon="props.icon" class="text-base" />
        </div>
        <span
          ref="titleRef"
          :class="[titleClasses]"
        >
          {{ props.title }}
        </span>
      </div>
    </template>
  </VTooltip>
</template>
