<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { translationKey, entityField = "name", onConfirm } = defineProps<{
  translationKey: string
  entityField?: string
  onConfirm: (entity: any) => Promise<any>
}>();

const loading = ref(false);

const visible = defineModel<boolean>("visible");
const entity = ref<any | null>(null);

function show(inputEntity: any) {
  entity.value = inputEntity;
  visible.value = true;
}

function close() {
  entity.value = undefined;
  visible.value = false;
}

defineExpose({
  show,
  close,
});

async function handleConfirm() {
  if (loading.value || !entity.value) {
    return;
  }
  try {
    loading.value = true;
    await onConfirm?.(entity.value);
  } finally {
    loading.value = false;
  }
}

const { t } = useI18n();
const toggleDialogConfig = computed(() => {
  const isActive = entity.value?.active === true;
  return {
    id: "toggle-dialog",
    title: t(`${translationKey}.toggle_${isActive ? "deactivate" : "activate"}`),
    description: t(`${translationKey}.toggle_${isActive ? "deactivate" : "activate"}_confirm_text`),
    icon: isActive ? "eye-slash" : "eye",
    type: isActive ? "warning" : "primary",
    mask: true,
    confirmText: t(`base.toggle_${isActive ? "0" : "1"}`),
    cancelText: t("base.cancel"),
  };
});
</script>

<template>
  <VConfirmDialog
    :visible="visible"
    v-bind="toggleDialogConfig"
    :confirm-loading="loading"
    @cancel="close"
    @confirm="handleConfirm"
  >
    <template #content>
      <div class="text-center flex flex-col items-center">
        <b>"{{ entity?.[entityField] }}"</b>
      </div>
    </template>
  </VConfirmDialog>
</template>

<style scoped>

</style>
