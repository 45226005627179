export enum PermissionAttributesEnum {
  Context = "context.*",
  ContextClient = "context.client.*",
  ContextClientField = "context.client.field.*",
  ContextClientOperation = "context.client.operation.*",
  ContextClientOperationApp = "context.client.operation.app.*",
  ContextClientOperationAppCreate = "context.client.operation.app.create",
  ContextClientOperationAppDelete = "context.client.operation.app.delete",
  ContextClientOperationAppRead = "context.client.operation.app.read",
  ContextClientOperationAppUpdate = "context.client.operation.app.update",
  ContextClientOperationAppsubscription = "context.client.operation.app_subscription.*",
  ContextClientOperationAppsubscriptionCreate = "context.client.operation.app_subscription.create",
  ContextClientOperationAppsubscriptionDelete = "context.client.operation.app_subscription.delete",
  ContextClientOperationAppsubscriptionRead = "context.client.operation.app_subscription.read",
  ContextClientOperationAppsubscriptionUpdate = "context.client.operation.app_subscription.update",
  ContextClientOperationClient = "context.client.operation.client.*",
  ContextClientOperationClientRead = "context.client.operation.client.read",
  ContextClientOperationClientUpdate = "context.client.operation.client.update",
  ContextLocation = "context.location.*",
  ContextLocationField = "context.location.field.*",
  ContextLocationOperation = "context.location.operation.*",
  ContextLocationOperationReport = "context.location.operation.report.*",
  ContextLocationOperationReportCreateguestreport = "context.location.operation.report.create_guest_report",
  ContextOperation = "context.operation.*",
  ContextOperationActivityreportcomment = "context.operation.activity_report_comment.*",
  ContextOperationActivityreportcommentCreate = "context.operation.activity_report_comment.create",
  ContextOperationActivityreportcommentDelete = "context.operation.activity_report_comment.delete",
  ContextOperationActivityreportcommentUpdate = "context.operation.activity_report_comment.update",
  ContextOperationActivitytaskcomment = "context.operation.activity_task_comment.*",
  ContextOperationActivitytaskcommentCreate = "context.operation.activity_task_comment.create",
  ContextOperationActivitytaskcommentDelete = "context.operation.activity_task_comment.delete",
  ContextOperationActivitytaskcommentUpdate = "context.operation.activity_task_comment.update",
  ContextOperationAppsubscriptionlocationsetting = "context.operation.app_subscription_location_setting.*",
  ContextOperationAppsubscriptionlocationsettingCreate = "context.operation.app_subscription_location_setting.create",
  ContextOperationAppsubscriptionlocationsettingDelete = "context.operation.app_subscription_location_setting.delete",
  ContextOperationAppsubscriptionlocationsettingUpdate = "context.operation.app_subscription_location_setting.update",
  ContextOperationGeneralledger = "context.operation.general_ledger.*",
  ContextOperationGeneralledgerCreate = "context.operation.general_ledger.create",
  ContextOperationGeneralledgerDelete = "context.operation.general_ledger.delete",
  ContextOperationGeneralledgerRead = "context.operation.general_ledger.read",
  ContextOperationGeneralledgerUpdate = "context.operation.general_ledger.update",
  ContextOperationLegacynotificationusersubscription = "context.operation.legacy_notification_user_subscription.*",
  ContextOperationLegacynotificationusersubscriptionCreate = "context.operation.legacy_notification_user_subscription.create",
  ContextOperationLegacynotificationusersubscriptionDelete = "context.operation.legacy_notification_user_subscription.delete",
  ContextOperationLegacynotificationusersubscriptionRead = "context.operation.legacy_notification_user_subscription.read",
  ContextOperationLegacynotificationusersubscriptionReadcollectionviauser = "context.operation.legacy_notification_user_subscription.read_collection_via_user",
  ContextOperationLegacynotificationusersubscriptionUpdate = "context.operation.legacy_notification_user_subscription.update",
  ContextOperationLocation = "context.operation.location.*",
  ContextOperationLocationCreate = "context.operation.location.create",
  ContextOperationLocationDelete = "context.operation.location.delete",
  ContextOperationLocationRead = "context.operation.location.read",
  ContextOperationLocationUpdate = "context.operation.location.update",
  ContextOperationMediaobjectreportmetadata = "context.operation.media_object_report_meta_data.*",
  ContextOperationMediaobjectreportmetadataCreate = "context.operation.media_object_report_meta_data.create",
  ContextOperationMediaobjectreportmetadataDelete = "context.operation.media_object_report_meta_data.delete",
  ContextOperationMediaobjectreportmetadataRead = "context.operation.media_object_report_meta_data.read",
  ContextOperationMediaobjecttaskmetadata = "context.operation.media_object_task_metadata.*",
  ContextOperationMediaobjecttaskmetadataCreate = "context.operation.media_object_task_metadata.create",
  ContextOperationMediaobjecttaskmetadataDelete = "context.operation.media_object_task_metadata.delete",
  ContextOperationMediaobjecttaskmetadataRead = "context.operation.media_object_task_metadata.read",
  ContextOperationMediaobjectuserprofilepicturemetadata = "context.operation.media_object_user_profile_picture_meta_data.*",
  ContextOperationMediaobjectuserprofilepicturemetadataCreate = "context.operation.media_object_user_profile_picture_meta_data.create",
  ContextOperationMediaobjectuserprofilepicturemetadataDelete = "context.operation.media_object_user_profile_picture_meta_data.delete",
  ContextOperationMediaobjectuserprofilepicturemetadataRead = "context.operation.media_object_user_profile_picture_meta_data.read",
  ContextOperationPermissiontemplatebracket = "context.operation.permission_template_bracket.*",
  ContextOperationPermissiontemplatebracketCreate = "context.operation.permission_template_bracket.create",
  ContextOperationPermissiontemplatebracketDelete = "context.operation.permission_template_bracket.delete",
  ContextOperationPermissiontemplatebracketRead = "context.operation.permission_template_bracket.read",
  ContextOperationPermissiontemplatebracketUpdate = "context.operation.permission_template_bracket.update",
  ContextOperationPermissiontemplategroup = "context.operation.permission_template_group.*",
  ContextOperationPermissiontemplategroupCreate = "context.operation.permission_template_group.create",
  ContextOperationPermissiontemplategroupDelete = "context.operation.permission_template_group.delete",
  ContextOperationPermissiontemplategroupRead = "context.operation.permission_template_group.read",
  ContextOperationPermissiontemplategroupUpdate = "context.operation.permission_template_group.update",
  ContextOperationProduct = "context.operation.product.*",
  ContextOperationProductReservation = "context.operation.product.reservation.*",
  ContextOperationProductReservationCreate = "context.operation.product.reservation.create",
  ContextOperationProductReservationDelete = "context.operation.product.reservation.delete",
  ContextOperationProductReservationRead = "context.operation.product.reservation.read",
  ContextOperationProductReservationUpdate = "context.operation.product.reservation.update",
  ContextOperationProductTask = "context.operation.product.task.*",
  ContextOperationProductTaskCreate = "context.operation.product.task.create",
  ContextOperationProductTaskDelete = "context.operation.product.task.delete",
  ContextOperationProductTaskRead = "context.operation.product.task.read",
  ContextOperationProductTaskUpdate = "context.operation.product.task.update",
  ContextOperationProperty = "context.operation.property.*",
  ContextOperationPropertyCreate = "context.operation.property.create",
  ContextOperationPropertyDelete = "context.operation.property.delete",
  ContextOperationPropertyRead = "context.operation.property.read",
  ContextOperationPropertyUpdate = "context.operation.property.update",
  ContextOperationPropertygroup = "context.operation.property_group.*",
  ContextOperationPropertygroupCreate = "context.operation.property_group.create",
  ContextOperationPropertygroupDelete = "context.operation.property_group.delete",
  ContextOperationPropertygroupRead = "context.operation.property_group.read",
  ContextOperationPropertygroupUpdate = "context.operation.property_group.update",
  ContextOperationReport = "context.operation.report.*",
  ContextOperationReportCreate = "context.operation.report.create",
  ContextOperationReportDelete = "context.operation.report.delete",
  ContextOperationReportRead = "context.operation.report.read",
  ContextOperationReportTransfertotask = "context.operation.report.transfer_to_task",
  ContextOperationReportUpdate = "context.operation.report.update",
  ContextOperationReporttopic = "context.operation.report_topic.*",
  ContextOperationReporttopicCreate = "context.operation.report_topic.create",
  ContextOperationReporttopicDelete = "context.operation.report_topic.delete",
  ContextOperationReporttopicRead = "context.operation.report_topic.read",
  ContextOperationReporttopicUpdate = "context.operation.report_topic.update",
  ContextOperationReservation = "context.operation.reservation.*",
  ContextOperationReservationCreate = "context.operation.reservation.create",
  ContextOperationReservationDelete = "context.operation.reservation.delete",
  ContextOperationReservationRead = "context.operation.reservation.read",
  ContextOperationReservationUpdate = "context.operation.reservation.update",
  ContextOperationReservationproduct = "context.operation.reservation_product.*",
  ContextOperationReservationproductCreate = "context.operation.reservation_product.create",
  ContextOperationReservationproductDelete = "context.operation.reservation_product.delete",
  ContextOperationReservationproductRead = "context.operation.reservation_product.read",
  ContextOperationReservationproductUpdate = "context.operation.reservation_product.update",
  ContextOperationReservationproperty = "context.operation.reservation_property.*",
  ContextOperationReservationpropertyCreate = "context.operation.reservation_property.create",
  ContextOperationReservationpropertyDelete = "context.operation.reservation_property.delete",
  ContextOperationReservationpropertyRead = "context.operation.reservation_property.read",
  ContextOperationReservationpropertyUpdate = "context.operation.reservation_property.update",
  ContextOperationSort = "context.operation.sort.*",
  ContextOperationSortCreate = "context.operation.sort.create",
  ContextOperationSortDelete = "context.operation.sort.delete",
  ContextOperationSortRead = "context.operation.sort.read",
  ContextOperationSortUpdate = "context.operation.sort.update",
  ContextOperationTaskproduct = "context.operation.task_product.*",
  ContextOperationTaskproductCreate = "context.operation.task_product.create",
  ContextOperationTaskproductDelete = "context.operation.task_product.delete",
  ContextOperationTaskproductRead = "context.operation.task_product.read",
  ContextOperationTaskproductUpdate = "context.operation.task_product.update",
  ContextOperationTasktimeentry = "context.operation.task_time_entry.*",
  ContextOperationTasktimeentryCreate = "context.operation.task_time_entry.create",
  ContextOperationTasktimeentryDelete = "context.operation.task_time_entry.delete",
  ContextOperationTasktimeentryRead = "context.operation.task_time_entry.read",
  ContextOperationTasktimeentryUpdate = "context.operation.task_time_entry.update",
  ContextOperationUser = "context.operation.user.*",
  ContextOperationUserInvite = "context.operation.user.invite",
  ContextOperationUserRead = "context.operation.user.read",
  ContextOperationUserUpdate = "context.operation.user.update",
  ContextOperationUsergroup = "context.operation.user_group.*",
  ContextOperationUsergroupCreate = "context.operation.user_group.create",
  ContextOperationUsergroupDelete = "context.operation.user_group.delete",
  ContextOperationUsergroupRead = "context.operation.user_group.read",
  ContextOperationUsergroupUpdate = "context.operation.user_group.update",
  ContextOperationUserpermission = "context.operation.user_permission.*",
  ContextOperationUserpermissionCreate = "context.operation.user_permission.create",
  ContextOperationUserpermissionDelete = "context.operation.user_permission.delete",
  ContextOperationUserpermissionMutatesimple = "context.operation.user_permission.mutate_simple",
  ContextOperationUserpermissionRead = "context.operation.user_permission.read",
  ContextOperationUserpermissionUpdate = "context.operation.user_permission.update",
  ContextOperationUserverification = "context.operation.user_verification.*",
  ContextOperationUserverificationRead = "context.operation.user_verification.read",
  ContextProperty = "context.property.*",
  ContextPropertyField = "context.property.field.*",
  ContextPropertyOperation = "context.property.operation.*",
  ContextReport = "context.report.*",
  ContextReportCreate = "context.report.create.*",
  ContextReportCreateField = "context.report.create.field.*",
  ContextReportCreateFieldInside = "context.report.create.field.inside",
  ContextReportCreateFieldPriority = "context.report.create.field.priority",
  ContextReportCreateFieldProperty = "context.report.create.field.property",
  ContextReportCreateFieldStatus = "context.report.create.field.status",
  ContextReportDelete = "context.report.delete.*",
  ContextReportDeleteTransferredtotask = "context.report.delete.transferred_to_task",
  ContextReportUpdate = "context.report.update.*",
  ContextReportUpdateField = "context.report.update.field.*",
  ContextReportUpdateFieldDescription = "context.report.update.field.description",
  ContextReportUpdateFieldInside = "context.report.update.field.inside",
  ContextReportUpdateFieldPriority = "context.report.update.field.priority",
  ContextReportUpdateFieldProperty = "context.report.update.field.property",
  ContextReportUpdateFieldSort = "context.report.update.field.sort",
  ContextReportUpdateFieldStatus = "context.report.update.field.status",
  ContextReportUpdateFieldTopic = "context.report.update.field.topic",
  ContextReportshortcut = "context.report_shortcut.*",
  ContextReportshortcutAllreports = "context.report_shortcut.all_reports",
  ContextReportshortcutGuestreports = "context.report_shortcut.guest_reports",
  ContextReportshortcutMyreports = "context.report_shortcut.my_reports",
  ContextReportshortcutOpenreports = "context.report_shortcut.open_reports",
  ContextReportshortcutPerproperty = "context.report_shortcut.per_property",
  ContextReportshortcutTopics = "context.report_shortcut.topics",
  ContextReportshortcutTransferredtotask = "context.report_shortcut.transferred_to_task",
  ContextTask = "context.task.*",
  ContextTaskCreate = "context.task.create.*",
  ContextTaskCreateAssigneddirectlytoscope = "context.task.create.assigned_directly_to_scope",
  ContextTaskCreateAssigneddirectlytoself = "context.task.create.assigned_directly_to_self",
  ContextTaskCreateAssignedtoothersinscope = "context.task.create.assigned_to_others_in_scope",
  ContextTaskCreateField = "context.task.create.field.*",
  ContextTaskCreateFieldBlocking = "context.task.create.field.blocking",
  ContextTaskCreateFieldDeadlineat = "context.task.create.field.deadline_at",
  ContextTaskCreateFieldInside = "context.task.create.field.inside",
  ContextTaskCreateFieldPriority = "context.task.create.field.priority",
  ContextTaskCreateFieldProperty = "context.task.create.field.property",
  ContextTaskCreateFieldQuantity = "context.task.create.field.quantity",
  ContextTaskCreateForassignedusergrouplessuser = "context.task.create.for_assigned_user_group_less_user",
  ContextTaskCreateunassigned = "context.task.create_unassigned",
  ContextTaskDelete = "context.task.delete.*",
  ContextTaskDeleteAssigneddirectlytoscope = "context.task.delete.assigned_directly_to_scope",
  ContextTaskDeleteAssigneddirectlytoself = "context.task.delete.assigned_directly_to_self",
  ContextTaskDeleteAssignedtoothersinscope = "context.task.delete.assigned_to_others_in_scope",
  ContextTaskDeleteForassignedusergrouplessuser = "context.task.delete.for_assigned_user_group_less_user",
  ContextTaskDeleteUnassigned = "context.task.delete.unassigned",
  ContextTaskRead = "context.task.read.*",
  ContextTaskReadAssigneddirectlytoscope = "context.task.read.assigned_directly_to_scope",
  ContextTaskReadAssignedofothersinscope = "context.task.read.assigned_of_others_in_scope",
  ContextTaskReadOfassignedusergrouplessuser = "context.task.read.of_assigned_user_group_less_user",
  ContextTaskReadUnassigned = "context.task.read.unassigned",
  ContextTaskUpdate = "context.task.update.*",
  ContextTaskUpdateAssigneddirectlytoscope = "context.task.update.assigned_directly_to_scope",
  ContextTaskUpdateAssigneddirectlytoself = "context.task.update.assigned_directly_to_self",
  ContextTaskUpdateAssignedtoothersinscope = "context.task.update.assigned_to_others_in_scope",
  ContextTaskUpdateField = "context.task.update.field.*",
  ContextTaskUpdateFieldBlocking = "context.task.update.field.blocking",
  ContextTaskUpdateFieldDeadlineat = "context.task.update.field.deadline_at",
  ContextTaskUpdateFieldDescription = "context.task.update.field.description",
  ContextTaskUpdateFieldInside = "context.task.update.field.inside",
  ContextTaskUpdateFieldPriority = "context.task.update.field.priority",
  ContextTaskUpdateFieldProperty = "context.task.update.field.property",
  ContextTaskUpdateFieldQuantity = "context.task.update.field.quantity",
  ContextTaskUpdateFieldSort = "context.task.update.field.sort",
  ContextTaskUpdateFieldStatus = "context.task.update.field.status.*",
  ContextTaskUpdateFieldStatusTochecked = "context.task.update.field.status.to_checked",
  ContextTaskUpdateFieldStatusTofinishedorcheckedfuturedeadlineat = "context.task.update.field.status.to_finished_or_checked_future_deadline_at",
  ContextTaskUpdateForassignedusergrouplessuser = "context.task.update.for_assigned_user_group_less_user",
  ContextTaskUpdateunassigned = "context.task.update_unassigned",
  ContextTaskassignee = "context.task_assignee.*",
  ContextTaskassigneeAssign = "context.task_assignee.assign.*",
  ContextTaskassigneeAssignToothersinscope = "context.task_assignee.assign.to_others_in_scope",
  ContextTaskassigneeAssignUsergroup = "context.task_assignee.assign.user_group",
  ContextTaskassigneeAssignUsergrouplessuser = "context.task_assignee.assign.user_group_less_user",
  ContextTaskassigneeUnassign = "context.task_assignee.unassign.*",
  ContextTaskassigneeUnassignOthersinscope = "context.task_assignee.unassign.others_in_scope",
  ContextTaskassigneeUnassignUsergroup = "context.task_assignee.unassign.user_group",
  ContextTaskassigneeUnassignUsergrouplessusers = "context.task_assignee.unassign.user_group_less_users",
  ContextTaskshortcut = "context.task_shortcut.*",
  ContextTaskshortcutAlltasks = "context.task_shortcut.all_tasks",
  ContextTaskshortcutAlltaskstoday = "context.task_shortcut.all_tasks_today",
  ContextTaskshortcutAlltasksuntiltoday = "context.task_shortcut.all_tasks_until_today",
  ContextTaskshortcutAssignedtome = "context.task_shortcut.assigned_to_me",
  ContextTaskshortcutChecked = "context.task_shortcut.checked",
  ContextTaskshortcutFinished = "context.task_shortcut.finished",
  ContextTaskshortcutMytaskstoday = "context.task_shortcut.my_tasks_today",
  ContextTaskshortcutMytasksuntiltoday = "context.task_shortcut.my_tasks_until_today",
  ContextTaskshortcutPerproperty = "context.task_shortcut.per_property",
  ContextTaskshortcutUnassigned = "context.task_shortcut.unassigned",
  ContextTaskshortcutUser = "context.task_shortcut.user",
  ContextTaskshortcutUsergrouplessuser = "context.task_shortcut.user_group_less_user",
  ContextTaskshortcutUsergroups = "context.task_shortcut.user_groups",
  ContextTaskshortcutWalkroute = "context.task_shortcut.walk_route",
  ContextUsergroup = "context.user_group.*",
  ContextUsergroupField = "context.user_group.field.*",
  ContextUsergroupOperation = "context.user_group.operation.*",
  ContextUserpermission = "context.user_permission.*",
  ContextUserpermissionAccesstype = "context.user_permission.access_type.*",
  ContextUserpermissionAccesstypeRegular = "context.user_permission.access_type.regular",
  ContextUserpermissionAccesstypeTemporarily = "context.user_permission.access_type.temporarily.*",
  ContextUserpermissionAccesstypeTemporarily30days = "context.user_permission.access_type.temporarily.30_days",
  ContextUserpermissionAccesstypeTemporarily48hours = "context.user_permission.access_type.temporarily.48_hours",
  Global = "global.*",
  GlobalOperation = "global.operation.*",
  GlobalOperationActivity = "global.operation.activity.*",
  GlobalOperationActivityReadany = "global.operation.activity.read_any",
  GlobalOperationActivityreportcomment = "global.operation.activity_report_comment.*",
  GlobalOperationActivityreportcommentCreateany = "global.operation.activity_report_comment.create_any",
  GlobalOperationActivityreportcommentDeleteany = "global.operation.activity_report_comment.delete_any",
  GlobalOperationActivityreportcommentUpdateany = "global.operation.activity_report_comment.update_any",
  GlobalOperationActivitytaskcomment = "global.operation.activity_task_comment.*",
  GlobalOperationActivitytaskcommentCreateany = "global.operation.activity_task_comment.create_any",
  GlobalOperationActivitytaskcommentDeleteany = "global.operation.activity_task_comment.delete_any",
  GlobalOperationActivitytaskcommentUpdateany = "global.operation.activity_task_comment.update_any",
  GlobalOperationApp = "global.operation.app.*",
  GlobalOperationAppCreateany = "global.operation.app.create_any",
  GlobalOperationAppDeleteany = "global.operation.app.delete_any",
  GlobalOperationAppReadany = "global.operation.app.read_any",
  GlobalOperationAppUpdateany = "global.operation.app.update_any",
  GlobalOperationAppsubscription = "global.operation.app_subscription.*",
  GlobalOperationAppsubscriptionCreateany = "global.operation.app_subscription.create_any",
  GlobalOperationAppsubscriptionDeleteany = "global.operation.app_subscription.delete_any",
  GlobalOperationAppsubscriptionReadany = "global.operation.app_subscription.read_any",
  GlobalOperationAppsubscriptionUpdateany = "global.operation.app_subscription.update_any",
  GlobalOperationAppsubscriptionimportbatch = "global.operation.app_subscription_import_batch.*",
  GlobalOperationAppsubscriptionimportbatchDelete = "global.operation.app_subscription_import_batch.delete",
  GlobalOperationAppsubscriptionlocationsetting = "global.operation.app_subscription_location_setting.*",
  GlobalOperationAppsubscriptionlocationsettingCreateany = "global.operation.app_subscription_location_setting.create_any",
  GlobalOperationAppsubscriptionlocationsettingDeleteany = "global.operation.app_subscription_location_setting.delete_any",
  GlobalOperationAppsubscriptionlocationsettingReadany = "global.operation.app_subscription_location_setting.read_any",
  GlobalOperationAppsubscriptionlocationsettingUpdateany = "global.operation.app_subscription_location_setting.update_any",
  GlobalOperationClient = "global.operation.client.*",
  GlobalOperationClientCreate = "global.operation.client.create",
  GlobalOperationClientDelete = "global.operation.client.delete",
  GlobalOperationClientReadany = "global.operation.client.read_any",
  GlobalOperationClientUpdate = "global.operation.client.update",
  GlobalOperationDevice = "global.operation.device.*",
  GlobalOperationDeviceDeleteany = "global.operation.device.delete_any",
  GlobalOperationDeviceReadany = "global.operation.device.read_any",
  GlobalOperationDeviceaction = "global.operation.device_action.*",
  GlobalOperationDeviceactionDeleteany = "global.operation.device_action.delete_any",
  GlobalOperationDeviceactionReadany = "global.operation.device_action.read_any",
  GlobalOperationGeneralledger = "global.operation.general_ledger.*",
  GlobalOperationGeneralledgerCreateany = "global.operation.general_ledger.create_any",
  GlobalOperationGeneralledgerDeleteany = "global.operation.general_ledger.delete_any",
  GlobalOperationGeneralledgerReadany = "global.operation.general_ledger.read_any",
  GlobalOperationGeneralledgerUpdateany = "global.operation.general_ledger.update_any",
  GlobalOperationLegacynotification = "global.operation.legacy_notification.*",
  GlobalOperationLegacynotificationCreateany = "global.operation.legacy_notification.create_any",
  GlobalOperationLegacynotificationDeleteany = "global.operation.legacy_notification.delete_any",
  GlobalOperationLegacynotificationReadany = "global.operation.legacy_notification.read_any",
  GlobalOperationLegacynotificationUpdateany = "global.operation.legacy_notification.update_any",
  GlobalOperationLegacynotificationusersubscription = "global.operation.legacy_notification_user_subscription.*",
  GlobalOperationLegacynotificationusersubscriptionCreateany = "global.operation.legacy_notification_user_subscription.create_any",
  GlobalOperationLegacynotificationusersubscriptionDeleteany = "global.operation.legacy_notification_user_subscription.delete_any",
  GlobalOperationLegacynotificationusersubscriptionReadany = "global.operation.legacy_notification_user_subscription.read_any",
  GlobalOperationLegacynotificationusersubscriptionUpdateany = "global.operation.legacy_notification_user_subscription.update_any",
  GlobalOperationLocation = "global.operation.location.*",
  GlobalOperationLocationCreateany = "global.operation.location.create_any",
  GlobalOperationLocationDeleteany = "global.operation.location.delete_any",
  GlobalOperationLocationReadany = "global.operation.location.read_any",
  GlobalOperationLocationUpdateany = "global.operation.location.update_any",
  GlobalOperationMediaobject = "global.operation.media_object.*",
  GlobalOperationMediaobjectDeleteany = "global.operation.media_object.delete_any",
  GlobalOperationMediaobjectReadany = "global.operation.media_object.read_any",
  GlobalOperationMediaobjectappmetadata = "global.operation.media_object_app_meta_data.*",
  GlobalOperationMediaobjectappmetadataDeleteany = "global.operation.media_object_app_meta_data.delete_any",
  GlobalOperationMediaobjectreportmetadata = "global.operation.media_object_report_meta_data.*",
  GlobalOperationMediaobjectreportmetadataCreateany = "global.operation.media_object_report_meta_data.create_any",
  GlobalOperationMediaobjectreportmetadataDeleteany = "global.operation.media_object_report_meta_data.delete_any",
  GlobalOperationMediaobjectreportmetadataReadany = "global.operation.media_object_report_meta_data.read_any",
  GlobalOperationMediaobjecttaskmetadata = "global.operation.media_object_task_metadata.*",
  GlobalOperationMediaobjecttaskmetadataCreateany = "global.operation.media_object_task_metadata.create_any",
  GlobalOperationMediaobjecttaskmetadataDeleteany = "global.operation.media_object_task_metadata.delete_any",
  GlobalOperationMediaobjecttaskmetadataReadany = "global.operation.media_object_task_metadata.read_any",
  GlobalOperationMediaobjectuserprofilepicturemetadata = "global.operation.media_object_user_profile_picture_meta_data.*",
  GlobalOperationMediaobjectuserprofilepicturemetadataCreateany = "global.operation.media_object_user_profile_picture_meta_data.create_any",
  GlobalOperationMediaobjectuserprofilepicturemetadataDeleteany = "global.operation.media_object_user_profile_picture_meta_data.delete_any",
  GlobalOperationMediaobjectuserprofilepicturemetadataReadany = "global.operation.media_object_user_profile_picture_meta_data.read_any",
  GlobalOperationPayloadfield = "global.operation.payload_field.*",
  GlobalOperationPayloadfieldReadcollection = "global.operation.payload_field.read_collection",
  GlobalOperationPermissionresource = "global.operation.permission_resource.*",
  GlobalOperationPermissionresourceReadcollection = "global.operation.permission_resource.read_collection",
  GlobalOperationPermissiontemplatebracket = "global.operation.permission_template_bracket.*",
  GlobalOperationPermissiontemplatebracketCreateany = "global.operation.permission_template_bracket.create_any",
  GlobalOperationPermissiontemplatebracketDeleteany = "global.operation.permission_template_bracket.delete_any",
  GlobalOperationPermissiontemplatebracketReadany = "global.operation.permission_template_bracket.read_any",
  GlobalOperationPermissiontemplatebracketUpdateany = "global.operation.permission_template_bracket.update_any",
  GlobalOperationPermissiontemplategroup = "global.operation.permission_template_group.*",
  GlobalOperationPermissiontemplategroupCreateany = "global.operation.permission_template_group.create_any",
  GlobalOperationPermissiontemplategroupDeleteany = "global.operation.permission_template_group.delete_any",
  GlobalOperationPermissiontemplategroupReadany = "global.operation.permission_template_group.read_any",
  GlobalOperationPermissiontemplategroupUpdateany = "global.operation.permission_template_group.update_any",
  GlobalOperationProduct = "global.operation.product.*",
  GlobalOperationProductCreateany = "global.operation.product.create_any",
  GlobalOperationProductDeleteany = "global.operation.product.delete_any",
  GlobalOperationProductReadany = "global.operation.product.read_any",
  GlobalOperationProductUpdateany = "global.operation.product.update_any",
  GlobalOperationProperty = "global.operation.property.*",
  GlobalOperationPropertyCreateany = "global.operation.property.create_any",
  GlobalOperationPropertyDeleteany = "global.operation.property.delete_any",
  GlobalOperationPropertyReadany = "global.operation.property.read_any",
  GlobalOperationPropertyUpdateany = "global.operation.property.update_any",
  GlobalOperationPropertygroup = "global.operation.property_group.*",
  GlobalOperationPropertygroupCreateany = "global.operation.property_group.create_any",
  GlobalOperationPropertygroupDeleteany = "global.operation.property_group.delete_any",
  GlobalOperationPropertygroupReadany = "global.operation.property_group.read_any",
  GlobalOperationPropertygroupUpdateany = "global.operation.property_group.update_any",
  GlobalOperationReport = "global.operation.report.*",
  GlobalOperationReportCreateany = "global.operation.report.create_any",
  GlobalOperationReportDeleteany = "global.operation.report.delete_any",
  GlobalOperationReportReadany = "global.operation.report.read_any",
  GlobalOperationReportTransfertotaskany = "global.operation.report.transfer_to_task_any",
  GlobalOperationReportUpdateany = "global.operation.report.update_any",
  GlobalOperationReporttopic = "global.operation.report_topic.*",
  GlobalOperationReporttopicCreateany = "global.operation.report_topic.create_any",
  GlobalOperationReporttopicDeleteany = "global.operation.report_topic.delete_any",
  GlobalOperationReporttopicReadany = "global.operation.report_topic.read_any",
  GlobalOperationReporttopicUpdateany = "global.operation.report_topic.update_any",
  GlobalOperationReservation = "global.operation.reservation.*",
  GlobalOperationReservationCreateany = "global.operation.reservation.create_any",
  GlobalOperationReservationDeleteany = "global.operation.reservation.delete_any",
  GlobalOperationReservationReadany = "global.operation.reservation.read_any",
  GlobalOperationReservationUpdateany = "global.operation.reservation.update_any",
  GlobalOperationReservationproduct = "global.operation.reservation_product.*",
  GlobalOperationReservationproductCreateany = "global.operation.reservation_product.create_any",
  GlobalOperationReservationproductDeleteany = "global.operation.reservation_product.delete_any",
  GlobalOperationReservationproductReadany = "global.operation.reservation_product.read_any",
  GlobalOperationReservationproductUpdateany = "global.operation.reservation_product.update_any",
  GlobalOperationReservationproperty = "global.operation.reservation_property.*",
  GlobalOperationReservationpropertyCreateany = "global.operation.reservation_property.create_any",
  GlobalOperationReservationpropertyDeleteany = "global.operation.reservation_property.delete_any",
  GlobalOperationReservationpropertyReadany = "global.operation.reservation_property.read_any",
  GlobalOperationReservationpropertyUpdateany = "global.operation.reservation_property.update_any",
  GlobalOperationSort = "global.operation.sort.*",
  GlobalOperationSortCreateany = "global.operation.sort.create_any",
  GlobalOperationSortDeleteany = "global.operation.sort.delete_any",
  GlobalOperationSortReadany = "global.operation.sort.read_any",
  GlobalOperationSortUpdateany = "global.operation.sort.update_any",
  GlobalOperationSpace = "global.operation.space.*",
  GlobalOperationSpaceAcceptany = "global.operation.space.accept_any",
  GlobalOperationSpaceDeclineany = "global.operation.space.decline_any",
  GlobalOperationSpaceReadany = "global.operation.space.read_any",
  GlobalOperationSsetoken = "global.operation.sse_token.*",
  GlobalOperationSsetokenAny = "global.operation.sse_token.any",
  GlobalOperationTask = "global.operation.task.*",
  GlobalOperationTaskCreateany = "global.operation.task.create_any",
  GlobalOperationTaskDeleteany = "global.operation.task.delete_any",
  GlobalOperationTaskReadany = "global.operation.task.read_any",
  GlobalOperationTaskUpdateany = "global.operation.task.update_any",
  GlobalOperationTaskassignee = "global.operation.task_assignee.*",
  GlobalOperationTaskassigneeCreateany = "global.operation.task_assignee.create_any",
  GlobalOperationTaskassigneeDeleteany = "global.operation.task_assignee.delete_any",
  GlobalOperationTaskassigneeReadany = "global.operation.task_assignee.read_any",
  GlobalOperationTaskassigneeUpdateany = "global.operation.task_assignee.update_any",
  GlobalOperationTaskproduct = "global.operation.task_product.*",
  GlobalOperationTaskproductCreateany = "global.operation.task_product.create_any",
  GlobalOperationTaskproductDeleteany = "global.operation.task_product.delete_any",
  GlobalOperationTaskproductReadany = "global.operation.task_product.read_any",
  GlobalOperationTaskproductUpdateany = "global.operation.task_product.update_any",
  GlobalOperationTasktimeentry = "global.operation.task_time_entry.*",
  GlobalOperationTasktimeentryCreateany = "global.operation.task_time_entry.create_any",
  GlobalOperationTasktimeentryDeleteany = "global.operation.task_time_entry.delete_any",
  GlobalOperationTasktimeentryReadany = "global.operation.task_time_entry.read_any",
  GlobalOperationTasktimeentryUpdateany = "global.operation.task_time_entry.update_any",
  GlobalOperationTopicresource = "global.operation.topic_resource.*",
  GlobalOperationTopicresourceReadcollection = "global.operation.topic_resource.read_collection",
  GlobalOperationUser = "global.operation.user.*",
  GlobalOperationUserDelete = "global.operation.user.delete",
  GlobalOperationUserInviteany = "global.operation.user.invite_any",
  GlobalOperationUserReadany = "global.operation.user.read_any",
  GlobalOperationUserSyncpermissionsany = "global.operation.user.sync_permissions_any",
  GlobalOperationUserUpdateany = "global.operation.user.update_any",
  GlobalOperationUsergroup = "global.operation.user_group.*",
  GlobalOperationUsergroupCreateany = "global.operation.user_group.create_any",
  GlobalOperationUsergroupDeleteany = "global.operation.user_group.delete_any",
  GlobalOperationUsergroupReadany = "global.operation.user_group.read_any",
  GlobalOperationUsergroupUpdateany = "global.operation.user_group.update_any",
  GlobalOperationUserpermission = "global.operation.user_permission.*",
  GlobalOperationUserpermissionCreateany = "global.operation.user_permission.create_any",
  GlobalOperationUserpermissionDeleteany = "global.operation.user_permission.delete_any",
  GlobalOperationUserpermissionReadany = "global.operation.user_permission.read_any",
  GlobalOperationUserpermissionUpdateany = "global.operation.user_permission.update_any",
  GlobalOperationUserverification = "global.operation.user_verification.*",
  GlobalOperationUserverificationReadany = "global.operation.user_verification.read_any",
  GlobalReport = "global.report.*",
  GlobalReportCreate = "global.report.create.*",
  GlobalReportCreateField = "global.report.create.field.*",
  GlobalReportCreateFieldAny = "global.report.create.field.any",
  GlobalReportUpdate = "global.report.update.*",
  GlobalReportUpdateField = "global.report.update.field.*",
  GlobalReportUpdateFieldAny = "global.report.update.field.any",
  GlobalReportshortcut = "global.report_shortcut.*",
  GlobalReportshortcutAny = "global.report_shortcut.any",
  GlobalTask = "global.task.*",
  GlobalTaskCreate = "global.task.create.*",
  GlobalTaskCreateField = "global.task.create.field.*",
  GlobalTaskCreateFieldAny = "global.task.create.field.any",
  GlobalTaskUpdate = "global.task.update.*",
  GlobalTaskUpdateField = "global.task.update.field.*",
  GlobalTaskUpdateFieldAny = "global.task.update.field.any",
  GlobalTaskshortcut = "global.task_shortcut.*",
  GlobalTaskshortcutAny = "global.task_shortcut.any",
  GlobalUser = "global.user.*",
  GlobalUserCreate = "global.user.create.*",
  GlobalUserCreateField = "global.user.create.field.*",
  GlobalUserCreateFieldEmailverifiedat = "global.user.create.field.email_verified_at",
  GlobalUserCreateFieldPlainpassword = "global.user.create.field.plain_password",
  GlobalUserSwitchuserany = "global.user.switch_user_any",
  GlobalUserUpdate = "global.user.update.*",
  GlobalUserUpdateField = "global.user.update.field.*",
  GlobalUserUpdateFieldEmailverifiedat = "global.user.update.field.email_verified_at",
  GlobalUserUpdateFieldLocale = "global.user.update.field.locale",
  GlobalUserUpdateFieldName = "global.user.update.field.name",
  GlobalUserUpdateFieldPlainpassword = "global.user.update.field.plain_password",
  GlobalUserUpdateFieldTimezone = "global.user.update.field.timezone",
  GlobalUserUpdateFieldUsername = "global.user.update.field.username",
}
