import type { ReportHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { ReportVoter } from "./ReportVoter";
import type { ReportEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const reportFieldMapping: Record<
  "description" | "inside" | "priority" | "property" | "sort" | "status" | "topic",
  {
    create: PermissionAttributesEnum | null
    update: PermissionAttributesEnum
  }
> = {
  description: {
    create: null,
    update: PermissionAttributesEnum.ContextReportUpdateFieldDescription,
  },
  inside: {
    create: PermissionAttributesEnum.ContextReportCreateFieldInside,
    update: PermissionAttributesEnum.ContextReportUpdateFieldInside,
  },
  priority: {
    create: PermissionAttributesEnum.ContextReportCreateFieldPriority,
    update: PermissionAttributesEnum.ContextReportUpdateFieldPriority,
  },
  property: {
    create: PermissionAttributesEnum.ContextReportCreateFieldProperty,
    update: PermissionAttributesEnum.ContextReportUpdateFieldProperty,
  },
  sort: {
    create: null,
    update: PermissionAttributesEnum.ContextReportUpdateFieldSort,
  },
  status: {
    create: PermissionAttributesEnum.ContextReportCreateFieldStatus,
    update: PermissionAttributesEnum.ContextReportUpdateFieldStatus,
  },
  topic: {
    create: null,
    update: PermissionAttributesEnum.ContextReportUpdateFieldTopic,
  },
};

type ReportFieldAttribute = keyof typeof reportFieldMapping;

export class ReportFieldVoter extends ContextVoter {
  public reportVoter: ReportVoter;

  constructor(permissionService: PermissionService, reportVoter: ReportVoter) {
    super(permissionService);
    this.reportVoter = reportVoter;
  }

  public hasField = (entity: ReportEntityVote | null, operation: "create" | "update", field: ReportFieldAttribute): boolean => {
    if (reportFieldMapping[field] === undefined || reportFieldMapping[field][operation] === undefined) {
      return false;
    }

    const attribute = reportFieldMapping[field][operation];

    if (attribute === null) {
      return true;
    }

    if (entity) {
      if (this.permissionService.getGlobalPermissionForUser({
        attribute: operation === "create"
          ? PermissionAttributesEnum.GlobalReportCreateFieldAny
          : PermissionAttributesEnum.GlobalReportUpdateFieldAny,
      })) {
        return true;
      }
      return this.reportVoter.hasAccessToAttributeViaReportRelations(entity as ReportHydraItem, attribute);
    }

    return false;
  };
}
