import type { AxiosInstance } from "axios";
import { useAuthStore } from "../AuthStore/useAuthStore";
import { useConfigStore } from "../ConfigStore";
import { createApi } from "./createApi";
import { AcceptLanguageRequestInterceptor, contentTypeInterceptor, JwtBearerTokenRequestInterceptor, LegacyUrlPrefix } from "./Plugin";

export const { api, installInterceptor } = createApi({
  enableErrorHandler: true,
  baseURL: requestConfig =>
    requestConfig.url?.includes("oauth/v2/token")
    || requestConfig.url?.includes("oauth/v2/authorize")
      ? `${useConfigStore().config.value?.loginUrl}`
      : `${useConfigStore().config.value?.apiUrl}`,
  interceptors: [
    JwtBearerTokenRequestInterceptor(
      () => useAuthStore().token.value || "",
      config =>
        (config.url?.includes("/oauth/v2/token")
          || config.url?.includes("/api/public_guest_reports")) ?? false,
    ),
    LegacyUrlPrefix(),
    contentTypeInterceptor({
      excludedRoutes: ["/oauth/v2/token", "/oauth/v2/authorize"],
    }),
    AcceptLanguageRequestInterceptor(),
  ],
});

export default api as AxiosInstance;
