import type { UserPermissionHydraItem, UserStatusHydraItem } from "@verbleif/lib";
import { PermissionAttributesEnum, PermissionScope, UserStateEnum } from "@verbleif/lib";

const permissionStringMap: Record<Exclude<PermissionScope, PermissionScope.GLOBAL>, keyof Pick<UserPermissionHydraItem, "property" | "location" | "client" | "userGroup">> = {
  [PermissionScope.PROPERTY]: "property",
  [PermissionScope.LOCATION]: "location",
  [PermissionScope.CLIENT]: "client",
  [PermissionScope.USER_GROUP]: "userGroup",
};

export function validatePermission(
  {
    attribute,
    permissionAttributes,
  }: {
    attribute: PermissionAttributesEnum
    permissionAttributes: PermissionAttributesEnum[]
  },
): boolean {
  // Split the permission into parts
  const permissionParts = attribute.split(".");

  // Ensure we have at least 3 parts (like in PHP implementation)
  if (permissionParts.length < 3) {
    // if parts are 2 for example global.* or context.* we need to add the full permission
    if (permissionParts.length === 2) {
      return [attribute].some(attr => permissionAttributes.includes(attr));
    }

    throw new Error(`Invalid permission attribute "${attribute}".`);
  }

  // Initialize the required attributes with the full permission
  const requiredAttributes: PermissionAttributesEnum[] = [attribute];

  // Remove the last element as it's never used for wildcards
  permissionParts.pop();

  // Build up the wildcard patterns progressively
  const previousParts: string[] = [];
  for (const part of permissionParts) {
    previousParts.push(part);
    const wildcardPattern = `${previousParts.join(".")}.*` as PermissionAttributesEnum;
    requiredAttributes.push(wildcardPattern);
  }

  // Check if any of the required attributes exist in the permission attributes
  return requiredAttributes.some(attr => permissionAttributes.includes(attr));
}

export class PermissionService {
  public userPermissions: UserPermissionHydraItem[] = [];
  public userIri: string | null = null;
  public mode: "location" | "client" | "global" = "location";
  public defaultLocation: string | null = null;
  public defaultClient: string | null = null;
  public locationClientMap: Map<string, string> = new Map();
  public usersData: Map<string, {
    statuses: UserStatusHydraItem[]
  }> = new Map();

  public init: () => void = () => { };
  public setUserPermissions: () => void = () => { };
  public setUserIri: () => void = () => { };
  public setMode: () => void = () => { };
  public setDefaultLocation: () => void = () => { };
  public setDefaultClient: () => void = () => { };
  public setLocationClientMap: () => void = () => { };
  public setUsersData: () => void = () => { };

  constructor(params: {
    setUserPermissions: () => UserPermissionHydraItem[]
    setUserIri: () => string | null
    setDefaultLocation: () => string | null
    setDefaultClient: () => string | null
    setMode: () => "location" | "client" | "global"
    setLocationClientMap: () => Map<string, string>
    setUsersData: () => Map<string, {
      statuses: UserStatusHydraItem[]
    }>
  } | undefined) {
    if (!params) {
      return;
    }
    this.setUserPermissions = () => this.userPermissions = params.setUserPermissions();
    this.setUserIri = () => this.userIri = params.setUserIri();
    this.setDefaultLocation = () => this.defaultLocation = params.setDefaultLocation();
    this.setDefaultClient = () => this.defaultClient = params.setDefaultClient();
    this.setLocationClientMap = () => this.locationClientMap = params.setLocationClientMap();
    this.setMode = () => this.mode = params.setMode();
    this.setUsersData = () => this.usersData = params.setUsersData();
    this.init = () => {
      this.setUserPermissions();
      this.setUserIri();
      this.setMode();
      this.setDefaultLocation();
      this.setDefaultClient();
      this.setLocationClientMap();
      this.setUsersData();
    };

    this.init();
  }

  public getUsersData(): Map<string, { statuses: UserStatusHydraItem[] }> {
    const activeUsersData = new Map<string, { statuses: UserStatusHydraItem[] }>();

    this.usersData.forEach((value, key) => {
      const activeStatuses = value.statuses.filter(status => status.state === UserStateEnum.ACTIVE);
      if (activeStatuses.length > 0) {
        activeUsersData.set(key, { statuses: activeStatuses });
      }
    });

    return activeUsersData;
  }

  private getPermissionAttributesByScopeKey(
    {
      attributeKey,
    }: {
      attributeKey: string
    },
  ): PermissionAttributesEnum[] {
    const permissionAttributes = Object.values(PermissionAttributesEnum);
    return permissionAttributes.filter(attr => attr.includes(`${attributeKey}`));
  }

  public getClientViaLocation(location: string): string | null {
    const client = this.locationClientMap.get(location);

    if (client) {
      return client;
    }
    return null;
  }

  public getAllPermissionsForUser(
    {
      validOnly = true,
    }: {
      validOnly?: boolean
    } = {},
  ): UserPermissionHydraItem[] {
    const permissions: UserPermissionHydraItem[] = this.userPermissions;

    if (!permissions) {
      return [];
    }

    if (validOnly) {
      return permissions.filter((permission) => {
        if (!permission.acceptedAt) {
          return false;
        }

        // Check the expiresAt
        const expiresAt = permission.expiresAt;
        if (!expiresAt) {
          return true;
        }

        const now = new Date();
        if (new Date(expiresAt).getTime() < now.getTime()) {
          return false;
        }

        return true;
      });
    }

    return permissions;
  }

  public getPermissionsByScope(
    {
      scope,
      validOnly = true,
      attribute,
    }: {
      scope: PermissionScope
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
    },
  ): UserPermissionHydraItem[] {
    const allPermissions = this.getAllPermissionsForUser({ validOnly });

    const permissions = allPermissions.filter((permission) => {
      if (scope !== permission.scope) {
        return false;
      }

      if (attribute) {
        if (Array.isArray(attribute)) {
          if (!attribute.some(attr => validatePermission({ attribute: attr, permissionAttributes: permission.attributes }))) {
            return false;
          }
        } else {
          if (!validatePermission({ attribute, permissionAttributes: permission.attributes })) {
            return false;
          }
        }
      }

      return true;
    });

    return permissions;
  }

  public getPermissionsByScopeLess(
    {
      validOnly = true,
      attribute,
    }: {
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
    },
  ): UserPermissionHydraItem[] {
    const allPermissions = this.getAllPermissionsForUser({ validOnly });

    const permissions = allPermissions.filter((permission) => {
      if (attribute) {
        if (Array.isArray(attribute)) {
          if (!attribute.some(attr => validatePermission({ attribute: attr, permissionAttributes: permission.attributes }))) {
            return false;
          }
        } else {
          if (!validatePermission({ attribute, permissionAttributes: permission.attributes })) {
            return false;
          }
        }
      }

      return true;
    });

    return permissions;
  }

  public getGlobalPermissionForUser(
    {
      validOnly = true,
      attribute,
    }: {
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
    } = {},
  ): UserPermissionHydraItem | null {
    const permissions = this.getPermissionsByScope({
      scope: PermissionScope.GLOBAL,
      validOnly,
      attribute,
    });
    return permissions[0] || null;
  }

  public getScopePermissionForUser(
    {
      iri,
      validOnly = true,
      attribute,
      scope,
      scopeKeyOverride,
    }: {
      iri: string
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
      scope: Exclude<PermissionScope, PermissionScope.GLOBAL>
      scopeKeyOverride?: keyof Pick<UserPermissionHydraItem, "property" | "location" | "client" | "userGroup">
    },
  ): UserPermissionHydraItem | null {
    const permissions = this.getPermissionsByScope(
      {
        scope,
        validOnly,
        attribute,
      },
    );

    const scopeKey = scopeKeyOverride || permissionStringMap[scope];

    for (const permission of permissions) {
      if (!permission[scopeKey]) {
        continue;
      }

      const scopeValue = permission[scopeKey];
      if (scopeValue !== iri) {
        continue;
      }

      return permission;
    }

    return null;
  }

  public getScopeLessPermissionForUser(
    {
      validOnly = true,
      attribute,
      client: clientIri,
      location: locationIri,
    }: {
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
      client: string
      location?: string | null
    },
  ): UserPermissionHydraItem | null {
    const permissions = this.getPermissionsByScopeLess(
      {
        validOnly,
        attribute,
      },
    );

    for (const permission of permissions) {
      if (!permission.client) {
        continue;
      }

      if (permission.client !== clientIri) {
        continue;
      }

      if (locationIri) {
        if (permission.location !== locationIri) {
          continue;
        }
      } else {
        if (permission.location) {
          continue;
        }
      }

      return permission;
    }

    return null;
  }

  public getLocationPermissionForUser(
    {
      locationIri,
      validOnly = true,
      attribute,
    }: {
      locationIri: string
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
    },
  ): UserPermissionHydraItem | null {
    const permissions = this.getPermissionsByScope(
      {
        scope: PermissionScope.LOCATION,
        validOnly,
        attribute,
      },
    );

    for (const permission of permissions) {
      if (!permission.location) {
        continue;
      }

      if (permission.location !== locationIri) {
        continue;
      }

      return permission;
    }

    return null;
  }

  public getClientPermissionForUser(
    {
      clientIri,
      validOnly = true,
      attribute,
    }: {
      clientIri: string
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
    },
  ): UserPermissionHydraItem | null {
    const permissions = this.getPermissionsByScope(
      {
        scope: PermissionScope.CLIENT,
        validOnly,
        attribute,
      },
    );

    for (const permission of permissions) {
      if (!permission.client) {
        continue;
      }

      const permissionClientIri = permission.client;
      if (permissionClientIri !== clientIri) {
        continue;
      }

      return permission;
    }

    return null;
  }

  public getAllIdentitiesViaScope<T extends "client" | "location" | "userGroup" | "property" | undefined = undefined>(
    {
      scope,
      validOnly = true,
      attribute,
      column,
    }: {
      scope: PermissionScope
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
      column?: T
    },
  ): T extends undefined
      ? Array<{
        client: string | null
        location: string | null
        userGroup: string | null
        property: string | null
      }>
      : string[] {
    const identities: Array<{
      client: string | null
      location: string | null
      userGroup: string | null
      property: string | null
    }> = [];

    const permissions = this.getPermissionsByScope(
      {
        scope,
        validOnly,
        attribute,
      },
    );

    for (const permission of permissions) {
      identities.push({
        client: permission.client || null,
        location: permission.location || null,
        userGroup: permission.userGroup || null,
        property: permission.property || null,
      });
    }

    if (column) {
      return identities
        .map(identity => identity[column])
        .filter((id): id is string => id !== null) as T extends undefined ? never : string[];
    }

    return identities as T extends undefined
      ? Array<{
        client: string | null
        location: string | null
        userGroup: string | null
        property: string | null
      }>
      : never;
  }

  public getAllIdentities<T extends "client" | "location" | "userGroup" | "property" | undefined = undefined>(
    {
      validOnly = true,
      attribute,
      column,
    }: {
      validOnly?: boolean
      attribute?: PermissionAttributesEnum | PermissionAttributesEnum[]
      column?: T
    },
  ): T extends undefined
      ? Array<{
        client: string | null
        location: string | null
        userGroup: string | null
        property: string | null
      }>
      : string[] {
    const identities: Array<{
      client: string | null
      location: string | null
      userGroup: string | null
      property: string | null
    }> = [];

    const permissions = this.getAllPermissionsForUser({ validOnly });

    for (const permission of permissions) {
      if (attribute) {
        if (Array.isArray(attribute)) {
          if (!attribute.some(attr => validatePermission({ attribute: attr, permissionAttributes: permission.attributes }))) {
            continue;
          }
        } else {
          if (!validatePermission({ attribute, permissionAttributes: permission.attributes })) {
            continue;
          }
        }
      }

      identities.push({
        client: permission.client || null,
        location: permission.location || null,
        userGroup: permission.userGroup || null,
        property: permission.property || null,
      });
    }

    if (column) {
      return identities
        .map(identity => identity[column])
        .filter((id): id is string => id !== null) as T extends undefined ? never : string[];
    }

    return identities as T extends undefined
      ? Array<{
        client: string | null
        location: string | null
        userGroup: string | null
        property: string | null
      }>
      : never;
  }

  public hasAccessVia({
    iri,
    attribute,
  }: {
    iri: string
    attribute: PermissionAttributesEnum | PermissionAttributesEnum[]
  }): boolean {
    let userPermission: UserPermissionHydraItem | null = null;

    if (iri.includes("/clients/")) {
      userPermission = this.getScopePermissionForUser({ iri, attribute, scope: PermissionScope.CLIENT });
    }

    if (iri.includes("/locations/")) {
      userPermission = this.getScopePermissionForUser({ iri, attribute, scope: PermissionScope.LOCATION });
    }

    if (iri.includes("/user_groups/")) {
      userPermission = this.getScopePermissionForUser({ iri, attribute, scope: PermissionScope.USER_GROUP });
    }

    if (iri.includes("/properties/")) {
      userPermission = this.getScopePermissionForUser({ iri, attribute, scope: PermissionScope.PROPERTY });
    }

    return userPermission !== null;
  }

  public hasAnyContext(attributeKey: string): boolean {
    // First get all permissions for the user
    const allPermissions = this.getAllPermissionsForUser({ validOnly: true });
    // Then get the permission attributes for the scope
    const permissionAttributes = this.getPermissionAttributesByScopeKey({ attributeKey });
    // Then check if any permission has any of the permission attributes
    return allPermissions.some(permission => permissionAttributes.some(attr => validatePermission({ attribute: attr, permissionAttributes: permission.attributes })));
  }

  /**
   * Returns an array of user IRIs that are members of any of the provided user group IRIs
   * @param userGroupIris Array of user group IRIs to check membership against
   * @returns Array of user IRIs that are members of any of the provided user groups
   */
  public getUsersInUserGroups(userGroupIris: string[]): string[] {
    if (!userGroupIris.length) {
      return [];
    }

    const users: string[] = [];

    this.usersData.forEach((userData, userIri) => {
      // Check if any of the user's status objects have a userGroup that matches any in the provided IRIs
      const hasMatchingUserGroup = userData.statuses.some(status =>
        // Check if this status has a userGroup property (UserStatusUserGroup type)
        "userGroup" in status
        && status.userGroup
        // Check if that user group is in our input array
        && userGroupIris.includes(status.userGroup),
      );

      if (hasMatchingUserGroup) {
        users.push(userIri);
      }
    });

    return users;
  }

  /**
   * Returns an array of user IRIs that are associated with any of the provided property IRIs
   * @param propertyIris Array of property IRIs to check associations against
   * @returns Array of user IRIs that are associated with any of the provided properties
   */
  public getUsersInProperties(propertyIris: string[]): string[] {
    if (!propertyIris.length) {
      return [];
    }

    const users: string[] = [];

    this.usersData.forEach((userData, userIri) => {
      // Check if any of the user's status objects have a property that matches any in the provided IRIs
      const hasMatchingProperty = userData.statuses.some(status =>
        // Check if this status has a property property (UserStatusProperty type)
        "property" in status
        && status.property
        // Check if that property is in our input array
        && propertyIris.includes(status.property),
      );

      if (hasMatchingProperty) {
        users.push(userIri);
      }
    });

    return users;
  }
}
