import type { VSidebarItemProps } from "@web/features/Tasks/Sidebar/VSidebarItem.type";
import { OccupationState, type PropertyReservationStateHydraItem, type ReportTopicHydraItem } from "@verbleif/lib";
import { TransitionEvent } from "@verbleif/lib";
import { shortcutVoter, useSystemStore } from "@verbleif/shared";
import { ReportListType } from "@web/features/Reports/ReportsRoutes";
import { sort } from "fast-sort";
import { computed, inject, provide } from "vue";
import { useRoute } from "vue-router";

const ReportListKey = Symbol("ReportListKey");

// function mapToIri(item: string | HydraItem<any>): string {
//   return typeof item === "string" ? item : item["@id"];
// }

function getPropertyIconColor(item: {
  reservationState: PropertyReservationStateHydraItem | null
}) {
  const isBlocked = item.reservationState?.blocked;
  const isOccupied = item.reservationState?.datePointState?.occupationState === OccupationState.OCCUPIED;

  if (isBlocked) {
    return "text-red-500 dark:text-red-400";
  }

  if (isOccupied) {
    return "text-blue-500 dark:text-blue-400";
  }

  return "text-gray-900 dark:text-gray-100";
}

export function createReportList() {
  const route = useRoute();
  const systemStore = useSystemStore();

  const { t } = useI18n();

  const { propertyStates } = usePropertyStates();

  const filteredTopics = computed<ReportTopicHydraItem[]>(() => {
    return systemStore.reportTopics.value;
  });

  const currentListType = computed(() => {
    return route.params.type as ReportListType || null;
  });

  const currentListId = computed(() => {
    return route.params.id as string || null;
  });

  const currentListRouteId = computed(() => {
    return `${currentListType.value}_${currentListId.value}`;
  });

  const currentList = computed(() => {
    switch (currentListType.value) {
      case ReportListType.TYPE_ALL:
      case ReportListType.TYPE_OPEN:
      case ReportListType.TYPE_CREATED_BY_ME:
      case ReportListType.TYPE_GUEST:
      case ReportListType.TYPE_TRANSFERRED:
      case ReportListType.TYPE_PER_PROPERTY:
        return systemStore.properties.value.find((property) => {
          return `${ReportListType.TYPE_PER_PROPERTY}_${property.id}` === currentListRouteId.value;
        }) || null;
      case ReportListType.TYPE_REPORT_TOPIC:
        return filteredTopics.value.find((topic: ReportTopicHydraItem) => {
          return `${ReportListType.TYPE_REPORT_TOPIC}_${topic.id}` === currentListRouteId.value;
        }) || null;
    }
  });

  const loading = computed(() => {
    return !!currentList.value;
  });

  const currentListName = computed(() => {
    if (currentList.value) {
      return currentList.value.name;
    }

    return "";
  });

  const currentListTranslation = computed(() => {
    switch (currentListType.value) {
      case ReportListType.TYPE_ALL:
        return "reports.type.all";
      case ReportListType.TYPE_OPEN:
        return "reports.type.open";
      case ReportListType.TYPE_CREATED_BY_ME:
        return "reports.type.created_by_me";
      case ReportListType.TYPE_GUEST:
        return "reports.type.guest";
      case ReportListType.TYPE_TRANSFERRED:
        return "reports.type.transfer_to_task";
      case ReportListType.TYPE_REPORT_TOPIC:
        return "reports.type.topic";
      case ReportListType.TYPE_PER_PROPERTY:
        return "reports.type.per_property";
      default:
        return "";
    }
  });

  const propertyTabs = computed<VSidebarItemProps[]>(() => {
    const rawProperties = systemStore.properties.value.filter(property => property.active);
    const propertiesWithGroup = sort(rawProperties.map((property) => {
      const propertyGroup = systemStore.propertyGroupsIndexedByIri.value[property.group];
      return {
        ...property,
        groupName: propertyGroup?.name ?? null,
        groupId: propertyGroup?.id ?? null,
      };
    })).asc([
      property => property.groupName ?? "",
      property => property.name,
    ]);

    const items: VSidebarItemProps[] = [];

    // First collect all groups and their properties
    const groupedProperties = propertiesWithGroup.reduce((acc, property) => {
      // Skip properties without a group
      if (!property.groupName) {
        return acc;
      }

      if (!acc[property.groupName]) {
        acc[property.groupName] = {
          properties: [],
          groupId: property.groupId,
        };
      }
      acc[property.groupName].properties.push(property);
      return acc;
    }, {} as Record<string, { properties: typeof propertiesWithGroup, groupId: number | null }>);

    // Add groups and their properties
    Object.entries(groupedProperties).forEach(([groupName, group]) => {
      // Create group item with properties as children
      const groupItem: VSidebarItemProps = {
        routeId: `${ReportListType.TYPE_PER_PROPERTY}_group_${group.groupId}`,
        text: groupName,
        icon: ["fad", "chart-tree-map"],
        iconColor: "text-gray-500",
        // hideSpacer: true,
        children: group.properties.map((property) => {
          const propertyState = propertyStates.value[property.id];
          const isSwitching = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.SWITCH;
          const isBlocked = propertyState?.data.reservationState?.blocked;
          const isArriving = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.ARRIVAL;
          const isDeparting = propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.DEPARTURE;

          const rightIcons: Array<{
            icon: [string, string]
            tooltip: string
            color: string
            tooltipColor?: string
            showInTooltip: boolean
          }> = [];

          if (isSwitching) {
            rightIcons.push({
              icon: ["fad", "exchange"],
              tooltip: t("tasks.section.object_status.switching"),
              color: "text-blue-500 dark:text-blue-400",
              showInTooltip: true,
            });
          }

          if (isBlocked) {
            rightIcons.push({
              icon: ["fad", "lock"],
              tooltip: t("tasks.section.object_status.blocked"),
              color: propertyState?.data.reservationState?.datePointState?.transitionEvent === TransitionEvent.NONE
                ? "text-neutral-600 dark:text-neutral-400"
                : "text-red-500 dark:text-red-400",
              showInTooltip: true,
            });
          }

          if (isArriving) {
            rightIcons.push({
              icon: ["fad", "left-to-bracket"],
              tooltip: t("tasks.section.object_status.arriving"),
              color: "text-green-500 dark:text-green-400",
              showInTooltip: true,
            });
          }

          if (isDeparting) {
            rightIcons.push({
              icon: ["fad", "right-from-bracket"],
              tooltip: t("tasks.section.object_status.departing"),
              color: "text-red-500 dark:text-red-400",
              showInTooltip: true,
            });
          }
          return {
            routeId: `${ReportListType.TYPE_PER_PROPERTY}_${property.id}`,
            text: property.name,
            icon: ["fad", "house"],
            to: {
              name: "report-list",
              params: {
                type: ReportListType.TYPE_PER_PROPERTY,
                id: property.id,
              },
            },
            children: [], // Required by VSidebarItemProps but empty
            iconColor: propertyState ? getPropertyIconColor(propertyState.data) : "text-gray-900 dark:text-gray-100",
            rightIcons: rightIcons.length > 0 ? rightIcons : undefined,
          };
        }),
      };
      items.push(groupItem);
    });

    return items;
  });

  const visiblePropertyTabs = reactive<Record<string, boolean>>(
    propertyTabs.value.reduce((acc, property) => {
      acc[property.routeId] = true;
      return acc;
    }, {} as Record<string, boolean>),
  );

  return {
    currentListName,
    currentList,
    currentListId,
    currentListType,
    currentListTranslation,
    loading,
    filteredTopics,
    propertyTabs,
    visiblePropertyTabs,
  };
}

export function provideReportList() {
  const inst = createReportList();
  provide(ReportListKey, inst);
  return inst;
}

type UseReportList = ReturnType<typeof createReportList>;
export function useReportList() {
  const inst = inject<UseReportList>(ReportListKey);

  if (!inst) {
    throw new Error("Run provideReportList before useReportList");
  }

  return inst;
}

export const isFocusedOnReportShortcut = computed(() => {
  return shortcutVoter.isFocusedOnReportShortcut();
});
