import type { ReservationDatePointState } from "@verbleif/lib";
import { OccupationState, TransitionEvent } from "@verbleif/lib";

export const iconDatePointMap = {
  [TransitionEvent.NONE]: ["far", "square"],
  [TransitionEvent.ARRIVAL]: ["fad", "left-to-bracket"],
  [TransitionEvent.DEPARTURE]: ["fad", "right-from-bracket"],
  [TransitionEvent.SWITCH]: ["fad", "exchange"],
} as const;

export const colorDatePointMap = {
  [TransitionEvent.NONE]: "text-gray-500",
  [TransitionEvent.ARRIVAL]: "text-green-500",
  [TransitionEvent.DEPARTURE]: "text-red-500",
  [TransitionEvent.SWITCH]: "text-blue-500",
} as const;

export function useDatePointState(datePointState: ReservationDatePointState) {
  const {
    transitionEvent,
    daysUntilOccupationStateEnds,
    daysSinceOccupationStateStarted,
    nextOccupationDuration,
    nextTransitionEvent,
    daysUntilNextTransition,
    occupationState,
  } = datePointState;

  function getSubText() {
    // Only check for Today or Tomorrow if we have a transition event
    if (transitionEvent !== TransitionEvent.NONE) {
      // Check if it's Today or Tomorrow based on daysUntilOccupationStateEnds
      if (daysUntilOccupationStateEnds === 0) {
        return {
          key: "tasks.reservation_state.date_point.subtitle.today",
          params: {},
        };
      } else if (daysUntilOccupationStateEnds === 1) {
        return {
          key: "tasks.reservation_state.date_point.subtitle.tomorrow",
          params: {},
        };
      }

      // Handle transition events
      switch (transitionEvent) {
        case TransitionEvent.SWITCH:
          if (daysUntilNextTransition !== undefined && daysUntilNextTransition > 0) {
            return {
              key: "tasks.reservation_state.date_point.subtitle.in_days",
              params: { n: daysUntilNextTransition },
            };
          }
          break;
        case TransitionEvent.DEPARTURE:
          if (nextOccupationDuration !== undefined && nextOccupationDuration > 0) {
            return {
              key: "tasks.reservation_state.date_point.subtitle.empty_days",
              params: { n: nextOccupationDuration },
            };
          }
          break;
        case TransitionEvent.ARRIVAL:
          if (daysSinceOccupationStateStarted !== undefined && daysSinceOccupationStateStarted > 0) {
            return {
              key: "tasks.reservation_state.date_point.subtitle.was_empty_days",
              params: { n: daysSinceOccupationStateStarted },
            };
          }
          break;
      }

      // Fallback to today if transition event data is incomplete
      return {
        key: "tasks.reservation_state.date_point.subtitle.today",
        params: {},
      };
    }

    // For occupation states, hide subtext when all occupation states are the same
    if (
      occupationState === datePointState.nextOccupationState
      && occupationState === datePointState.previousOccupationState
    ) {
      return { key: "", params: {} };
    }

    // Handle occupation states
    const totalDays = daysUntilOccupationStateEnds + daysSinceOccupationStateStarted;

    // If no start days available, fallback to today
    if (!daysSinceOccupationStateStarted || daysSinceOccupationStateStarted <= 0) {
      return {
        key: "tasks.reservation_state.date_point.subtitle.today",
        params: {},
      };
    }

    // Determine state-specific keys
    const statePrefix = occupationState === OccupationState.VACANT
      ? "vacant"
      : occupationState === OccupationState.OCCUPIED
        ? "occupied"
        : "unknown";

    // If we can't determine the total days, show only the current day
    if (!totalDays || Number.isNaN(totalDays)) {
      return {
        key: `tasks.reservation_state.date_point.subtitle.${statePrefix}_day`,
        params: { current: daysSinceOccupationStateStarted },
      };
    }

    // Show day of total
    return {
      key: `tasks.reservation_state.date_point.subtitle.${statePrefix}_day_of`,
      params: {
        current: daysSinceOccupationStateStarted,
        total: totalDays,
      },
    };
  }

  function getUpperSection() {
    // Don't show upper section if we're already showing a transition event
    if (transitionEvent !== TransitionEvent.NONE) {
      return null;
    }

    // Don't show upper section if there's no next transition or it's the same as the current state
    if (!nextTransitionEvent
      || nextTransitionEvent === TransitionEvent.NONE
      || nextOccupationDuration === 0
      || daysUntilNextTransition === 0) {
      return null;
    }

    const upperColor = "text-gray-500";
    const transitionKey = `tasks.reservation_state.date_point.transition.${nextTransitionEvent}`;
    const upperIcon = iconDatePointMap[nextTransitionEvent] || iconDatePointMap[TransitionEvent.ARRIVAL];

    return {
      transitionKey,
      subtitleKey: "tasks.reservation_state.date_point.upper_subtitle.in_days",
      subtitleParams: { n: daysUntilNextTransition },
      icon: upperIcon,
      color: upperColor,
    };
  }

  function getStateIcon() {
    if (transitionEvent !== TransitionEvent.NONE) {
      return iconDatePointMap[transitionEvent];
    }

    if (occupationState === OccupationState.VACANT) {
      return ["far", "square"];
    }

    if (occupationState === OccupationState.OCCUPIED) {
      return ["fad", "house-user"];
    }

    return ["fad", "question"];
  }

  const upperSectionData = getUpperSection();
  const hasUpperSection = upperSectionData !== null;

  const stateColor = hasUpperSection
    ? "text-gray-500"
    : colorDatePointMap[transitionEvent];

  let mainTransitionKey;
  if (transitionEvent === TransitionEvent.NONE) {
    mainTransitionKey = occupationState === OccupationState.VACANT
      ? "tasks.reservation_state.date_point.occupation.2"
      : "tasks.reservation_state.date_point.occupation.1";
  } else {
    mainTransitionKey = `tasks.reservation_state.date_point.transition.${transitionEvent}`;
  }

  return {
    iconDatePointMap,
    colorDatePointMap,
    stateIcon: getStateIcon(),
    getSubText: getSubText(),
    upperSection: upperSectionData,
    transitionEvent,
    occupationState,
    nextTransitionEvent,
    daysUntilNextTransition,
    stateColor,
    mainTransitionKey,
  };
}
