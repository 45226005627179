<script setup lang="ts">
import { watch } from "vue";
import { VModernSelect } from "../../components";
import { useCursor, usePaginate } from "../../composables";

interface Props {
  perPageText?: string
  options?: Record<number, string>
}

const props = withDefaults(defineProps<Props>(), {
  perPageText: "",
  options: () => ({
    10: "10",
    20: "20",
    50: "50",
    100: "100",
  }),
});

const { perPage } = useCursor();
const { resetAndLoad } = usePaginate();

watch(perPage, () => resetAndLoad());
</script>

<template>
  <div class="per-page">
    <VModernSelect
      v-model="perPage"
      :placeholder="props.perPageText"
      :items="Object.entries(props.options).map(([key, text]) => ({ id: Number(key), name: text }))"
      key-field-name="id"
      search-field-name="name"
      size="medium"
      hide-clear
    />
  </div>
</template>

<style lang="scss" scoped>
.per-page {
  width: 200px;
  margin-right: 10px;
}
</style>
