import type { RouteRecordRaw } from "vue-router";

export const AuthRoutes = <RouteRecordRaw[]>[
  {
    name: "login-root",
    path: "/",
    component: () => import("./AuthContainer.vue"),
    children: [
      {
        path: "",
        name: "entry",
        redirect: { name: "login" },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "login" },
      },
      {
        path: "login",
        name: "login",
        component: () => import("./Login/LoginView.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "spaces",
        name: "spaces",
        component: () => import("./Location/ChooseSpace.vue"),
        meta: {
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "impersonate/:username",
        name: "impersonate",
        component: () => import("./Impersonate/ImpersonateView.vue"),
        meta: {
          skipAuthChecks: true,
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "oauth/v2/callback",
        name: "callback",
        component: () => import("@web/features/Auth/Login/LoginCallback.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "apps/:appId/oauth/callback",
        name: "external-app-install",
        component: () => import("./ExternalAppInstall/ExternalAppInstallation.vue"),
        meta: {
          skipLocationCheck: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "error",
        name: "error",
        component: () => import("./Login/LoginError.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "404",
        name: "404",
        component: () => import("./404Page.vue"),
        meta: {
          public: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("@web/features/Auth/Logout/LogoutPage.vue"),
        meta: {
          skipLocationCheck: true,
          hideNavbar: true,
          showTheme: true,
          showLanguage: true,
        },
      },
    ],
  },
];

export default AuthRoutes;
