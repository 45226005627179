<script setup lang="ts">
import type { ModernSelectModelValue, ModernSelectProps } from "./VModernSelectProps.interface";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { isAxiosError } from "axios";
import { Dropdown as VDropdown } from "floating-vue";
import { computed, defineProps, getCurrentInstance, inject, ref, toValue, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import {
  providePaginate,
  provideSearchable,
  useLazyLoadScroll,
  useWidthCalculator,
} from "../../composables";
import ACTooltip from "../ACTooltip/ACTooltip.vue";
import VSkeleton from "../Skeleton/VSkeleton.vue";
import { useComponentId } from "../useComponentId";
import ListItem from "./ListItem.vue";
import { useArrowControls } from "./useArrowControls";
import { useKeyDown } from "./useKeyDown";

defineOptions({
  name: "VModernSelect",
  inheritAttrs: false,
});

const {
  rootClass = "",
  modelValue = null,
  iconLeft = null,
  iconRight = "caret-down",
  loading = false,
  autoSize = true,
  disabled = false,
  errorMessage = null,
  hideClear = false,
  toolTip = undefined,
  clearSearchOnSelect = false,
  clearValueOnSelect = false,
  singleItemAutoSelect = false,
  hasPerms = true,
  keyFieldName = null,
  uniqueKeyOfObject = "id",
  searchFieldName = "name" as Extract<keyof any, string>,
  disabledFieldName = undefined,
  noResultsText = "No results.",
  refreshText = "Refresh",
  paramOverride,
  labelLeft = "",
  required = false,
  debug = false,
  placeholder = null,
  items = [],
  excludedItems = [],
  onLoad = null,
  loadParams = () => { },
  initialItemProvider = async () => null,
  variant = "is-gray",
  showGroup,
  watchForChange = null,
  size = "medium",
  groupFieldName = null,
  placement = "bottom-start",
  showSeparator = true,
  scrollToSelected = true,
  confirmOnSelect = undefined,
  confirmOnSelectCallback = undefined,
} = defineProps<ModernSelectProps>();

const emit = defineEmits<{
  "update:modelValue": [value: any | ModernSelectModelValue]
  "update:allItems": [data: any[] | Record<string, any>[]]
  "keydown": [event: KeyboardEvent]
  "itemSelected": [item: any]
  "update:searchValue": [value: string]
  "blur": [event: FocusEvent]
  "onShow": []
  "onHide": []
}>();

const fallbackId = useComponentId();
const id = inject("FieldId", fallbackId);
const dropdown = ref<InstanceType<typeof VDropdown> | null>(null);

const { containerRef: iconLeftRef } = useWidthCalculator({
  initialWidth: 14,
  minWidth: 10,
  maxWidth: 40,
  observe: true,
});

const itemListContainer = ref<HTMLElement | null>(null);
const selectedItemRef = ref<object | null>(null);
const valueRef = ref<any | null>(modelValue);
const isDisabled = computed(() => {
  return disabled || !hasPerms;
});

function show() {
  if (loading || isDisabled.value) {
    return;
  }
  dropdown.value?.show();

  // Handle scrolling to selected item after dropdown is shown
  if (scrollToSelected && valueRef.value !== null) {
    setTimeout(() => {
      const container = itemListContainer.value;
      if (!container) {
        return;
      }

      // Find the active/selected item
      const selectedElement = container.querySelector(".is-active");
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest" });
      }
    }, 50); // Small delay to ensure the dropdown is fully rendered
  }
}

function hide() {
  dropdown.value?.hide();
}
const initialItemErrorCode = ref(0);
if (typeof initialItemProvider === "function") {
  initialItemProvider(modelValue).then((initialItem) => {
    initialItemErrorCode.value = 0;
    if (typeof initialItem === "object") {
      selectedItemRef.value = initialItem;
    }
  }).catch((error) => {
    if (isAxiosError(error)) {
      initialItemErrorCode.value = error.response?.status || 0;
    }
  });
}

let paginator: ReturnType<typeof providePaginate<any>> | null = null;
if (typeof onLoad === "function") {
  paginator = providePaginate({
    tableFields: {},
    sortableFields: {},
    searchableFields: [searchFieldName],
    initialPerPage: 25,
    onLoad,
    loadParams,
    cursorParams: { partial: true },
    paramOverride,
  });

  watch(() => onLoad, () => {
    if (!paginator || typeof onLoad !== "function") {
      return;
    }
    paginator.setOnLoad(onLoad);
    paginator.resetAndLoad();
  });

  useLazyLoadScroll(
    itemListContainer,
    (options) => {
      if (!paginator) {
        return;
      }
      paginator.cursor.currentPage.value++;
      paginator.load(options);
    },
    paginator.cursor.hasNextPage,
    paginator.loading,
  );
}

const allItems = computed(() => {
  if (paginator) {
    return paginator.items.value as Record<string, any>[];
  }

  return items;
});

const {
  searchValue,
  disableSearch,
  enableSearch,
  debouncedValue: debouncedSearchValue,
  filtered,
  highlight,
  searchEnabled,
}: ReturnType<typeof provideSearchable<any>> = paginator?.searchable || provideSearchable<any>(
  [],
  allItems,
  searchFieldName,
  paramOverride,
);

// Filtered result set, not always the full result set.
// When passing a excludedItems for example (when using a tag-input by default) the selected items are filtered out.
const dropdownItems = computed(() => {
  // Paginator handles the filtering since filtering is on the API side.
  let items = paginator ? allItems.value : filtered.value;

  if (excludedItems?.length) {
    items = items.filter(
      (item: any | ModernSelectModelValue) => !excludedItems.some(
        (excludedItem: any | ModernSelectModelValue) => {
          if (item === null || excludedItem === null) {
            return true;
          }

          if (typeof item !== "object" && typeof excludedItem !== "object") {
            return item === excludedItem;
          }

          if (typeof keyFieldName === "string" && typeof item === "object" && typeof excludedItem !== "object") {
            if (!(keyFieldName in item)) {
              return true;
            }

            return item[keyFieldName] === excludedItem;
          }

          if (typeof item === "object" && typeof excludedItem === "object") {
            if (!(uniqueKeyOfObject in item) || !(uniqueKeyOfObject in excludedItem)) {
              return true;
            }

            return item[uniqueKeyOfObject] === excludedItem[uniqueKeyOfObject];
          }

          return true;
        },
      ),
    );
  }

  return items;
});

function isItemDisabled(item: any) {
  if (!disabledFieldName || typeof item !== "object") {
    return false;
  }
  return !!item[disabledFieldName];
}

const { onArrowDown, onArrowUp, onEnter, hovered } = useArrowControls(
  dropdown,
  dropdownItems,
  (item) => {
    if (!isItemDisabled(item)) {
      setValueRef(item);
    }
  },
);
const { onKeyDown } = useKeyDown(show, emit);

// If the change comes from the outside, we need to update the valueRef
watch(() => modelValue, (newValue, oldValue) => {
  if (!keyFieldName) {
    if (newValue?.[uniqueKeyOfObject] === oldValue?.[uniqueKeyOfObject]) {
      return;
    }
  }

  if (newValue === oldValue) {
    return;
  }

  valueRef.value = newValue;
});

watch(() => valueRef.value, (newValue, oldValue) => {
  if (!keyFieldName) {
    if (newValue && !(uniqueKeyOfObject in newValue)) {
      console.error(`Warning: Prop: uniqueKeyOfObject does neither exist in newValue nor oldValue. Given unique key: ${uniqueKeyOfObject}. Object keys: ${Object.keys(newValue).join(", ")}`);
    }
    if (newValue?.[uniqueKeyOfObject] === oldValue?.[uniqueKeyOfObject]) {
      return;
    }
  }

  if (newValue === oldValue) {
    return;
  }

  // No need to emit null when clear on select is set.
  if (newValue === null && clearValueOnSelect) {
    return;
  }

  emit("update:modelValue", newValue);

  // Don't update when valueRef is set to null.
  // When a value is chosen, and you start searching. The valueRef is set to null.
  if (newValue === null) {
    return;
  }

  // Only hide when newValue is not null.
  hide();

  if (clearValueOnSelect) {
    valueRef.value = null;
  }
});

// We cannot watch, because we change the searchValue inside the valueRef.
// Search should only be triggered when the user inputs and never programmatically.
function onSearchInput(v: EventTarget | null) {
  if (!v) {
    return;
  }
  const target = v as HTMLInputElement;
  emit("update:searchValue", target.value);
  valueRef.value = null;
  enableSearch();
  searchValue.value = target.value;
}

function clearSearchAndResetValue() {
  valueRef.value = null;
  searchValue.value = "";
  selectedItemRef.value = null;
  emit("update:modelValue", null);
}

watch(() => watchForChange, () => {
  clearSearchAndResetValue();
  if (!paginator) {
    return;
  }
  paginator.resetAndLoad();
}, { deep: true });

watch(debouncedSearchValue, () => {
  if (!paginator) {
    return;
  }
  if (searchEnabled.value === false) {
    return;
  }

  paginator.resetAndLoad();
});

watch(searchEnabled, (newVal) => {
  if (!paginator) {
    return;
  }
  if (newVal === true) {
    // This is handled by the debouncedSearchValue.
    return;
  }

  paginator.resetAndLoad();
});

watchEffect(() => {
  emit("update:allItems", allItems.value);
});

const selectedItem = computed<any | ModernSelectModelValue>(() => {
  if (typeof keyFieldName !== "string") {
    return valueRef.value;
  }

  if (selectedItemRef.value) {
    return selectedItemRef.value;
  }

  // Contains the full object when a keyFieldName is set.
  // if ((paginator && paginator.loading.value)) {
  //   return valueRef.value;
  // }

  const result = allItems.value.find((v: any) => {
    if (typeof keyFieldName !== "string") {
      return false;
    }

    return v?.[keyFieldName] === valueRef.value;
  });

  return result || null;
});

watch(selectedItem, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  let newSearchValue = null;
  if (typeof newValue === "object") {
    newSearchValue = newValue?.[searchFieldName];
  }

  // This is a temp fix for "model.value" if that is set then we should set it back to default
  if (newValue === null && searchFieldName === "value") {
    newSearchValue = valueRef.value;
  }

  if (clearSearchOnSelect || typeof newSearchValue !== "string") {
    newSearchValue = "";
  }

  disableSearch();
  searchValue.value = newSearchValue;
}, { immediate: true });

watchEffect(() => {
  if (!singleItemAutoSelect) {
    return;
  }

  if (allItems.value.length === 1) {
    setValueRef(allItems.value[0]);
  }
});

async function setValueRef(newValue: any) {
  initialItemErrorCode.value = 0;

  // Don't set disabled items
  if (typeof newValue === "object" && disabledFieldName && newValue[disabledFieldName]) {
    return;
  }

  // Handle confirmation if needed
  if (confirmOnSelect && typeof confirmOnSelect === "function" && confirmOnSelect(newValue)) {
    if (confirmOnSelectCallback && typeof confirmOnSelectCallback === "function") {
      // Hide dropdown immediately to prevent z-index issues with dialog
      hide();

      // Store the selected value temporarily for display purposes
      if (!keyFieldName || typeof newValue !== "object") {
        // For primitive values, just update the display
        searchValue.value = String(newValue);
      } else {
        // For objects, update the display with the search field
        searchValue.value = newValue[searchFieldName] || "";
      }

      // Don't emit the model value update yet, but keep the display updated
      // We'll emit update:modelValue only after confirmation

      // Request confirmation
      const confirmed = await confirmOnSelectCallback(newValue);

      // If confirmed, set the value
      if (confirmed) {
        if (
          !keyFieldName
          || typeof newValue === "string"
          || typeof newValue === "number"
          || typeof newValue === "boolean"
          || typeof newValue === "symbol"
          || newValue === null
        ) {
          valueRef.value = newValue;
        } else {
          if (!(keyFieldName in newValue)) {
            throw new Error(`Property defined in the props.keyFieldName "${keyFieldName}" was not found on newValue. typeof newValue: ${typeof newValue}`);
          }

          selectedItemRef.value = newValue;
          valueRef.value = newValue[keyFieldName];
        }

        emit("update:modelValue", valueRef.value);
        emit("itemSelected", newValue);
      } else {
        // If not confirmed, clear the display
        searchValue.value = "";
        valueRef.value = null;
        selectedItemRef.value = null;
        emit("update:modelValue", null);
      }

      return;
    }
  }

  // Normal flow without confirmation
  if (
    !keyFieldName
    || typeof newValue === "string"
    || typeof newValue === "number"
    || typeof newValue === "boolean"
    || typeof newValue === "symbol"
    || newValue === null
  ) {
    valueRef.value = newValue;
    return;
  }

  if (!(keyFieldName in newValue)) {
    throw new Error(`Property defined in the props.keyFieldName "${keyFieldName}" was not found on newValue. typeof newValue: ${typeof newValue}`);
  }

  selectedItemRef.value = newValue;
  valueRef.value = newValue[keyFieldName];
  emit("itemSelected", newValue);
}

if (paginator) {
  paginator.load();
}

if (debug) {
  const instance = getCurrentInstance();
  let instanceName = instance?.type?.name;
  if (instance?.parent?.type.name === "VTagInput") {
    instanceName = instance.parent.type.name;
  }
  function logState(fieldName: string, newVal: any, oldVal: any) {
    console.group(`${instanceName} ${id}`);
    console.log(`%cwatchEffect triggered by ${fieldName}`, "color:red");
    console.log("%cNew value", "color:green", newVal);
    console.log("%cOld value", "color:purple", oldVal);

    console.log("Paginator?", !!paginator);
    console.log("modelValue", modelValue);
    console.log("errorMessage", errorMessage);
    console.log("onLoad", onLoad);
    console.log("items", items);
    console.log("clearSearchOnSelect", clearSearchOnSelect);
    console.log("clearValueOnSelect", clearValueOnSelect);
    console.log("searchFieldName", searchFieldName);
    console.log("watchForChange", watchForChange);
    console.log("keyFieldName", keyFieldName);

    console.log("dropdownItems.value", dropdownItems.value);
    console.log("excludedItems", excludedItems);
    console.log("loading", loading);
    console.log("valueRef", valueRef.value);
    console.log("selectedItem", selectedItem.value);
    console.log("searchValue", searchValue.value);
    console.log("debouncedSearchValue", debouncedSearchValue.value);
    console.groupEnd();
  }
  watch(
    () => modelValue,
    (newVal, oldVal) => logState("modelValue", newVal, oldVal),
  );
  watch(
    () => errorMessage,
    (newVal, oldVal) => logState("errorMessage", newVal, oldVal),
  );
  watch(
    () => onLoad,
    (newVal, oldVal) => logState("onLoad", newVal, oldVal),
  );
  watch(
    () => items,
    (newVal, oldVal) => logState("items", newVal, oldVal),
  );
  watch(
    () => clearSearchOnSelect,
    (newVal, oldVal) => logState("clearSearchOnSelect", newVal, oldVal),
  );
  watch(
    () => clearValueOnSelect,
    (newVal, oldVal) => logState("clearValueOnSelect", newVal, oldVal),
  );
  watch(
    () => searchFieldName,
    (newVal, oldVal) => logState("searchFieldName", newVal, oldVal),
  );
  watch(
    () => watchForChange,
    (newVal, oldVal) => logState("watchForChange", newVal, oldVal),
  );
  watch(
    () => keyFieldName,
    (newVal, oldVal) => logState("keyFieldName", newVal, oldVal),
  );
  watch(dropdownItems, (newVal, oldVal) =>
    logState("dropdownItems.value", newVal, oldVal));
  watch(allItems, (newVal, oldVal) =>
    logState("allItems.value", newVal, oldVal));
  if (paginator) {
    watch(paginator.loading, (newVal, oldVal) =>
      logState("loading.value", newVal, oldVal));
  }
  watch(valueRef, (newVal, oldVal) =>
    logState("valueRef.value", newVal, oldVal));
  watch(selectedItem, (newVal, oldVal) =>
    logState("computed(selectedItem)", newVal, oldVal));
  watch(searchValue, (newVal, oldVal) =>
    logState("searchValue.value", newVal, oldVal));
  watch(debouncedSearchValue, (newVal, oldVal) =>
    logState("debouncedSearchValue.value", newVal, oldVal));
}

const groupedDropdownItems = computed(() => {
  if (!groupFieldName) {
    return dropdownItems.value;
  }

  const groups: Record<string, any[]> = {};

  dropdownItems.value.forEach((item) => {
    const groupKey = item[groupFieldName];
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(item);
  });

  return Object.entries(groups).map(([key, items]) => ({
    label: key,
    items,
    isGroup: true,
  }));
});

const { t } = useI18n();

const inputPlaceHolder = computed(() => {
  if (initialItemErrorCode.value === 403) {
    return t("permissions.ac.item");
  }

  if (initialItemErrorCode.value !== 0) {
    return t("base.unknown_error");
  }

  return errorMessage !== null ? errorMessage : (placeholder === null ? undefined : placeholder);
});
</script>

<template>
  <VDropdown
    ref="dropdown"
    :popper-class="[rootClass]"
    class="modern-select-wrapper"
    :placement="placement"
    theme="verbleif-select"
    no-auto-focus
    :show-group="showGroup"
    :auto-size="autoSize"
    :triggers="[]"
    :disabled="isDisabled"
    @show="emit('onShow')"
    @hide="emit('onHide')"
    @keydown.up="onArrowUp"
    @keydown.down="onArrowDown"
    @keydown.enter="onEnter"
  >
    <template #popper>
      <slot
        name="popper"
        :items="dropdownItems"
        :grouped-items="groupedDropdownItems"
        :group-field-name="groupFieldName"
        :key-field-name="keyFieldName"
        :unique-key-of-object="uniqueKeyOfObject"
        :search-field-name="searchFieldName"
        :value-ref="valueRef"
        :hovered="hovered"
        :highlight="highlight"
        :set-value-ref="setValueRef"
        :paginator="paginator"
        :variant="variant"
        :is-item-disabled="isItemDisabled"
      >
        <div ref="itemListContainer" class="items">
          <slot v-if="dropdownItems.length === 0" name="empty" :retry="paginator">
            <div class="fake-item">
              <template v-if="toValue(paginator?.errorCode) === 403">
                <div class="flex flex-col justify-center items-center gap-3 p-1 text-center w-full">
                  <FontAwesomeIcon :icon="['far', 'lock']" class="text-2xl text-red-500" />
                  <p class="text-sm text-gray-600 text-xs">
                    {{ t('permissions.ac.list') }}
                  </p>
                </div>
              </template>
              <template v-else>
                <FontAwesomeIcon class="icon" icon="ban" />
                <div class="text">
                  {{ noResultsText }}
                </div>
                <div v-if="paginator" class="retry" @click.stop="() => paginator && paginator.load()">
                  {{ refreshText }}
                </div>
              </template>
            </div>
          </slot>

          <template
            v-for="(item, index) in (groupFieldName ? groupedDropdownItems : dropdownItems)"
            :key="groupFieldName
              ? `group-${index}`
              : (keyFieldName
                ? item[keyFieldName]
                : (item?.[uniqueKeyOfObject] || item))"
          >
            <template v-if="groupFieldName && item.isGroup">
              <!-- Group header -->
              <div class="group-header" :class="variant === 'dark-gray' ? 'text-theme-light-gray-2 dark:text-theme-dark-gray-2' : '' ">
                <slot name="group" :group="item">
                  <b>{{ item.label }}</b>
                </slot>
              </div>

              <!-- Group items -->
              <template
                v-for="(groupItem, groupItemIndex) in item.items"
                :key="keyFieldName
                  ? groupItem[keyFieldName]
                  : (groupItem?.[uniqueKeyOfObject] || groupItem)"
              >
                <ListItem
                  :hovered="hovered"
                  :current-value="valueRef"
                  :key-field-name="keyFieldName"
                  :unique-key-of-object="uniqueKeyOfObject"
                  :item="groupItem"
                  :disabled="isItemDisabled(groupItem)"
                  @click.stop="() => !isItemDisabled(groupItem) && setValueRef(groupItem)"
                >
                  <slot name="item" :item="groupItem" :current-value="valueRef" :label="highlight(groupItem[searchFieldName])" :disabled="isItemDisabled(groupItem)">
                    <div v-html="highlight(groupItem[searchFieldName])" />
                  </slot>
                </ListItem>
                <div v-if="showSeparator && groupItemIndex < item.items.length - 1" class="h-px bg-gray-200 dark:bg-gray-700 mx-[10px]" />
              </template>

              <!-- Regular item (when not grouped) -->
              <template v-if="!groupFieldName || !item.isGroup">
                <ListItem
                  :hovered="hovered"
                  :current-value="valueRef"
                  :key-field-name="keyFieldName"
                  :unique-key-of-object="uniqueKeyOfObject"
                  :item="item"
                  :disabled="isItemDisabled(item)"
                  @click.stop="() => !isItemDisabled(item) && setValueRef(item)"
                >
                  <slot name="item" :item="item" :current-value="valueRef" :label="highlight(item[searchFieldName])" :disabled="isItemDisabled(item)">
                    <div v-html="highlight(item[searchFieldName])" />
                  </slot>
                </ListItem>
                <div v-if="showSeparator && index < (groupFieldName ? groupedDropdownItems : dropdownItems).length - 1" class="h-px bg-gray-200 dark:bg-gray-700 mx-[10px]" />
              </template>
            </template>

            <!-- Regular item (when not grouped) -->
            <template v-if="!groupFieldName || !item.isGroup">
              <ListItem
                :hovered="hovered"
                :current-value="valueRef"
                :key-field-name="keyFieldName"
                :unique-key-of-object="uniqueKeyOfObject"
                :item="item"
                :disabled="isItemDisabled(item)"
                @click.stop="() => !isItemDisabled(item) && setValueRef(item)"
              >
                <slot name="item" :item="item" :current-value="valueRef" :label="highlight(item[searchFieldName])" :disabled="isItemDisabled(item)">
                  <div v-html="highlight(item[searchFieldName])" />
                </slot>
              </ListItem>
              <div v-if="showSeparator && index < dropdownItems.length - 1" class="h-px bg-gray-200 dark:bg-gray-700 mx-[10px]" />
            </template>
          </template>
        </div>
      </slot>
    </template>
    <ACTooltip
      v-if="$slots.input"
      :type="initialItemErrorCode === 403 ? 'item' : 'edit_field'"
      :allow-change="hasPerms && initialItemErrorCode !== 403"
    >
      <slot
        name="input"
        :search-value="searchValue"
        :initial-item-error-code="initialItemErrorCode"
        :show="show"
        :hide="hide"
        :on-search-input="onSearchInput"
        :loading="loading"
        :error="errorMessage"
        :clear="clearSearchAndResetValue"
      />
    </ACTooltip>
    <div v-else class="sub-modern-select-wrapper" :class="$attrs.class">
      <label
        v-if="labelLeft"
        :style="{
          paddingLeft:
            iconLeft || $slots.iconLeft ? `` : undefined,
        }"
        class="label"
        :for="id ? `${id}` : undefined"
      >
        {{ labelLeft }}
        <span v-if="required" class="required">*</span>
        <VTooltip v-if="toolTip" placement="top">
          <template #reference>
            <FontAwesomeIcon class="text-[16px]" :icon="['fa', 'circle-info']" />
          </template>
          <template #content>
            <div class="w-64">
              {{ toolTip }}
            </div>
          </template>
        </VTooltip>
      </label>
      <ACTooltip
        :type="initialItemErrorCode === 403 ? 'item' : 'edit_field'"
        :allow-change="hasPerms && initialItemErrorCode !== 403"
      >
        <div
          class="input overflow-hidden"
          :class="[
            {
              'is-loading': loading,
              'is-disabled': isDisabled,
              'has-validation-error': errorMessage !== null,
            },
            variant,
            errorMessage !== null && 'is-danger',
            size,
          ]"
          @click="show"
          @keydown="onKeyDown"
        >
          <div v-if="$slots.iconLeft || iconLeft" ref="iconLeftRef" class="icon left">
            <slot name="iconLeft" />
            <FontAwesomeIcon v-if="!$slots.iconLeft && iconLeft" :icon="iconLeft" />
          </div>
          <div
            v-if="loading"
            class="input-content"
            :class="[{
              'has-icon-left': iconLeft || $slots.iconLeft,
              'has-icon-right': iconRight,
            }]"
          >
            <VSkeleton :width="140" width-unit="px" :height="size === 'medium' ? 10 : 12" height-unit="px" pill />
          </div>
          <template v-else>
            <div
              v-if="selectedItem && $slots['selected-item']"
              class="input-content flex items-center"
              :class="[
                {
                  'has-icon-left': iconLeft || $slots.iconLeft,
                  'has-icon-right': iconRight,
                  'has-value': valueRef !== null,
                  'pl-0': $slots['selected-item'],
                },
              ]"
            >
              <slot name="selected-item" :item="selectedItem" />
            </div>
            <input
              v-else
              :for="id ? `${id}` : undefined"
              :value="searchValue"
              :placeholder="inputPlaceHolder"
              :disabled="isDisabled"
              type="text"
              class="bg-primary-600 input-content truncate"
              :class="[
                {
                  'has-icon-left': iconLeft || $slots.iconLeft,
                  'has-icon-right': iconRight,
                  'has-value': valueRef !== null,
                },
              ]"
              autocomplete="off"
              @input="(v) => onSearchInput(v.target)"
              @keydown.enter="show"
              @blur="(e) => $emit('blur', e)"
              @keydown="onKeyDown"
            >
          </template>
          <div class="icon right">
            <FontAwesomeIcon
              v-if="!hideClear && valueRef !== null && !loading && !isDisabled"
              class="clear-icon has-action"
              icon="times"
              @click.prevent.stop="clearSearchAndResetValue"
            />
            <FontAwesomeIcon v-if="iconRight" class="has-action" :icon="iconRight" />
          </div>
        </div>
      </ACTooltip>
    </div>
  </vdropdown>
</template>

<style lang="scss" scoped>
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.modern-select-wrapper {
  position: relative;

  .sub-modern-select-wrapper {
    display: flex;
    flex-direction: column;

    .label {
      display: inline-flex;
      font-size: 14px;
      color: var(--text);
      gap: 5px;

      .required {
        color: var(--secondary);
        font-weight: bold;
      }
    }

    .input {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 25px;
      justify-content: space-between;
      width: 100%;

      &.medium {
        height: 32px;
      }

      &.large {
        height: 40px;
      }

      border: 1px solid var(--input-border);

      &.is-danger.has-validation-error {
        border-color: var(--danger);
      }

      &.is-loading {
        cursor: progress;
      }

      &.is-disabled {
        opacity: 0.5;
      }

      &.is-primary {
        border: 1px solid var(--primary);

        .icon {
          color: var(--primary);
        }
      }

      &.is-secondary {
        border: 1px solid var(--secondary);

        .icon {
          color: var(--secondary);
        }
      }

      &.is-success {
        border: 1px solid var(--success);

        .icon {
          color: var(--success);
        }
      }

      &.is-info {
        border: 1px solid var(--info);

        .icon {
          color: var(--info);
        }
      }

      &.is-warning {
        border: 1px solid var(--warning);

        .icon {
          color: var(--warning);
        }
      }

      &.is-danger {
        border: 1px solid var(--danger) !important;

        .icon {
          color: var(--danger) !important;
        }
      }

      &.is-gray {
        border: 1px solid var(--input-border);
        background: red;

        .icon {
          color: var(--input-icon);
        }
      }

      // Extra styling when validation error is occurred
      &.is-danger.has-validation-error {
        input {
          color: var(--danger);

          &::placeholder {
            color: var(--danger);
          }
        }
      }

      &.is-white,
      &.is-gray {
        background: var(--input-background);

        input {
          background-color: transparent;
          color: var(--text);

          &::placeholder {
            color: var(--input-placeholder);
          }
        }

        .icon {
          color: var(--text);
        }
      }
      &.dark-gray {
        background: var(--input-background);

        input {
          background-color: transparent;
          color: var(--text);

          &::placeholder {
            color: var(--input-placeholder);
          }
        }

        .icon {
          color: var(--text);
        }
      }

      .icon {
        position: absolute;
        font-size: 14px;
        display: inline-flex;
        align-items: center;

        &.left {
          left: 0;
          margin-left: 15px;
        }

        &.right {
          margin-right: 15px;
          right: 0;

          .clear-icon {
            margin-right: 5px;
          }

          .has-action {
            cursor: pointer;
          }
        }
      }

      .input-content {
        flex: 1;
        border: none;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        margin-left: 20px;
        margin-right: 20px;
        min-width: 0;
        width: 100%;

        &.pl-0 {
          margin-left: 8px;
        }

        &:focus {
          outline: none;
        }

        &.has-icon-left {
          margin-left: 37px;

          &.pl-0 {
            margin-left: 25px;
          }
        }

        &.has-icon-right:not(.has-value) {
          margin-right: 25px;
        }

        &.has-icon-right.has-value {
          margin-right: 47px;
        }
      }

      .icon.right {
        display: flex;
        gap: 5px;
        align-items: center;
        position: absolute;
        right: 0;
        margin-right: 15px;
        background: var(--input-background);
      }
    }
  }
}

.items {
  max-height: 250px;
  overflow-y: auto;
  position: relative;
}

.group-header {
  padding: 0.625rem;
}

.list-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform-origin: top;

  &:hover {
    background-color: var(--surface-100, #f8f9fa);
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }
}

// .list-item:not(:first-child) {
//   // border-top: 1px solid var(--surface-200, #e9ecef);
// }

.group-header+.list-item {
  padding-left: 1.5rem;
}

.fake-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  user-select: none;
  position: relative;
  font-size: 14px;

  .icon {
    margin-right: 10px;
  }

  .retry {
    margin-left: auto;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;
  }

  &.is-loading:after {
    animation: loader 0.5s infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: absolute;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.5em);
    width: 1em;
  }
}
</style>
