import { useStorage } from "@vueuse/core";
import { ref, watch } from "vue";

export const localeState = ref<string>("nl");

const storageLocale = useStorage("language", "nl", localStorage);
localeState.value = storageLocale.value;

watch(storageLocale, (newLocale) => {
  localeState.value = newLocale;
});

watch(localeState, (newLocale) => {
  storageLocale.value = newLocale;
});

export function updateLocale(newLocale: string): void {
  localeState.value = newLocale;
}
