<script setup lang="ts">
import type { UserHydraItem } from "@verbleif/lib";
import { languageNames } from "@verbleif/lib";
import { useI18n } from "@verbleif/lib/src/translations/i18n";
import { api, useAuthStore } from "@verbleif/shared";
import NavbarSettingsitem from "../NavbarSettingsItem.vue";

const { user, patchUser } = useAuthStore();
const { outputPartialTransformer } = usePersonalTransformer();

const { t, availableLocales } = useI18n();
const TRANSLATION_KEY = "navbar.dropdown";

const languageOptions = computed(() => availableLocales.map((locale, index) => ({
  id: index,
  value: locale,
  label: languageNames[locale as keyof typeof languageNames] ?? locale,
} as { id: number, value: string, label: string })));

const locale = ref(user.value?.locale ?? "");

async function updateLocale(id: string) {
  if (!user.value) {
    return;
  }
  console.log("user.value[\"@id\"]", user.value["@id"]);
  const { data } = await api.patch<UserHydraItem>(user.value["@id"], outputPartialTransformer({
    locale: id,
  }));
  patchUser(data);
}
watch(() => locale.value, (newLocale) => {
  updateLocale(newLocale);
});
</script>

<template>
  <VModernSelect
    v-model="locale"
    :items="languageOptions"
    placement="left"
    :auto-size="false"
    key-field-name="value"
  >
    <template #input="{ show }">
      <div class="custom-input-wrapper" @click="show">
        <NavbarSettingsitem :icon="['fad', 'language']" :text="t(`${TRANSLATION_KEY}.change_language`)" />
      </div>
    </template>
    <template #item="{ item }">
      <div class="flex items-center gap-2">
        <img
          class="h-4"
          :src="`/flags/${item.value.toUpperCase()}.svg`"
          alt="flag"
        >
        {{ item.label }}
      </div>
    </template>
  </VModernSelect>
</template>
