import type { RouteRecordRaw } from "vue-router";
import { PermissionAttributesEnum } from "@verbleif/lib";

export enum ReportListType {
  TYPE_ALL = "aall",
  TYPE_OPEN = "op",
  TYPE_CREATED_BY_ME = "cbm",
  TYPE_GUEST = "gr",
  TYPE_TRANSFERRED = "tr",
  TYPE_REPORT_TOPIC = "rt",
  TYPE_PER_PROPERTY = "po",
}

export const shortcutReportListTypeMap = {
  [PermissionAttributesEnum.ContextReportshortcutAllreports]: ReportListType.TYPE_ALL,
  [PermissionAttributesEnum.ContextReportshortcutOpenreports]: ReportListType.TYPE_OPEN,
  [PermissionAttributesEnum.ContextReportshortcutGuestreports]: ReportListType.TYPE_GUEST,
  [PermissionAttributesEnum.ContextReportshortcutPerproperty]: ReportListType.TYPE_PER_PROPERTY,
  [PermissionAttributesEnum.ContextReportshortcutTransferredtotask]: ReportListType.TYPE_TRANSFERRED,
  [PermissionAttributesEnum.ContextReportshortcutTopics]: ReportListType.TYPE_REPORT_TOPIC,
};

export const FALLBACK_TYPE = ReportListType.TYPE_CREATED_BY_ME;

export const routes = <RouteRecordRaw[]>[
  {
    path: "/r",
    name: "reports",
    component: () => import("@web/features/Reports/ReportsContainer.vue"),
    redirect: (props) => {
      return {
        name: "report-list",
        params: { type: FALLBACK_TYPE, ...props?.params },
      };
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: {
          name: "report-list",
          params: { type: FALLBACK_TYPE },
        },
      },
      {
        path: "li/:type/:id?/:reportId?",
        name: "report-list",
        component: () => import("./Overview/ReportsView.vue"),
        beforeEnter: (to, _, next) => {
          const type = to.params.type as ReportListType;
          const { id, reportId } = to.params;

          if (!Object.values(ReportListType).includes(type)) {
            return next({
              name: "report-list",
              params: { type: FALLBACK_TYPE, reportId },
            });
          }

          const focusedShortcut = isFocusedOnReportShortcut.value;
          if (focusedShortcut) {
            const typeFromShortcut = shortcutReportListTypeMap[focusedShortcut as keyof typeof shortcutReportListTypeMap];

            if (type !== typeFromShortcut) {
              return next({
                name: "report-list",
                params: {
                  type: typeFromShortcut ?? FALLBACK_TYPE,
                  id,
                  reportId,
                },
              });
            }
          }

          if (id && !reportId && typeof id === "string" && id.startsWith("r")) {
            return next({
              name: "report-list",
              params: {
                type,
                reportId: id,
                id: undefined,
              },
            });
          }

          return next();
        },
      },
    ],
  },
];

export default routes;
