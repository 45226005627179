import type { App } from "vue";

export const CONFIRM_DIALOG_KEY = Symbol("confirmDialog");

export interface ConfirmDialogInstance {
  openDialog: (options: {
    title: string
    message: string
    confirmText?: string
    confirmIcon?: string | string[]
    cancelText?: string
    icon?: string
    type?: string
  }) => Promise<boolean>
}

export function provideDefaultConfirmDialog(app: App, dialog: ConfirmDialogInstance) {
  app.provide(CONFIRM_DIALOG_KEY, dialog);
}
export function useDefaultConfirmDialog() {
  return {
    provideDefaultConfirmDialog,
  };
}
