import { cva } from "class-variance-authority";

export const tabListContainerCva = cva("relative flex bg-transparent", {
  variants: {
    orientation: {
      horizontal: "flex-row items-center w-full border-b-1 border-gray-100 dark:border-dark-700",
      vertical: "flex-col",
    },
    listPosition: {
      left: null,
      right: null,
    },
    tile: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      orientation: "horizontal",
      listPosition: "left",
      class: "border-r-1 border-gray-100 dark:border-dark-700",
    },
    {
      orientation: "horizontal",
      listPosition: "right",
      class: "border-l-1 border-gray-100 dark:border-dark-700",
    },
    {
      orientation: "vertical",
      tile: false,
      class: "min-w-[200px]",
    },
  ],
});

export const tabListPreviousButtonCva = cva("absolute flex items-center justify-center border-none bg-transparent cursor-pointer text-primary-600 dark:text-primary-400 z-10 p-2 hover:bg-gray-50 dark:hover:bg-dark-800 shadow-[4px_0_6px_-4px_rgba(0,0,0,0.1)] dark:shadow-[4px_0_6px_-4px_rgba(0,0,0,0.3)] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white dark:disabled:hover:bg-dark-850", {
  variants: {
    orientation: {
      horizontal: "top-0 h-full left-0",
      vertical: "w-full h-8 top-0",
    },
    listPosition: {
      left: null,
      right: null,
    },
  },
  compoundVariants: [
    {
      orientation: "vertical",
      listPosition: "left",
      class: "left-0",
    },
    {
      orientation: "vertical",
      listPosition: "right",
      class: "right-0",
    },
  ],
});

export const tabListNextButtonCva = cva("absolute flex items-center justify-center border-none bg-transparent cursor-pointer text-primary-600 dark:text-primary-400 z-10 p-2 hover:bg-gray-50 dark:hover:bg-dark-800 shadow-[-4px_0_6px_-4px_rgba(0,0,0,0.1)] dark:shadow-[-4px_0_6px_-4px_rgba(0,0,0,0.3)] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white dark:disabled:hover:bg-dark-850", {
  variants: {
    orientation: {
      horizontal: "top-0 h-full right-0",
      vertical: "w-full h-8 top-0",
    },
    listPosition: {
      left: null,
      right: null,
    },
  },
  compoundVariants: [
    {
      orientation: "vertical",
      listPosition: "left",
      class: "left-0",
    },
    {
      orientation: "vertical",
      listPosition: "right",
      class: "right-0",
    },
  ],
});

export const tabListInkbarBackgroundCva = cva("absolute bg-gray-100 dark:bg-dark-700", {
  variants: {
    orientation: {
      horizontal: "bottom-0 left-0 right-0 h-[2px]",
      vertical: "top-0 bottom-0 w-[2px]",
    },
    listPosition: {
      left: null,
      right: null,
    },
  },
  compoundVariants: [
    {
      orientation: "vertical",
      listPosition: "left",
      class: "right-0",
    },
    {
      orientation: "vertical",
      listPosition: "right",
      class: "left-0",
    },
  ],
});

export const tabListActiveInkbarCva = cva("absolute transition-all duration-200 z-10", {
  variants: {
    error: {
      true: "!bg-red-600 !dark:bg-red-400",
      false: "bg-primary-600 dark:bg-primary-400",
    },
    orientation: {
      horizontal: null,
      vertical: null,
    },
    tile: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      orientation: "vertical",
      tile: true,
      class: "rounded-lg",
    },
  ],
});

export const tabListContentWrapperCva = cva("flex flex-1 overflow-hidden relative", {
  variants: {
    orientation: {
      horizontal: "flex-row",
      vertical: "flex-col w-full",
    },
    buttons: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      orientation: "horizontal",
      buttons: true,
      class: "mx-8",
    },
    {
      orientation: "vertical",
      buttons: true,
      class: "my-8",
    },
  ],
});

export const tabListItemsContainerCva = cva("flex flex-1 relative", {
  variants: {
    orientation: {
      horizontal: "flex-row",
      vertical: "flex-col",
    },
    tile: {
      true: "gap-1.5",
      false: null,
    },
    divider: {
      true: null,
      false: null,
    },
    center: {
      true: "justify-center",
      false: null,
    },
    listPosition: {
      left: null,
      right: null,
    },
    scrollable: {
      true: "overflow-auto scrollbar-hide",
      false: "overflow-hidden scrollbar-hide",
    },
  },
  compoundVariants: [
    {
      tile: false,
      divider: false,
      class: "gap-1",
    },
    {
      tile: true,
      listPosition: "left",
      orientation: "vertical",
      class: "pr-0",
    },
    {
      tile: true,
      listPosition: "right",
      orientation: "vertical",
      class: "pl-0",
    },
    {
      scrollable: true,
      orientation: "vertical",
      class: "overflow-x-hidden",
    },
  ],
});

export const tabListItemCva = cva("flex relative items-center", {
  variants: {
    orientation: {
      horizontal: "flex-row",
      vertical: "flex-col w-full",
    },
    tile: {
      true: null,
      false: null,
    },
  },
  compoundVariants: [
    {
      tile: true,
      orientation: "vertical",
      class: "pt-2",
    },
  ],
});

export const tabListDividerCva = cva("flex-shrink-0", {
  variants: {
    orientation: {
      horizontal: "w-0.5 h-[calc(100%-1rem)] mx-2 bg-gray-100 dark:bg-dark-600 my-2",
      vertical: "w-[calc(100%-2rem)] h-0.5 bg-gray-100 dark:bg-dark-600 my-2",
    },
    listPosition: {
      left: null,
      right: null,
    },
  },
  compoundVariants: [
    {
      orientation: "vertical",
      listPosition: "left",
      class: "mr-2",
    },
    {
      orientation: "vertical",
      listPosition: "right",
      class: "ml-2",
    },
  ],
});
