<!-- eslint-disable vue/return-in-computed-property -->
<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import { useAccordionPanelState } from "./useAccordionPanelState";
import { useAccordionState } from "./useAccordionState";

const emit = defineEmits<{
  click: [event: MouseEvent]
}>();

const { modelValue, size, variant } = useAccordionState();
const { value, disabled } = useAccordionPanelState();

const isActive = computed(() => {
  const currentValue = modelValue.value;
  if (Array.isArray(currentValue)) {
    return currentValue.includes(value);
  }
  return currentValue === value;
});

const sizeClasses = computed(() => {
  switch (size.value) {
    case "xs":
      return "p-2 text-xs";
    case "sm":
      return "p-3 text-sm";
    case "md":
      return "p-4 text-base";
    case "lg":
      return "p-6 text-xl";
    default:
      return "p-4 text-base";
  }
});

const variantClasses = computed(() => {
  const currentVariant = variant.value;
  switch (currentVariant) {
    case "default":
      return "text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800";
    case "is-primary":
      return "text-primary-600 dark:text-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/20";
    case "is-secondary":
      return "text-secondary-600 dark:text-secondary-400 hover:bg-secondary-50 dark:hover:bg-secondary-900/20";
    case "is-info":
      return "text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/20";
    case "is-success":
      return "text-success-600 dark:text-success-400 hover:bg-success-50 dark:hover:bg-success-900/20";
    case "is-warning":
      return "text-warning-600 dark:text-warning-400 hover:bg-warning-50 dark:hover:bg-warning-900/20";
    case "is-danger":
      return "text-danger-600 dark:text-danger-400 hover:bg-danger-50 dark:hover:bg-danger-900/20";
    case "is-gray":
      return "text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900/20";
    default:
      return "text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800";
  }
});
</script>

<template>
  <button
    class="w-full flex items-center justify-between transition-colors"
    :class="[sizeClasses, variantClasses, { 'cursor-not-allowed opacity-50': disabled }]"
    :disabled="disabled"
    @click="emit('click', $event)"
  >
    <slot />
    <FontAwesomeIcon
      :icon="['fas', 'chevron-down']"
      class="transition-transform"
      :class="{ 'rotate-180': isActive }"
    />
  </button>
</template>
