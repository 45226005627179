<script setup lang="ts">
import { localeState } from "@verbleif/lib/src/translations/localeState";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { toast } from "vue-sonner";
import { api } from "../../composables";
import { useAuthStore } from "../../composables/AuthStore/useAuthStore";

interface LocaleItem {
  "@id": string
  "@type": string
  "name": string
  "locale": string
}

interface LocalesResponse {
  "@context": string
  "@id": string
  "@type": string
  "totalItems": number
  "member": LocaleItem[]
}

const { t, availableLocales } = useI18n();
const authStore = useAuthStore();
const locales = ref<LocaleItem[]>([]);
const loading = ref(false);
const flagsLoading = ref(false);
const flagStatuses = ref<Record<string, boolean>>({});
const translationStatuses = ref<Record<string, boolean>>({});
const selectedLocale = ref(localeState.value || "");

/**
 * List of locales with their flags and translation status
 */
const localeList = computed(() => {
  return locales.value.map(locale => ({
    code: locale.locale,
    name: locale.name,
    flag: `/flags/${locale.locale.toUpperCase()}.svg`,
    flagExists: flagStatuses.value[locale.locale.toUpperCase()] ?? true,
    translationExists: translationStatuses.value[locale.locale] ?? false,
  }));
});

/**
 * Fetch available locales from the API
 */
async function fetchLocales() {
  loading.value = true;
  try {
    const { data } = await api.get<LocalesResponse>("/api/locales");
    locales.value = data.member;
    checkFlagExistence();
    checkTranslationExistence();
  } catch (error) {
    console.error("Failed to fetch locales", error);
    toast.error(t("base.error_occurred"));
  } finally {
    loading.value = false;
  }
}

async function checkFlagExistence() {
  flagsLoading.value = true;
  const statuses: Record<string, boolean> = {};

  for (const locale of locales.value) {
    const code = locale.locale.toUpperCase();
    try {
      const img = new Image();
      const promise = new Promise<boolean>((resolve) => {
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
      img.src = `/flags/${code}.svg`;
      statuses[code] = await promise;
    } catch {
      statuses[code] = false;
    }
  }

  flagStatuses.value = statuses;
  flagsLoading.value = false;
}

/**
 * Check if translations exist for all locales in i18n
 */
function checkTranslationExistence() {
  const statuses: Record<string, boolean> = {};

  for (const locale of locales.value) {
    statuses[locale.locale] = availableLocales.includes(locale.locale);
  }

  translationStatuses.value = statuses;
}

/**
 * Change the active locale for testing purposes
 */
async function changeLocale(locale: string) {
  if (!authStore.user.value) {
    // Just update locale state for testing without updating user
    localeState.value = locale;
    selectedLocale.value = locale;
    toast.success(t("base.success"));
    return;
  }

  try {
    // Update the user's locale if logged in
    await api.patch(authStore.user.value["@id"], {
      locale,
    });
    localeState.value = locale;
    selectedLocale.value = locale;
    toast.success(t("base.success"));
  } catch (error) {
    console.error("Failed to update locale", error);
    toast.error(t("base.error_occurred"));
  }
}

onMounted(() => {
  fetchLocales();
});
</script>

<template>
  <div class="flex flex-col gap-6">
    <!-- Current Locale -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
        {{ t("settings.locales.current") }}
      </div>

      <div class="flex items-center gap-4 p-3 border border-gray-200 dark:border-gray-700 rounded">
        <img
          :src="`/flags/${selectedLocale.toUpperCase()}.svg`"
          :alt="selectedLocale"
          class="h-6 w-8 object-cover rounded"
          onerror="this.src='/flags/UNKNOWN.svg'"
        >
        <div class="flex flex-col">
          <span class="text-sm font-medium text-gray-700 dark:text-gray-300">
            {{ locales.find((loc: LocaleItem) => loc.locale === selectedLocale)?.name || selectedLocale }}
          </span>
          <span class="text-xs text-gray-500 dark:text-gray-400">
            {{ selectedLocale }}
          </span>
        </div>
        <div class="flex-1" />
        <div class="flex items-center">
          <span
            class="px-2 py-0.5 text-xs font-medium rounded"
            :class="availableLocales.includes(selectedLocale)
              ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300'
              : 'bg-warning-100 text-warning-700 dark:bg-warning-900 dark:text-warning-300'"
          >
            {{ availableLocales.includes(selectedLocale)
              ? t('settings.locales.translation_available')
              : t('settings.locales.translation_missing') }}
          </span>
        </div>
      </div>
    </div>

    <!-- Available Locales -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="flex justify-between items-center mb-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.locales.available") }} ({{ localeList.length }})
        </div>
      </div>

      <div v-if="loading" class="flex justify-center my-4">
        <VSpinner size="medium" />
      </div>

      <div v-else-if="localeList.length === 0" class="text-center text-gray-500 dark:text-gray-400 my-4">
        {{ t("base.no_results") }}
      </div>

      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        <div
          v-for="locale in localeList"
          :key="locale.code"
          class="flex items-center gap-2 p-3 border border-gray-200 dark:border-gray-700 rounded cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800"
          @click="changeLocale(locale.code)"
        >
          <div class="relative">
            <img
              :src="locale.flag"
              :alt="locale.code"
              class="h-5 w-7 object-cover rounded"
              onerror="this.src='/flags/UNKNOWN.svg'"
            >
            <FontAwesomeIcon
              v-if="flagStatuses[locale.code.toUpperCase()] === false"
              :icon="['fas', 'exclamation-triangle']"
              class="text-warning-500 absolute -top-2 -right-2 bg-white dark:bg-gray-800 rounded-full w-3 h-3"
            />
          </div>

          <div class="flex flex-col flex-1">
            <div class="flex items-center gap-1">
              <span class="text-sm font-medium text-gray-700 dark:text-gray-300">{{ $t(`locales.${locale.code.toUpperCase()}`) }}</span>

              <span
                v-if="!locale.translationExists"
                class="ml-1 px-1.5 py-0.5 text-[10px] font-medium rounded bg-warning-100 text-warning-700 dark:bg-warning-900 dark:text-warning-300"
              >
                {{ t('settings.locales.no_i18n') }}
              </span>
            </div>
            <span class="text-xs text-gray-500 dark:text-gray-400">{{ locale.code }}</span>
          </div>

          <FontAwesomeIcon
            v-if="locale.code === selectedLocale"
            :icon="['fas', 'check']"
            class="text-success-500 w-4 h-4"
          />
        </div>
      </div>
    </div>

    <!-- Translation Status Summary -->
    <div class="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
      <div class="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
        {{ t("settings.locales.i18n_status") }}
      </div>

      <div class="flex items-center gap-3 p-3 border border-gray-200 dark:border-gray-700 rounded mb-3">
        <FontAwesomeIcon
          :icon="['fas', 'language']"
          class="text-primary-500 w-4 h-4"
        />
        <span class="text-sm text-gray-600 dark:text-gray-400">
          {{ Object.values(translationStatuses).filter(status => status).length }} / {{ Object.keys(translationStatuses).length }} {{ t("settings.locales.translations_available") }}
        </span>
      </div>

      <div class="flex flex-col gap-3">
        <div class="text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ t("settings.locales.i18n_available") }}:
        </div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
          <div
            v-for="locale in availableLocales"
            :key="locale"
            class="text-xs text-gray-600 dark:text-gray-400 p-2 border border-gray-200 dark:border-gray-700 rounded bg-gray-50 dark:bg-gray-800 flex items-center gap-2"
          >
            <FontAwesomeIcon
              :icon="['fas', 'check-circle']"
              class="text-success-500 w-3 h-3"
            />
            {{ locale }}
          </div>
        </div>
      </div>

      <div v-if="Object.values(translationStatuses).some(status => !status)" class="flex flex-col gap-3 mt-4">
        <div class="text-sm font-medium text-warning-500">
          {{ t("settings.locales.missing_translations") }}:
        </div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2">
          <div
            v-for="locale in locales.filter(loc => !availableLocales.includes(loc.locale))"
            :key="locale.locale"
            class="text-xs text-gray-600 dark:text-gray-400 p-2 border border-gray-200 dark:border-gray-700 rounded bg-gray-50 dark:bg-gray-800 flex items-center gap-2"
          >
            <FontAwesomeIcon
              :icon="['fas', 'exclamation-triangle']"
              class="text-warning-500 w-3 h-3"
            />
            {{ locale.locale }} ({{ locale.name }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
