import type { TaskHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { TaskVoter } from "./TaskVoter";
import type { TaskEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const taskFieldMapping: Record<
  "blocking"
  | "deadline_at"
  | "description"
  | "inside"
  | "priority"
  | "property"
  | "quantity"
  | "sort"
  | "status"
  | "status.to_checked"
  | "status.to_finished_or_checked_future_deadline_at",
  {
    create: PermissionAttributesEnum | null
    update: PermissionAttributesEnum
  }
> = {
  "blocking": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldBlocking,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldBlocking,
  },
  "deadline_at": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldDeadlineat,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldDeadlineat,
  },
  "description": {
    create: null,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldDescription,
  },
  "inside": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldInside,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldInside,
  },
  "priority": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldPriority,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldPriority,
  },
  "property": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldProperty,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldProperty,
  },
  "quantity": {
    create: PermissionAttributesEnum.ContextTaskCreateFieldQuantity,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldQuantity,
  },
  "sort": {
    create: null,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldSort,
  },
  "status": {
    create: null,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldStatus,
  },
  "status.to_checked": {
    create: null,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldStatusTochecked,
  },
  "status.to_finished_or_checked_future_deadline_at": {
    create: null,
    update: PermissionAttributesEnum.ContextTaskUpdateFieldStatusTofinishedorcheckedfuturedeadlineat,
  },
};

export type TaskFieldAttribute = keyof typeof taskFieldMapping;

export class TaskFieldVoter extends ContextVoter {
  public taskVoter: TaskVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
  }

  public hasField = (entity: TaskEntityVote | null, operation: "create" | "update", field: TaskFieldAttribute): boolean => {
    if (taskFieldMapping[field] === undefined || taskFieldMapping[field][operation] === undefined) {
      return false;
    }

    const attribute = taskFieldMapping[field][operation];

    if (attribute === null) {
      return true;
    }

    if (entity) {
      if (this.permissionService.getGlobalPermissionForUser({
        attribute: operation === "create"
          ? PermissionAttributesEnum.GlobalTaskCreateFieldAny
          : PermissionAttributesEnum.GlobalTaskUpdateFieldAny,
      })) {
        return true;
      }
      return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity as TaskHydraItem, attribute);
    }

    return false;
  };
}
