<script setup lang="ts">
import { toRefs } from "vue";
import { useProvideTabsState } from "./useTabsState";

interface Props {
  orientation?: "horizontal" | "vertical"
  scrollable?: boolean
  lazy?: boolean
  tile?: boolean
  inkbar?: boolean
  listPosition?: "left" | "right"
  centerItemsInList?: boolean
  dividerBetweenTabs?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  orientation: "horizontal",
  scrollable: false,
  lazy: false,
  tile: false,
  inkbar: true,
  listPosition: "left",
  centerItemsInList: false,
  dividerBetweenTabs: false,
});

const modelValue = defineModel<number>({
  required: true,
  default: 0,
});

const {
  orientation,
  scrollable,
  lazy,
  tile,
  inkbar,
  listPosition,
  centerItemsInList,
  dividerBetweenTabs,
} = toRefs(props);

useProvideTabsState({
  modelValue,
  orientation,
  scrollable,
  lazy,
  tile,
  inkbar,
  listPosition,
  centerItemsInList,
  dividerBetweenTabs,
});
</script>

<template>
  <div
    class="flex w-full h-full min-h-0"
    :class="[
      orientation === 'horizontal'
        ? 'flex-col'
        : `flex-row${listPosition === 'right' ? '-reverse' : ''}`,
    ]"
  >
    <slot />
  </div>
</template>
