import type { Ref } from "vue";
import { createInjectionState } from "@vueuse/core";

const [useProvideTabItemState, useTabItemStateRaw] = createInjectionState(({
  error,
  disabled,
  index,
}: {
  error: Ref<boolean>
  disabled: Ref<boolean>
  index: Ref<number>
}) => {
  return {
    error,
    disabled,
    index,
  };
});

export { useProvideTabItemState };

export function useTabItemState() {
  const state = useTabItemStateRaw();
  if (state == null) {
    throw new Error("Please call `useProvideTabItemState` on the appropriate parent component");
  }
  return state;
}
