import { cva } from "class-variance-authority";

export const rootCva = cva("flex", {
  variants: {
    labelPosition: {
      left: "flex-row",
      right: "flex-row-reverse",
      top: "flex-col",
    },
  },
  defaultVariants: {
    labelPosition: "left",
  },
});

export const switchCva = cva("rounded-full transition-colors duration-200", {
  variants: {
    size: {
      small: ["w-[33px] h-4"],
      medium: ["w-[39px] h-[18px]"],
      large: ["w-[52px] h-6"],
    },
    disabled: {
      true: ["opacity-50", "cursor-not-allowed"],
      false: null,
    },
    errorMessage: {
      true: ["bg-red-500"],
      false: ["bg-gray-300 dark:bg-dark-600"],
    },
    active: {
      true: ["bg-primary-500"],
      false: null,
    },
    hasPerms: {
      false: ["opacity-50", "cursor-not-allowed"],
      true: null,
    },
  },
  defaultVariants: {
    size: "medium",
    disabled: null,
    errorMessage: null,
    hasPerms: true,
  },
});

export const thumbCva = cva("absolute top-1/2 -translate-y-1/2 rounded-full transition-all duration-200", {
  variants: {
    size: {
      small: ["w-2.5 h-2.5 left-1"],
      medium: ["w-3.5 h-3.5 left-[2px]"],
      large: ["w-5 h-5 left-[2px]"],
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

export const labelCva = cva("", {
  variants: {
    size: {
      small: ["text-xs"],
      medium: ["text-sm"],
      large: ["text-sm"],
    },
  },
  defaultVariants: {
    size: "medium",
  },

});

export const requiredCva = cva("text-red-500", {
  variants: {
    required: {
      true: ["text-red-500 font-bold"],
      false: ["hidden"],
    },
  },
});
export const thumbCheckedCva = cva("", {
  variants: {
    size: {
      small: ["left-[calc(100%-14px)]"],
      medium: ["left-[calc(100%-16px)]"],
      large: ["left-[calc(100%-22px)]"],
    },
  },
  defaultVariants: {
    size: "medium",
  },
});
