<script setup lang="ts">
import { useConfigStore } from "@verbleif/shared";
import { useBreakpoints } from "@vueuse/core";
import OccupationView from "@web/features/Tasks/Overview/ReservationState/Occupation/OccupationView.vue";
import PropertyView from "@web/features/Tasks/Overview/ReservationState/Property/PropertyView.vue";
import ReservationStateDebug from "@web/features/Tasks/Overview/ReservationState/ReservationStateDebug.vue";

const { dialog } = defineProps<{
  dialog?: boolean
}>();

const breakpoints = useBreakpoints({
  lg: 1024,
});

const { debugMode } = useConfigStore();

const activeIndex = ref(0);
</script>

<template>
  <VTabs
    v-model:model-value="activeIndex"
    :orientation="breakpoints.lg.value ? 'vertical' : 'horizontal'"
    tile
    class="w-full"
  >
    <VTabList class="flex-wrap">
      <VTabItem
        :title="$t('tasks.reservation_state.occupation.title')"
        :index="0"
        :icon="['far', 'bed']"
      />
      <VTabItem
        :title="$t('tasks.reservation_state.property.title')"
        :index="1"
        :icon="['far', 'info-circle']"
      />
      <VTabItem
        v-if="debugMode"
        title="Debug"
        :index="2"
        :icon="['far', 'bug']"
      />
    </VTabList>
    <VTabPanels>
      <VTabPanel :index="0">
        <div class="my-2 lg:my-0">
          <OccupationView :dialog="dialog" />
        </div>
      </VTabPanel>
      <VTabPanel :index="1">
        <div class="my-2 lg:my-0">
          <PropertyView :dialog="dialog" />
        </div>
      </VTabPanel>
      <VTabPanel v-if="debugMode" :index="2">
        <div class="my-2 lg:my-0">
          <ReservationStateDebug />
        </div>
      </VTabPanel>
    </VTabPanels>
  </VTabs>
</template>

<style scoped>

</style>
