<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ACTooltip from "@verbleif/lib/src/components/ACTooltip/ACTooltip.vue";
import { computed } from "vue";

interface Props {
  min?: number
  max?: number
  step?: number
  size?: "medium" | "large"
  disabled?: boolean
  required?: boolean
  invalid?: boolean
  errorMessage?: string
  placeholder?: string
  hasPerms?: boolean
  iconLeft?: string | ["fa" | "fas" | "far" | "fad" | "fal", string]
  labelLeft?: string
  maxDecimals?: number | null
  showClear?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  min: 0,
  max: undefined,
  step: 1,
  size: "medium",
  disabled: false,
  required: false,
  maxDecimals: null,
  invalid: false,
  errorMessage: "",
  placeholder: "",
  hasPerms: true,
  iconLeft: undefined,
  labelLeft: undefined,
  showClear: false,
});

const model = defineModel<number | null>({ default: null });

const isDisabled = computed(() => {
  return props.disabled || !props.hasPerms;
});

const showClearButton = computed(() => {
  return props.showClear && !isDisabled.value && model.value !== null;
});

function decreaseValue() {
  const currentValue = model.value ?? 0;
  const newValue = props.maxDecimals !== null
    ? Number((currentValue - props.step).toFixed(props.maxDecimals))
    : currentValue - props.step;
  if (newValue >= props.min) {
    model.value = newValue;
  }
}

function increaseValue() {
  const currentValue = model.value ?? 0;
  const newValue = props.maxDecimals !== null
    ? Number((currentValue + props.step).toFixed(props.maxDecimals))
    : currentValue + props.step;
  if (!props.max || newValue <= props.max) {
    model.value = newValue;
  }
}

function validateInput(event: Event) {
  if (model.value === null) {
    return;
  }

  const value = (event.target as HTMLInputElement).value;
  const numberValue = value === "" ? props.min : Number(value);

  if (props.max && numberValue > props.max) {
    model.value = props.max;
  } else if (numberValue < props.min) {
    model.value = props.min;
  } else {
    model.value = numberValue;
  }
}

function onInput(event: Event) {
  if (props.maxDecimals === null) {
    return;
  }

  const input = event.target as HTMLInputElement;
  let value: string = input.value;
  if (!value) {
    model.value = Number(value);
    return;
  }
  const commaIndex = value.indexOf(".");
  if (commaIndex === -1) {
    return;
  }
  value = value.slice(0, commaIndex + props.maxDecimals + 1);
  model.value = Number(value);
}

function clearValue() {
  model.value = null;
}
</script>

<template>
  <div class="task-amount-input">
    <label
      v-if="labelLeft"
      class="block text-sm mb-1"
      for="task-amount-input"
    >
      {{ labelLeft }}
      <span v-if="required" class="text-danger-600 font-bold">*</span>
    </label>
    <ACTooltip
      type="edit_field"
      :allow-change="hasPerms"
    >
      <div
        class="relative flex items-center w-full"
        :class="[
          size === 'medium' ? 'h-8' : 'h-10',
          {
            'opacity-50 cursor-not-allowed': isDisabled,
            'cursor-default': !isDisabled,
          },
        ]"
      >
        <div
          v-if="iconLeft"
          class="absolute left-0 flex items-center justify-center ml-[15px] h-full text-gray-800 dark:text-gray-400"
        >
          <FontAwesomeIcon :icon="iconLeft" class="text-sm" />
        </div>

        <input
          id="task-amount-input"
          v-model="model"
          type="number"
          class="w-full h-full border rounded-full outline-none text-sm"
          :class="[
            iconLeft ? 'pl-[37px]' : 'pl-5',
            showClearButton ? 'pr-[5.5rem]' : 'pr-[4.5rem]',
            isDisabled ? 'bg-gray-100 dark:bg-dark-700 cursor-not-allowed' : 'bg-white dark:bg-dark-800',
            invalid || errorMessage ? 'border-danger-600 dark:border-danger-700' : 'border-gray-300 dark:border-dark-600',
          ]"
          :min="min"
          :max="max"
          :step="step"
          :disabled="isDisabled"
          :placeholder="placeholder"
          @blur="validateInput"
          @input="onInput"
        >

        <!-- Clear button -->
        <div
          v-if="showClearButton"
          class="absolute right-20 flex items-center justify-center h-full text-gray-700 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-300 cursor-pointer transition-colors"
          @click.stop="clearValue"
        >
          <FontAwesomeIcon :icon="['far', 'times']" class="text-sm" />
        </div>

        <div class="absolute right-0 flex h-full">
          <!-- Increase Button -->
          <button
            type="button"
            class="flex items-center justify-center w-9 h-full text-white transition-colors border-r border-white/20"
            :class="[
              isDisabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-primary-600 hover:bg-primary-700 dark:bg-primary-700 dark:hover:bg-primary-800',
            ]"
            :disabled="isDisabled"
            @click="increaseValue"
          >
            <FontAwesomeIcon :icon="['far', 'plus-circle']" class="text-m" />
          </button>

          <!-- Decrease Button -->
          <button
            type="button"
            class="flex items-center justify-center w-9 h-full text-white transition-colors rounded-r-full"
            :class="[
              isDisabled ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' : 'bg-danger-600 hover:bg-danger-700 dark:bg-danger-700 dark:hover:bg-danger-800',
            ]"
            :disabled="isDisabled"
            @click="decreaseValue"
          >
            <FontAwesomeIcon :icon="['far', 'minus-circle']" class="text-m" />
          </button>
        </div>
      </div>
    </ACTooltip>

    <div
      v-if="errorMessage"
      class="mt-1 text-sm text-danger-600 dark:text-danger-500"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task-amount-input {
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
