// localeParser.ts
export interface ParsedLocale {
  language: string
  script: string | null
  region: string
}

// Default region mapping based on the provided JSON of locale objects.
export const defaultRegionMapping: Record<string, string> = {
  af: "ZA",
  ak: "GH",
  sq: "AL",
  am: "ET",
  ar: "EG",
  hy: "AM",
  as: "IN",
  az: "AZ",
  bm: "ML",
  bn: "BD",
  eu: "ES",
  be: "BY",
  bs: "BA",
  br: "FR",
  bg: "BG",
  my: "MM",
  ca: "ES",
  ce: "RU",
  zh: "CN",
  cv: "RU",
  kw: "GB",
  hr: "HR",
  cs: "CZ",
  da: "DK",
  nl: "NL",
  dz: "BT",
  en: "US",
  eo: "001",
  et: "EE",
  ee: "GH",
  fo: "FO",
  fi: "FI",
  fr: "FR",
  ff: "SN",
  gl: "ES",
  lg: "UG",
  ka: "GE",
  de: "DE",
  el: "GR",
  gu: "IN",
  ha: "NG",
  he: "IL",
  hi: "IN",
  hu: "HU",
  is: "IS",
  ig: "NG",
  id: "ID",
  ia: "001",
  ie: "001",
  ga: "IE",
  it: "IT",
  ja: "JP",
  jv: "ID",
  kl: "GL",
  kn: "IN",
  ks: "IN",
  kk: "KZ",
  km: "KH",
  ki: "KE",
  rw: "RW",
  ko: "KR",
  ku: "TR",
  ky: "KG",
  lo: "LA",
  lv: "LV",
  ln: "CG",
  lt: "LT",
  lu: "CD",
  lb: "LU",
  mk: "MK",
  mg: "MG",
  ms: "MY",
  ml: "IN",
  mt: "MT",
  gv: "IM",
  mi: "NZ",
  mr: "IN",
  mn: "MN",
  ne: "NP",
  nd: "ZW",
  se: "NO",
  no: "NO",
  nb: "NO",
  nn: "NO",
  oc: "FR",
  or: "IN",
  om: "ET",
  os: "RU",
  ps: "AF",
  fa: "IR",
  pl: "PL",
  pt: "PT",
  pa: "IN",
  qu: "PE",
  ro: "RO",
  rm: "CH",
  rn: "BI",
  ru: "RU",
  sg: "CF",
  sa: "IN",
  sc: "IT",
  gd: "GB",
  sr: "RS",
  sn: "ZW",
  ii: "CN",
  sd: "PK",
  si: "LK",
  sk: "SK",
  sl: "SI",
  so: "SO",
  st: "ZA",
  es: "ES",
  su: "ID",
  sw: "KE",
  sv: "SE",
  tl: "PH",
  tg: "TJ",
  ta: "IN",
  tt: "RU",
  te: "IN",
  th: "TH",
  bo: "CN",
  ti: "ER",
  to: "TO",
  tn: "ZA",
  tr: "TR",
  tk: "TM",
  uk: "UA",
  ur: "PK",
  ug: "CN",
  uz: "UZ",
  vi: "VN",
  cy: "GB",
  fy: "NL",
  wo: "SN",
  xh: "ZA",
  yi: "001",
  yo: "NG",
  za: "CN",
  zu: "ZA",
};

export function parseLocale(raw: string): ParsedLocale {
  // console.debug("Parsing locale string:", raw);
  const parts = raw.split("_");
  // console.debug("Locale parts:", parts);
  const language = parts[0] || "UNKNOWN";
  let region = "UNKNOWN";
  let script: string | null = null;

  if (parts.length === 1) {
    region = defaultRegionMapping[language.toLowerCase()] || language.toUpperCase();
    // console.debug(`One part locale detected. language=${language}, region=${region}`);
  } else if (parts.length === 2) {
    region = parts[1] || language.toUpperCase();
    // console.debug(`Two part locale detected. language=${language}, region=${region}`);
  } else if (parts.length === 3) {
    script = parts[1];
    region = parts[2] || language.toUpperCase();
    // console.debug(`Three part locale detected. language=${language}, script=${script}, region=${region}`);
  }
  return { language, script, region };
}
