<script setup lang="ts">
import type { NotificationHydraItem } from "@verbleif/lib";
import { iriToId, ScrollDirection, useLazyLoadScroll } from "@verbleif/lib";
import { truncateText } from "@verbleif/shared";
import { useFirebase } from "@verbleif/shared/src/composables/useFirebase";
import { watchDebounced } from "@vueuse/core";
import { TaskListType } from "@web/features/Tasks/TasksRoutes";
import moment from "moment";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";
import NavbarSettingsitem from "../NavbarSettingsItem.vue";
import { useNavbarNotifications } from "./useNavbarNotifications";

const { t } = useI18n();
const router = useRouter();
const container: Ref<HTMLElement | null> = ref(null);
const { granted } = useFirebase();

const {
  isAllRead,
  items,
  loading,
  searchable,
  hasNextPage,
  currentPage,
  load,
  markAsRead,
  markOneAsRead,
  hasError,
  hasNotifications,
  unreadCount,
  checkUnreadCount,
  resetAndLoad,
} = useNavbarNotifications();

const TRANSLATION_KEY = "navbar.dropdown";

function fromNow(sentAt: string) {
  const currentTime = moment();
  const sentAtTime = moment(sentAt);
  const diffInSeconds = currentTime.diff(sentAtTime, "seconds");

  if (diffInSeconds < 60) {
    return t(`${TRANSLATION_KEY}.just_now`);
  } else {
    return sentAtTime.from(currentTime);
  }
}

async function notificationLocation(notification: NotificationHydraItem) {
  if (!notification.type) {
    toast.error(t(`${TRANSLATION_KEY}.no_path`));
    return;
  }

  await markOneAsRead(notification);
  if (notification.type === "task" && notification.task) {
    const id = iriToId(notification.task);
    router.push({ name: "task-list", params: { type: TaskListType.TYPE_ALL, taskId: `t${id}` } });
  } else if (notification.type === "report" && notification.report) {
    const id = iriToId(notification.report);
    router.push({ name: "report-list", params: { type: "aall", reportId: `r${id}` } });
  } else {
    toast.error(t(`${TRANSLATION_KEY}.no_path`));
  }
}

async function handleMarkAsRead(notification: NotificationHydraItem, event: Event) {
  event.stopPropagation();
  await markOneAsRead(notification);
}

useLazyLoadScroll(
  container,
  (options: any) => {
    if (currentPage) {
      currentPage.value++;
      load(options);
    }
  },
  hasNextPage,
  loading,
  ScrollDirection.BOTTOM,
);

// Add debounced search functionality
watchDebounced(() => searchable.searchValue.value, () => {
  resetAndLoad();
}, { debounce: 300 });
</script>

<template>
  <VDropdown
    theme="verbleif-popper-bounded"
    placement="right"
    @show="() => {
      resetAndLoad();
      checkUnreadCount();
    }"
  >
    <template #popper>
      <div class="flex flex-col text-gray-800 text-lg leading-5  dark:text-white h-[500px] w-[361px] flex-1">
        <div class="mx-5 mt-4 flex flex-col gap-4">
          <div class="flex justify-between items-center gap-3">
            <div class="text-lg font-bold">
              {{ t(`${TRANSLATION_KEY}.notifications`) }}
            </div>
            <div
              class="text-sm cursor-pointer"
              :class="{
                'text-primary-600 hover:text-primary-700': hasNotifications && !isAllRead,
                'text-gray-300 pointer-events-none': !hasNotifications || isAllRead,
              }"
              @click="markAsRead()"
            >
              {{ t(`${TRANSLATION_KEY}.mark_all_read`) }}
            </div>
          </div>
          <div v-if="!granted" class="flex items-center justify-between rounded-lg bg-red-600 bg-opacity-10 text-red-600 text-sm font-bold">
            <div class="flex items-center">
              <FontAwesomeIcon class="ml-4 mr-3" :icon="['fa', 'thumbtack']" />
              <div>{{ t(`${TRANSLATION_KEY}.push_notification`) }}</div>
            </div>
            <RouterLink v-close-popper.all class="m-4" to="/settings/notifications">
              <FontAwesomeIcon :icon="['fa', 'arrow-up-right-from-square']" />
            </RouterLink>
          </div>
          <div>
            <VInput
              v-model="searchable.searchValue.value"
              :icon-left="['fa', 'magnifying-glass']"
              :icon-right="['fa', 'xmark']"
              :placeholder="t(`${TRANSLATION_KEY}.search`)"
              @on-right-icon-click="searchable.searchValue.value = ''"
            />
          </div>
        </div>
        <div v-if="hasError" class="flex flex-col items-center justify-center p-8 text-center flex-1 overflow-hidden">
          <FontAwesomeIcon :icon="['fad', 'triangle-exclamation']" class="text-4xl text-red-500 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.error_loading`) }}
          </div>
          <VButton variant="secondary" class="mt-4" @click="load">
            {{ t(`${TRANSLATION_KEY}.try_again`) }}
          </VButton>
        </div>
        <div
          v-else-if="!loading && !hasNotifications && !searchable.searchValue.value"
          class="flex flex-col items-center justify-center p-8 text-center flex-1 overflow-hidden"
        >
          <FontAwesomeIcon :icon="['fad', 'bell']" class="text-4xl text-gray-400 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.no_notifications`) }}
          </div>
        </div>
        <div
          v-else-if="!loading && !hasNotifications && searchable.searchValue.value"
          class="flex flex-col items-center justify-center p-8 text-center flex-1 overflow-hidden"
        >
          <FontAwesomeIcon :icon="['fad', 'search']" class="text-4xl text-gray-400 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.no_search_results`) }}
          </div>
        </div>
        <!-- this is the list of notifications -->
        <div v-else class="flex flex-1 overflow-hidden">
          <ul ref="container" class="flex flex-col w-full max-h-[400px] overflow-y-auto mt-3 text-gray-800 dark:text-white">
            <li
              v-for="(notification, index) in items"
              :key="'skeleton' in notification ? `skeleton-${index}` : notification.id"
              class="hover:bg-gray-100 dark:hover:bg-gray-800 "
            >
              <template v-if="'skeleton' in notification">
                <div
                  class="flex pl-2 pr-3 py-3 rounded-lg group"
                >
                  <div class="flex h-6 w-5 items-center justify-center">
                    <VSkeleton
                      v-if="Math.round(Math.random())"
                      class="mb-1"
                      :dynamic-width="false"
                      :width="8"
                      :height="8"
                      width-unit="px"
                      height-unit="px"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <div class="flex justify-between">
                      <VSkeleton class="mb-2" :width="100" :height="16" width-unit="px" height-unit="px" />
                      <VSkeleton class="mb-1" :width="100" :height="12" width-unit="px" height-unit="px" />
                    </div>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <VSkeleton class="mb-1" :width="200" :height="16" width-unit="px" height-unit="px" />
                        <VSkeleton class="mb-1" :width="150" :height="16" width-unit="px" height-unit="px" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="flex flex-col h-20">
                  <div class="mx-5">
                    <VDivider no-margins />
                  </div>
                  <div
                    v-close-popper.all="notification.subject && (notification.subject.includes('task') || notification.subject.includes('report'))"
                    class="flex pr-3 py-3 rounded-lg group cursor-pointer"
                    @click="notificationLocation(notification)"
                  >
                    <div
                      class="flex h-4 w-5 items-center justify-center cursor-pointer"
                    >
                      <div v-if="!notification.readAt" class="w-2 h-2 bg-primary-600 rounded-full" />
                    </div>
                    <div class="flex flex-col w-full">
                      <div class="flex justify-between">
                        <div :class="notification.readAt ? '' : 'font-bold'" class="dark:text-white text-sm leading-4 mb-1">
                          {{ notification.title }}
                        </div>
                        <div v-if="notification.sentAt" class="text-gray-400 dark:text-grey-200 text-xs">
                          {{ fromNow(notification.sentAt) }}
                        </div>
                      </div>
                      <div class="w-fit">
                        <VTooltip
                          theme="verbleif-tooltip-dark"
                          placement="top"
                        >
                          <template #content>
                            {{ notification.subject?.includes('task')
                              ? t(`${TRANSLATION_KEY}.view_task`)
                              : t(`${TRANSLATION_KEY}.view_report`)
                            }}
                          </template>
                          <template #reference>
                            <div class="group-hover:text-primary-600">
                              {{ notification.subject }}
                            </div>
                          </template>
                        </VTooltip>
                      </div>
                      <div class="flex justify-between">
                        <div class="text-gray-500 font-normal leading-4 dark:text-grey-300 w-3/4 text-sm">
                          {{ truncateText(notification.messageWeb ?? '', 65) }}
                        </div>
                        <div class="flex items-end">
                          <div v-if="!notification.readAt" class="relative group">
                            <VTooltip theme="verbleif-tooltip-dark" placement="top">
                              <template #content>
                                {{ t(`${TRANSLATION_KEY}.mark_as_read`) }}
                              </template>
                              <template #reference>
                                <FontAwesomeIcon
                                  class="text-primary-600 text-sm hidden group-hover:flex cursor-pointer"
                                  :icon="['far', 'circle-check']"
                                  @click="handleMarkAsRead(notification, $event)"
                                />
                              </template>
                            </VTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <NavbarSettingsitem :icon="['fad', 'bell']" :text="t(`${TRANSLATION_KEY}.notifications`)" :count="unreadCount" />
  </VDropdown>
</template>
