import type { Component } from "vue";
import { createApp, reactive } from "vue";
import ConfirmDialog from "./VConfirmDialog.vue";
import Dialog from "./VDialog.vue";

const BASE_Z_INDEX = 1000;
const Z_INDEX_INCREMENT = 2;
const CONFIRM_DIALOG_PREFIX = "confirm-dialog-";

interface DialogInfo {
  id: string
  instanceId: string
  zIndex: number
  teleportTarget?: string
  parentId?: string
  isConfirm?: boolean
  order: number
}

interface DialogState {
  dialogs: DialogInfo[]
  activeDialogId: string | null
  openOrder: string[]
  nextOrder: number
}

const state = reactive<DialogState>({
  dialogs: [],
  activeDialogId: null,
  openOrder: [],
  nextOrder: 0,
});

function generateInstanceId(id: string): string {
  return `${id}-${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
}

function getZIndexForOrder(order: number): number {
  return BASE_Z_INDEX + (order * Z_INDEX_INCREMENT);
}

function getCurrentMaxZIndex(): number {
  return state.openOrder.reduce((max, instanceId) => {
    const dialog = state.dialogs.find(d => d.instanceId === instanceId);
    return dialog ? Math.max(max, dialog.zIndex) : max;
  }, BASE_Z_INDEX - Z_INDEX_INCREMENT);
}

function getConfirmDialogZIndex(): number {
  return getCurrentMaxZIndex() + Z_INDEX_INCREMENT;
}

function findActiveDialog(): DialogInfo | undefined {
  return state.dialogs.find(d => d.instanceId === state.activeDialogId);
}

function registerDialog(id: string, teleportTarget?: string): string {
  const instanceId = generateInstanceId(id);
  const parentDialog = findActiveDialog();
  const isConfirm = id.startsWith(CONFIRM_DIALOG_PREFIX);
  const order = state.nextOrder++;

  const zIndex = isConfirm
    ? getConfirmDialogZIndex()
    : getZIndexForOrder(order);

  state.dialogs.push({
    id,
    instanceId,
    zIndex,
    order,
    teleportTarget,
    parentId: parentDialog?.instanceId,
    isConfirm,
  });

  return instanceId;
}

function openDialog(id: string): string {
  const instanceId = registerDialog(id);

  if (!state.openOrder.includes(instanceId)) {
    state.openOrder.push(instanceId);
  }

  state.activeDialogId = instanceId;
  return instanceId;
}

function closeDialog(instanceId: string) {
  const dialogToClose = state.dialogs.find(d => d.instanceId === instanceId);
  if (!dialogToClose) {
    return;
  }

  const dialogsToClose = state.dialogs.filter(d =>
    d.instanceId === instanceId || d.parentId === instanceId,
  );

  dialogsToClose.forEach((dialog) => {
    const orderIndex = state.openOrder.indexOf(dialog.instanceId);
    if (orderIndex !== -1) {
      state.openOrder.splice(orderIndex, 1);
    }
  });

  dialogsToClose.forEach((dialog) => {
    const index = state.dialogs.findIndex(d => d.instanceId === dialog.instanceId);
    if (index !== -1) {
      state.dialogs.splice(index, 1);
    }
  });

  state.activeDialogId = state.openOrder[state.openOrder.length - 1] || null;
}

function getDialogZIndex(instanceId: string): number {
  const dialog = state.dialogs.find(d => d.instanceId === instanceId);
  if (!dialog) {
    return BASE_Z_INDEX;
  }

  if (dialog.isConfirm) {
    return getConfirmDialogZIndex();
  }

  return dialog.zIndex;
}

function getMaskZIndex(dialogZIndex: number): number {
  return dialogZIndex - 1;
}

function showDialog(config: {
  id: string
  title?: string
  contentComponent?: Component
  componentProps?: Record<string, any>
  onConfirm?: (input: any) => void
  onCancel?: () => void
  size?: string
  mask?: boolean
  isDirty?: () => boolean
  message?: string
  icon?: string
  type?: string
  visible?: boolean
}) {
  const DialogComponent = config.contentComponent || ConfirmDialog;
  const container = document.createElement("div");
  container.setAttribute("data-dialog-container", config.id);
  document.body.appendChild(container);

  const instanceId = registerDialog(config.id);

  const dialogApp = createApp(DialogComponent || Dialog, {
    ...config.componentProps,
    id: config.id,
    visible: true,
    onClose: () => {
      config.onCancel?.();
      closeDialog(instanceId);
      dialogApp.unmount();
      container.remove();
    },
    onConfirm: (input: any) => {
      config.onConfirm?.(input);
      closeDialog(instanceId);
      dialogApp.unmount();
      container.remove();
    },
  });

  openDialog(config.id);

  dialogApp.mount(container);
  return dialogApp;
}

export function useDialogStore() {
  return {
    state,
    openDialog,
    closeDialog,
    getDialogZIndex,
    getMaskZIndex,
    showDialog,
    registerDialog,
  };
}
