<script setup lang="ts">
import type { VNode } from "vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useTabsState } from "../useTabsState";
import { tabListActiveInkbarCva, tabListContainerCva, tabListContentWrapperCva, tabListDividerCva, tabListInkbarBackgroundCva, tabListItemCva, tabListItemsContainerCva, tabListNextButtonCva, tabListPreviousButtonCva } from "./VTabList.cva";

defineSlots<{
  "default"?: () => VNode[]
  "prev-icon"?: () => VNode[]
  "next-icon"?: () => VNode[]
}>();

const tabsContainer = ref<HTMLElement | null>(null);
const inkbarRef = ref<HTMLElement | null>(null);
const itemsContainerRef = ref<HTMLElement | null>(null);

const showPrevButton = ref(false);
const showNextButton = ref(false);
const shouldShowButtons = ref(false);

const {
  activeIndex,
  orientation,
  listPosition,
  tile,
  scrollable,
  tabItems,
  inkbar,
  centerItemsInList,
  dividerBetweenTabs,
} = useTabsState();

const activeTabError = computed(() => {
  const activeTab = tabItems.value.find(tab => tab.index === activeIndex.value);
  return activeTab?.error ?? false;
});

function getScrollContainer() {
  return itemsContainerRef.value;
}

function checkOverflow() {
  const container = getScrollContainer();
  if (!container || !tabsContainer.value) {
    return;
  }

  const isVertical = orientation?.value === "vertical";
  const size = isVertical ? "Height" : "Width";

  const containerElement = container as HTMLElement;
  const hasOverflow = containerElement[`scroll${size}`] > containerElement[`offset${size}`];

  shouldShowButtons.value = hasOverflow && !scrollable?.value;

  if (shouldShowButtons.value) {
    const pos = isVertical ? "Top" : "Left";
    showPrevButton.value = containerElement[`scroll${pos}`] > 0;
    showNextButton.value = containerElement[`scroll${pos}`] < (containerElement[`scroll${size}`] - containerElement[`offset${size}`]);
  } else {
    showPrevButton.value = false;
    showNextButton.value = false;
  }
}

function updateInkBar() {
  if (!tabsContainer.value || !inkbarRef.value || !inkbar?.value) {
    return;
  }

  const activeTab = tabsContainer.value.querySelector(".tab-item[aria-selected='true']");
  if (!activeTab) {
    return;
  }

  const scrollContainer = getScrollContainer();
  if (!scrollContainer) {
    return;
  }

  const activeTabRect = (activeTab as HTMLElement).getBoundingClientRect();
  const containerRect = scrollContainer.getBoundingClientRect();

  const isVertical = orientation?.value === "vertical";
  const scrollOffset = isVertical ? scrollContainer.scrollTop : scrollContainer.scrollLeft;

  const pos = isVertical
    ? activeTabRect.top - containerRect.top + scrollOffset
    : activeTabRect.left - containerRect.left + scrollOffset;

  const size = isVertical ? activeTabRect.height : activeTabRect.width;

  const styles = isVertical
    ? {
        top: `${pos}px`,
        height: `${size}px`,
        width: "2px",
        ...(tile?.value
          ? {
              [listPosition?.value === "left" ? "right" : "left"]: "0",
              borderRadius: "0",
            }
          : {
              [listPosition?.value === "left" ? "right" : "left"]: "0",
              borderRadius: undefined,
            }),
      }
    : {
        left: `${pos}px`,
        width: `${size}px`,
        height: "2px",
        bottom: "0",
      };

  inkbarRef.value.style.cssText = Object.entries(styles)
    .filter(([,value]) => value !== undefined)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");
}

function scroll(direction: 1 | -1) {
  const container = getScrollContainer();
  if (!container) {
    return;
  }

  const isVertical = orientation?.value === "vertical";
  const amount = isVertical ? container.clientHeight : container.clientWidth;
  const prop = isVertical ? "top" : "left";

  container.scrollBy({
    [prop]: amount * direction,
    behavior: "smooth",
  });
}

function scrollPrev() {
  scroll(-1);
}

function scrollNext() {
  scroll(1);
}

watch(activeIndex, () => {
  nextTick(() => {
    updateInkBar();
  });
});

function onScroll() {
  checkOverflow();
  updateInkBar();
}

onMounted(() => {
  nextTick(() => {
    checkOverflow();
    updateInkBar();
  });

  const container = getScrollContainer();
  if (container) {
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
      updateInkBar();
    });
    resizeObserver.observe(container);
    if (container.parentElement) {
      resizeObserver.observe(container.parentElement);
    }
  }
});

const containerClasses = computed(() => {
  return tabListContainerCva({
    orientation: orientation?.value,
    listPosition: listPosition?.value,
    tile: tile?.value,
  });
});

const previousButtonClasses = computed(() => {
  return tabListPreviousButtonCva({
    orientation: orientation?.value,
    listPosition: listPosition?.value,
  });
});

const nextButtonClasses = computed(() => {
  return tabListNextButtonCva({
    orientation: orientation?.value,
    listPosition: listPosition?.value,
  });
});

const inkbarBackgroundClasses = computed(() => {
  return tabListInkbarBackgroundCva({
    orientation: orientation?.value,
    listPosition: listPosition?.value,
  });
});

const activeInkbarClasses = computed(() => {
  return tabListActiveInkbarCva({
    orientation: orientation?.value,
    error: activeTabError.value,
    tile: tile?.value,
  });
});

const contentWrapperClasses = computed(() => {
  return tabListContentWrapperCva({
    orientation: orientation?.value,
    buttons: shouldShowButtons.value,
  });
});

const itemClasses = computed(() => {
  return tabListItemCva({
    orientation: orientation?.value,
    tile: tile?.value,
  });
});

const dividerClasses = computed(() => {
  return tabListDividerCva({
    orientation: orientation?.value,
    listPosition: listPosition?.value,
  });
});

const itemsContainerClasses = computed(() => {
  return tabListItemsContainerCva({
    orientation: orientation?.value,
    tile: tile?.value,
    divider: dividerBetweenTabs?.value,
    center: centerItemsInList?.value,
    scrollable: scrollable?.value,
  });
});
</script>

<template>
  <div
    ref="tabsContainer"
    :class="[containerClasses]"
    role="tablist"
  >
    <!-- Previous button -->
    <button
      v-if="shouldShowButtons"
      :disabled="!showPrevButton"
      :class="[previousButtonClasses]"
      @click.prevent="scrollPrev"
      @mousedown.stop
    >
      <slot name="prev-icon">
        <FontAwesomeIcon :icon="['fas', orientation === 'horizontal' ? 'chevron-left' : 'chevron-up']" />
      </slot>
    </button>

    <!-- Tab content wrapper -->
    <div
      :class="[contentWrapperClasses]"
    >
      <!-- Tab items container -->
      <div
        ref="itemsContainerRef"
        :class="[itemsContainerClasses]"
        @scroll="onScroll"
      >
        <!-- Tab items -->
        <div
          :class="[itemClasses]"
        >
          <template v-for="(item, index) in $slots.default?.() || []" :key="index">
            <component :is="item" />
            <div
              v-if="dividerBetweenTabs
                && index < ($slots.default?.()?.length || 0) - 1
                && (item.type as any).__name === 'VTabItem'
                && ($slots.default?.()[index + 1].type as any).__name === 'VTabItem'"
              :class="[dividerClasses]"
            />
          </template>
        </div>

        <!-- Inkbar background -->
        <div
          v-if="inkbar"
          :class="[inkbarBackgroundClasses]"
        />

        <!-- Active inkbar -->
        <div
          v-if="inkbar"
          ref="inkbarRef"
          :class="[activeInkbarClasses]"
        />
      </div>
    </div>

    <!-- Next button -->
    <button
      v-if="shouldShowButtons"
      :disabled="!showNextButton"
      :class="[nextButtonClasses]"
      @click.prevent="scrollNext"
      @mousedown.stop
    >
      <slot name="next-icon">
        <FontAwesomeIcon :icon="['fas', orientation === 'horizontal' ? 'chevron-right' : 'chevron-down']" />
      </slot>
    </button>
  </div>
</template>
