<script setup lang="ts">
import type { ReservationHydraItem, TransitionState } from "@verbleif/lib";
import type { ParsedLocale } from "../Occupation/localeParser";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { parseLocale } from "../Occupation/localeParser";

const { t, locale, te } = useI18n();
const visible = ref(false);
const currentReservation = ref<{
  reservation: ReservationHydraItem | null
  state: TransitionState | null
} | null>(null);

const currentMoment = computed(() => moment().locale(locale.value));

function formatDate(isoDate: string) {
  if (!isoDate) {
    return { date: "", time: "", isToday: false };
  }

  const momentDate = moment(isoDate).locale(locale.value);
  if (!momentDate.isValid()) {
    return { date: "", time: "", isToday: false };
  }

  const time = momentDate.format("HH:mm");
  const isToday = momentDate.isSame(currentMoment.value, "day");
  const formattedDate = momentDate.format("dddd, D MMMM");

  return {
    date: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1),
    time,
    isToday,
  };
}

async function show({
  currentReservation: currentReservationProp,
}: {
  currentReservation?: {
    reservation: ReservationHydraItem | null
    state: TransitionState | null
  } | null
}) {
  visible.value = true;
  currentReservation.value = {
    reservation: currentReservationProp?.reservation || null,
    state: currentReservationProp?.state || null,
  };
}

function hide() {
  visible.value = false;
  currentReservation.value = null;
}

defineExpose({
  show,
  hide,
});

// Get total guests count
const getGuestCount = computed(() => {
  const reservationData = currentReservation.value?.reservation?.data;
  if (!reservationData) {
    return {
      guests: 0,
      pets: 0,
    };
  }

  return {
    guests: (reservationData.adultAmount || 0)
      + (reservationData.adolescentAmount || 0)
      + (reservationData.babyAmount || 0)
      + (reservationData.childAmount || 0),
    pets: reservationData.petAmount || 0,
  };
});

const parsedLocale = computed<ParsedLocale>(() => {
  const raw = currentReservation.value?.reservation?.data.locale ?? "UNKNOWN";
  return parseLocale(raw);
});

const language = computed(() => parsedLocale.value.language);
const region = computed(() => parsedLocale.value.region);
const flagCode = computed(() => region.value);
const localeFlagExists = ref(false);

async function checkFlagExists(flagValue: string) {
  try {
    const response = await fetch(`/flags/${flagValue.toUpperCase()}.svg`, { method: "HEAD" });
    const contentType = response.headers.get("content-type");
    localeFlagExists.value = contentType ? !contentType.includes("text/html") : false;
  } catch (error) {
    console.error(error);
    localeFlagExists.value = false;
  }
}

watch(region, (newRegion) => {
  if (newRegion) {
    checkFlagExists(newRegion);
  }
}, { immediate: true });

const localeFlag = computed(() => {
  return localeFlagExists.value
    ? `/flags/${flagCode.value.toUpperCase()}.svg`
    : "/flags/UNKNOWN.svg";
});

const localeTranslation = computed(() => {
  const languageKey = language.value.toUpperCase();
  const baseTranslation = te(`locales.${languageKey}`)
    ? t(`locales.${languageKey}`)
    : t("locales.UNKNOWN");

  const rawLocale = currentReservation.value?.reservation?.data.locale || "";
  const parts = rawLocale.split("_");

  if (parts.length > 1) {
    const regionCode = region.value.toUpperCase();
    const countryName = t(`countries.${regionCode}`) || region.value;
    return `${baseTranslation} (${countryName})`;
  }

  return baseTranslation;
});
</script>

<template>
  <VDialog
    id="current-reservation-dialog"
    :visible="visible"
    mask
    @close="hide"
  >
    <template #title>
      <strong class="text-xl text-gray-800 dark:text-gray-200">
        {{ t("tasks.reservation.details") }}
      </strong>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-green-500">
              <FontAwesomeIcon :icon="['fad', 'left-to-bracket']" />
            </div>
            <span class="flex-1 text-gray-800 dark:text-gray-200 font-medium">{{ t("reservations.arriveAt") || "Aankomst" }}</span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-400">
              <FontAwesomeIcon :icon="['fad', 'calendar-day']" />
            </div>
            <template v-if="currentReservation?.reservation?.startAt">
              <template v-if="formatDate(currentReservation?.reservation?.startAt).isToday">
                <span class="flex-1 text-blue-600">{{ t("base.today") }}, {{ formatDate(currentReservation?.reservation?.startAt).time }}</span>
              </template>
              <template v-else>
                <span class="flex-1 text-gray-800 dark:text-gray-200">
                  {{ formatDate(currentReservation?.reservation?.startAt).date }},
                  {{ formatDate(currentReservation?.reservation?.startAt).time }}
                </span>
              </template>
            </template>
          </div>
        </div>

        <VDivider class="my-1 !mb-1" />

        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-red-500">
              <FontAwesomeIcon :icon="['fad', 'right-from-bracket']" />
            </div>
            <span class="flex-1 text-gray-800 dark:text-gray-200 font-medium">{{ t("reservations.leaveAt") || "Vertrek" }}</span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-400">
              <FontAwesomeIcon :icon="['fad', 'calendar-day']" />
            </div>
            <template v-if="currentReservation?.reservation?.endAt">
              <template v-if="formatDate(currentReservation?.reservation?.endAt).isToday">
                <span class="flex-1 text-blue-600">{{ t("base.today") }}, {{ formatDate(currentReservation?.reservation?.endAt).time }}</span>
              </template>
              <template v-else>
                <span class="flex-1 text-gray-800 dark:text-gray-200">
                  {{ formatDate(currentReservation?.reservation?.endAt).date }},
                  {{ formatDate(currentReservation?.reservation?.endAt).time }}
                </span>
              </template>
            </template>
          </div>

          <div v-if="currentReservation?.reservation?.lateCheckout" class="flex items-center gap-1">
            <div class="w-6 text-red-600">
              <FontAwesomeIcon :icon="['fad', 'clock']" />
            </div>
            <span class="flex-1 text-red-600">{{ t("reservations.lateCheckout") }}</span>
          </div>
        </div>

        <VDivider class="my-1 !mb-1" />

        <div class="flex flex-col gap-1">
          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-600">
              <FontAwesomeIcon :icon="['fad', 'people-group']" />
            </div>
            <span class="flex-1 text-gray-800 dark:text-gray-200">
              {{ t("tasks.reservation.guests", { n: getGuestCount.guests }) || `${getGuestCount.guests} personen` }}
            </span>
          </div>

          <div class="flex items-center gap-1">
            <div class="w-6 text-gray-600">
              <FontAwesomeIcon :icon="['fad', 'paw']" />
            </div>
            <span v-if="getGuestCount.pets > 0" class="flex-1 text-gray-800 dark:text-gray-200">
              {{ t("tasks.reservation.pets", { n: getGuestCount.pets }) || `${getGuestCount.pets} huisdieren` }}
            </span>
            <span v-else class="flex-1 text-gray-800 dark:text-gray-200">{{ t("tasks.reservation.no_pets") || "Geen huisdieren" }}</span>
          </div>

          <div v-if="currentReservation?.reservation?.data.locale" class="flex items-center gap-3">
            <div class="flex flex-row items-center gap-1">
              <div class="mr-2">
                <img alt="flag" :src="localeFlag" class="w-5 self-center">
              </div>

              <span class="flex-1">{{ localeTranslation }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </VDialog>
</template>

<style scoped>

</style>
