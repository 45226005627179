<script setup lang="ts">
import type { RequiredProps, RootProps, SwitchProps } from "./VSwitch.types";
import { computed, defineProps, useAttrs, useSlots } from "vue";
import { useComponentId } from "../useComponentId";
import { labelCva, requiredCva, rootCva, switchCva, thumbCheckedCva, thumbCva } from "./VSwitch.cva";

const {
  name = "",
  label = "",
  labelPosition = "left",
  required = false,
  trueValue = true,
  falseValue = false,
  errorMessage = "",
  hasPerms = true,
  size = "medium",
} = defineProps<{
  name?: string
  label?: string
  labelPosition?: RootProps["labelPosition"]
  required?: RequiredProps["required"]
  hasPerms?: boolean
  trueValue?: any
  falseValue?: any
  errorMessage?: string
  size?: SwitchProps["size"]
}>();

defineSlots<{
  label: void
}>();

const modelValue = defineModel<any>({ default: false });

const attrs = useAttrs();
const isDisabled = computed(() => (typeof attrs.disabled !== "undefined" && attrs.disabled !== false) || !hasPerms);
const slots = useSlots();
const id: any = useComponentId();

function toggle() {
  if (!isDisabled.value) {
    modelValue.value = modelValue.value === trueValue ? falseValue : trueValue;
  }
}

const showLabel = computed(() => !errorMessage && (label || slots.label));

const switchClasses = computed(() => {
  return {
    root: rootCva({
      labelPosition,
    }),
    switch: switchCva({
      size,
      disabled: isDisabled.value,
      errorMessage: !!errorMessage,
      active: modelValue.value === trueValue,
      hasPerms,
    }),
    thumb: thumbCva({
      size,
    }),
    label: labelCva({
      size,
    }),
    thumbChecked: thumbCheckedCva({
      size,
    }),
    required: requiredCva({
      required,
    }),
  };
});
</script>

<template>
  <div class="flex gap-x-2" :class="[switchClasses.root]">
    <label
      v-if="showLabel"
      :for="id"
      class="flex items-center gap-1 mb-1 text-gray-700 dark:text-white"
      :class="[switchClasses.label]"
    >
      <slot name="label">{{ label }}</slot>
      <span :class="[switchClasses.required]">*</span>
    </label>

    <div>
      <div
        class="relative flex items-center justify-between w-full rounded-full"
        @click="toggle"
      >
        <!-- Switch -->
        <ACTooltip
          type="edit_field"
          :allow-change="hasPerms"
        >
          <div class="relative">
            <input
              :id="id"
              v-model="modelValue"
              type="checkbox"
              :name="name"
              :disabled="isDisabled"
              :true-value="trueValue"
              :false-value="falseValue"
              class="sr-only"
            >
            <div
              class="rounded-full transition-colors duration-200"
              :class="[switchClasses.switch]"
            >
              <div
                class="absolute top-1/2 -translate-y-1/2 rounded-full transition-all duration-200 bg-white dark:bg-white"
                :class="[
                  switchClasses.thumb,
                  modelValue === trueValue ? switchClasses.thumbChecked : '',
                ]"
              />
            </div>
          </div>
        </ACTooltip>
      </div>
    </div>
  </div>
</template>
