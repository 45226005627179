import type { PermissionAttributesEnum, PermissionScope } from "@verbleif/lib";
import type { PermissionService } from "../../PermissionService";

export interface CrudArgs {
  "@id"?: string
  "location"?: string | null
  "client"?: string | null
}

export class ContextVoter {
  public permissionService: PermissionService;

  constructor(permissionService: PermissionService) {
    this.permissionService = permissionService;
  }

  protected _canCreate({
    item = {
      location: this.permissionService.defaultLocation,
      client: this.permissionService.defaultClient,
    },
    globalAttribute,
    contextAttribute,
  }: {
    item?: Omit<CrudArgs, "@id">
    globalAttribute: PermissionAttributesEnum | PermissionAttributesEnum[]
    contextAttribute: PermissionAttributesEnum | PermissionAttributesEnum[]
  }): boolean {
    return this._canCrud({
      item,
      globalAttribute,
      contextAttribute,
      scopeLess: true,
    });
  }

  protected _canCrud(
    {
      item,
      globalAttribute,
      contextAttribute,
      scope,
      scopeLess,
    }: {
      item: CrudArgs
      globalAttribute: PermissionAttributesEnum | PermissionAttributesEnum[]
      contextAttribute: PermissionAttributesEnum | PermissionAttributesEnum[]
      scope?: Exclude<PermissionScope, PermissionScope.GLOBAL>
      scopeLess?: boolean
    },
  ): boolean {
    if (this.permissionService.getGlobalPermissionForUser({
      attribute: globalAttribute,
    })) {
      return true;
    }
    return this.hasAccess({
      "@id": item["@id"],
      "location": item.location,
      "client": item.client,
      "attribute": contextAttribute,
      scope,
      scopeLess,
    });
  }

  protected hasAccess(
    {
      "@id": iri,
      location,
      client,
      attribute,
      scope,
      scopeLess,
    }: {
      "@id"?: string
      "location"?: string | null
      "client"?: string | null
      "attribute": PermissionAttributesEnum | PermissionAttributesEnum[]
      "scope"?: Exclude<PermissionScope, PermissionScope.GLOBAL>
      "scopeLess"?: boolean
    },
  ): boolean {
    if (scopeLess) {
      const inputClient = client ?? (location ? this.permissionService.getClientViaLocation(location) : null);

      if (inputClient === null) {
        return false;
      }

      const scopeLessUserPermissionClient = this.permissionService.getScopeLessPermissionForUser({
        client: inputClient,
        attribute,
      });

      if (scopeLessUserPermissionClient !== null) {
        return true;
      }

      const scopeLessUserPermissionLocation = this.permissionService.getScopeLessPermissionForUser({
        client: inputClient,
        location,
        attribute,
      });

      return scopeLessUserPermissionLocation !== null;
    }

    //* If the iri is provided and the scope is provided, check if the user has permission for the iri
    if (iri && scope) {
      const userPermission = this.permissionService.getScopePermissionForUser({
        iri,
        attribute,
        scope,
      });
      if (userPermission !== null) {
        return true;
      }
    }

    //* If the iri is not provided and the scope is provided, check if the user has permission for the location
    if (!iri && scope && (location || client)) {
      const iriToCheck = location || client;

      if (iriToCheck) {
        const userPermission = this.permissionService.getScopePermissionForUser({
          iri: iriToCheck,
          attribute,
          scope,
          scopeKeyOverride: location ? "location" : "client",
        });
        if (userPermission !== null) {
          return true;
        }
      }
    }

    //* If the location is provided, check if the user has permission for the location
    if (location) {
      const userLocationPermission = this.permissionService.getLocationPermissionForUser({ locationIri: location, attribute });
      if (userLocationPermission !== null) {
        return true;
      }
    }

    const inputClient = client ?? (location ? this.permissionService.getClientViaLocation(location) : null);

    if (inputClient === null) {
      return false;
    }

    //* If the client is provided, check if the user has permission for the client
    const clientPermission = this.permissionService.getClientPermissionForUser({ clientIri: inputClient, attribute });

    return clientPermission !== null;
  }
}
