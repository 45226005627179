<script setup lang="ts">
import type { PropertyReservationStateHydraItem, PropertyTaskStateHydraItem, TaskDataHydraItem } from "@verbleif/lib";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";

const emit = defineEmits<{
  (e: "close"): void
}>();

const visible = ref(false);
enum Mode {
  text = "text",
  tree = "tree",
  table = "table",
}

const reservationState = ref<PropertyReservationStateHydraItem | null>(null);
const taskState = ref<PropertyTaskStateHydraItem | null>(null);
const taskData = ref<TaskDataHydraItem | null>(null);

const JsonEditorVue = defineAsyncComponent(() =>
  import("json-editor-vue"),
);
async function show({
  reservationState: reservationStateProp,
  taskState: taskStateProp,
  taskData: taskDataProp,
}: {
  reservationState?: PropertyReservationStateHydraItem | null
  taskState?: PropertyTaskStateHydraItem | null
  taskData?: TaskDataHydraItem | null
}) {
  visible.value = true;
  reservationState.value = reservationStateProp || null;
  taskState.value = taskStateProp || null;
  taskData.value = taskDataProp || null;
}

function hide() {
  visible.value = false;
  reservationState.value = null;
  taskState.value = null;
  taskData.value = null;
  emit("close");
}

defineExpose({
  show,
  hide,
});

const activeTabIndex = ref(0);
</script>

<template>
  <VDialog
    id="device-details-dialog"
    :visible="visible"
    mask
    size="large"
    @close="hide"
  >
    <template #title>
      <div class="flex justify-between gap-2 items-center px-1">
        <div class="flex items-center gap-2">
          <FontAwesomeIcon :icon="['fad', 'bug']" class="text-primary-500 text-xl" />
          <h3 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
            Property State Debug
          </h3>
        </div>
      </div>
    </template>

    <template #content>
      <VTabs
        v-model:model-value="activeTabIndex"
        lazy
        divider-between-tabs
        orientation="horizontal"
      >
        <VTabList class="">
          <VTabItem
            title="Property Reservation State"
            :icon="['fad', 'bed']"
            :index="0"
          />
          <VTabItem
            title="Property Task State"
            :icon="['fad', 'calendar-alt']"
            :index="1"
          />
          <VTabItem
            title="Property Reservation State Multi"
            :icon="['fad', 'clipboard-list']"
            :index="2"
          />
        </VTabList>
        <VTabPanels class="pt-4">
          <VTabPanel :index="0">
            <div v-if="reservationState">
              <JsonEditorVue
                :model-value="reservationState"
                :mode="Mode.text"
                :stringified="false"
                read-only
                class="min-h-[400px]"
              />
            </div>
            <div v-else class="flex items-center justify-center p-8 text-gray-500 bg-gray-50 dark:bg-dark-800 rounded-lg border border-gray-200 dark:border-dark-700 border-dashed">
              <div class="text-center">
                <FontAwesomeIcon :icon="['fad', 'calendar']" class="text-3xl mb-2 text-gray-400" />
                <p>No reservation state data available</p>
              </div>
            </div>
          </VTabPanel>
          <VTabPanel :index="1">
            <div v-if="taskState">
              <JsonEditorVue
                :model-value="taskState"
                :mode="Mode.text"
                :stringified="false"
                read-only
                class="min-h-[400px]"
              />
            </div>
            <div v-else class="flex items-center justify-center p-8 text-gray-500 bg-gray-50 dark:bg-dark-800 rounded-lg border border-gray-200 dark:border-dark-700 border-dashed">
              <div class="text-center">
                <FontAwesomeIcon :icon="['fad', 'tasks']" class="text-3xl mb-2 text-gray-400" />
                <p>No task state data available</p>
              </div>
            </div>
          </VTabPanel>
          <VTabPanel :index="2">
            <div v-if="taskData">
              <JsonEditorVue
                :model-value="taskData"
                :mode="Mode.text"
                :stringified="false"
                read-only
                class="min-h-[400px]"
              />
            </div>
            <div v-else class="flex items-center justify-center p-8 text-gray-500 bg-gray-50 dark:bg-dark-800 rounded-lg border border-gray-200 dark:border-dark-700 border-dashed">
              <div class="text-center">
                <FontAwesomeIcon :icon="['fad', 'clipboard-list']" class="text-3xl mb-2 text-gray-400" />
                <p>No task extra data available</p>
              </div>
            </div>
          </VTabPanel>
        </VTabPanels>
      </VTabs>
    </template>
  </VDialog>
</template>
