<script setup lang="ts">
import { computed } from "vue";
import { useTabsState } from "../useTabsState";

interface Props {
  index: number
}

const {
  index = 0,
} = defineProps<Props>();

const { activeIndex, lazy } = useTabsState();
const isActive = computed(() => activeIndex.value === index);
</script>

<template>
  <div
    v-if="!lazy"
    v-show="isActive"
    class="flex-1 min-h-0 flex flex-col h-full w-full"
    role="VTabPanel"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
  <div
    v-else-if="isActive"
    class="flex-1 min-h-0 flex flex-col h-full w-full"
    role="VTabPanel"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
</template>
