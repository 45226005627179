<script setup lang="ts">
import type { ParsedLocale } from "./localeParser";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ReservationDate from "../ReservationDate.vue";
import { useReservationState } from "../useReservationState";
import { parseLocale } from "./localeParser"; // adjust the path as needed

const { reservationState, getGuestCount, loading } = useReservationState();
const { t, te } = useI18n();

const relevantReservation = "nextArrival";
const nextArrival = computed(() => reservationState.value?.nextArrival);

const parsedLocale = computed<ParsedLocale>(() => {
  const raw = nextArrival.value?.data.locale ?? "UNKNOWN";
  return parseLocale(raw);
});

const language = computed(() => parsedLocale.value.language);
const region = computed(() => parsedLocale.value.region);
const flagCode = computed(() => region.value);
const localeFlagExists = ref(false);

async function checkFlagExists(flagValue: string) {
  try {
    const response = await fetch(`/flags/${flagValue.toUpperCase()}.svg`, { method: "HEAD" });
    const contentType = response.headers.get("content-type");
    localeFlagExists.value = contentType ? !contentType.includes("text/html") : false;
  } catch (error) {
    console.error(error);
    localeFlagExists.value = false;
  }
}

watch(region, (newRegion) => {
  if (newRegion) {
    checkFlagExists(newRegion);
  }
}, { immediate: true });

const localeFlag = computed(() => {
  return localeFlagExists.value
    ? `/flags/${flagCode.value.toUpperCase()}.svg`
    : "/flags/UNKNOWN.svg";
});

const localeTranslation = computed(() => {
  const languageKey = language.value.toUpperCase();
  const baseTranslation = te(`locales.${languageKey}`)
    ? t(`locales.${languageKey}`)
    : t("locales.UNKNOWN");

  const rawLocale = nextArrival.value?.data.locale || "";
  const parts = rawLocale.split("_");

  if (parts.length > 1) {
    const regionCode = region.value.toUpperCase();
    const countryName = t(`countries.${regionCode}`) || region.value;
    return `${baseTranslation} (${countryName})`;
  }

  return baseTranslation;
});
</script>

<template>
  <div class="flex text-sm text-gray-800 dark:text-gray-200 flex-col">
    <h3 class="font-bold w-full pb-[15px]">
      {{ $t(`tasks.reservation_state.occupation.${relevantReservation}.title`) }}
    </h3>
    <div class="flex flex-col gap-2">
      <template v-if="loading">
        <div v-for="n in 2" :key="n" class="flex items-center gap-3">
          <VSkeleton :width="80" width-unit="%" :height="14" />
        </div>
        <VDivider class="my-1 !mb-1" />
        <div class="flex items-center gap-3">
          <VSkeleton :width="70" width-unit="%" :height="14" />
        </div>
        <div class="flex items-center gap-3">
          <VSkeleton :width="60" width-unit="%" :height="14" />
        </div>
      </template>
      <template v-else>
        <ReservationDate
          v-if="nextArrival?.startAt"
          :iso-date="nextArrival.startAt"
          :icon="['fad', 'left-to-bracket']"
          icon-color="text-green-500"
        />
        <ReservationDate
          v-if="nextArrival?.endAt"
          :iso-date="nextArrival.endAt"
          :icon="['fad', 'right-from-bracket']"
          icon-color="text-red-500"
          :late-checkout="nextArrival?.lateCheckout"
        />
        <VDivider class="my-1 !mb-1" />
        <div class="flex items-center gap-3">
          <div class="w-5">
            <FontAwesomeIcon :icon="['fad', 'people-group']" />
          </div>
          <span class="flex-1">
            {{ $t('tasks.reservation.guests', { n: getGuestCount(nextArrival).guests }) }}
            <template v-if="getGuestCount(nextArrival).pets > 0">
              , {{ $t('tasks.reservation.pets', { n: getGuestCount(nextArrival).pets }) }}
            </template>
          </span>
        </div>
        <div v-if="nextArrival?.data.locale" class="flex items-center gap-3">
          <div class="flex flex-row items-center gap-1">
            <div class="mr-2">
              <img alt="flag" :src="localeFlag" class="w-5 self-center">
            </div>
            <span class="flex-1">{{ localeTranslation }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
